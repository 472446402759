import CategoryIcon from '@material-ui/icons/Category'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import {PlaybookTagProps} from '../PlaybookTag/PlaybookTag'

export interface PlaybookNavDropdownMenuProps {
  playbookTags: Array<PlaybookTagProps>
}

const DropdownButton = styled.button`
  outline: none;
  background-color: ${BizlyPalette['base-black']};
  color: white;
  font-weight: bold;
  font-size: 48px;
  border: none;
  cursor: pointer;
  padding: 0;
`

const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  width: 200px;
  padding: 12px;
  top: 70px;
  right: 10px;
  border-radius: 9px;
  background-color: ${BizlyPalette['base-black']};
`

const DropdownContainer = styled.div`
  flex: 1;
  background-color: ${BizlyPalette['base-black']};
  max-width: 100px;
  border-radius: 9px;
  padding: 12px;
  text-align: center;
  font-family: 'Lato', sans-serif;
  color: white;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 24px;
  display: none;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${BizlyPalette['accent-black']};
  }

  &:active ${DropdownMenu} {
    display: block;
  }

  &:hover ${DropdownMenu} {
    display: block;
  }

  &:hover ${DropdownButton} {
    background-color: ${BizlyPalette['accent-black']};
  }

  @media ${BreakpointsMediaQueries.md} {
    display: flex;
  }
`

const MenuItems = styled.div`
  font-weight: bold;
  font-size: 24px;
  height: 100%;
`

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid ${BizlyPalette['base-purple']};
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 12px;
`

const MenuItemsContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: 10px;
  cursor: pointer;

  &: hover {
    background-color: ${BizlyPalette['accent-black']};
  }
`

const PlaybookNavDropdownMenu: FC<PlaybookNavDropdownMenuProps> = ({
  playbookTags,
}) => {
  // This function is used to group the array of Playbook tags by their category key
  const groupedTags = playbookTags.reduce((r, a) => {
    r[a.category] = r[a.category] || []
    r[a.category].push(a)
    return r
  }, Object.create(null))

  const Categories = Object.keys(groupedTags)

  return (
    <DropdownContainer>
      <DropdownButton>
        <CategoryIcon style={{fill: 'white', width: '40x', height: '40px'}} />{' '}
      </DropdownButton>
      <DropdownMenu>
        {Categories.map((item) => (
          <MenuItemsContainer>
            <Checkbox type='checkbox' />
            <MenuItems>{item}</MenuItems>
          </MenuItemsContainer>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  )
}

export default PlaybookNavDropdownMenu
