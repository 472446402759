import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import PlaybookNavItem from '../PlaybookNavItem/PlaybookNavItem'
import {PlaybookTagProps} from '../PlaybookTag/PlaybookTag'

export interface PlaybookNavContainerProps {
  playbookTags: Array<PlaybookTagProps>
}

const Container = styled.div`
  color: white;
  font-size: 22px;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
  width: 35%;
  max-width: 400px;
  padding: 40px 50px 0;
  height: calc(100vh - 100px);
  background-color: ${BizlyPalette['base-black']};
  border-right: 1px solid white;

  @media ${BreakpointsMediaQueries.sm} {
    font-size: 14px;
  }

  @media ${BreakpointsMediaQueries.md} {
    border-right: none;
    display: flex;
    flex-wrap: wrap;
    height: unset;
    display: none;
  }
`

const PlaybookNavContainer: FC<PlaybookNavContainerProps> = ({
  playbookTags,
}) => {
  // This function is used to group the array of Playbook tags by their category key
  const groupedTags = playbookTags.reduce((r, a) => {
    r[a.category] = r[a.category] || []
    r[a.category].push(a)
    return r
  }, Object.create(null))

  const Categories: Array<string> = Object.keys(groupedTags)

  return (
    <Container>
      {Categories.map((item) => (
        <PlaybookNavItem text={item} />
      ))}
    </Container>
  )
}

export default PlaybookNavContainer
