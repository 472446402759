import * as Msal from 'msal'

import {ImplicitMSALAuthenticationProvider} from '@microsoft/microsoft-graph-client/lib/src/ImplicitMSALAuthenticationProvider'
import {MSALAuthenticationProviderOptions} from '@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions'

import {Client} from '@microsoft/microsoft-graph-client'
import {isLocalhost} from '../../core/utility/Utils'

const MICROSOFT_CLIENT_ID = 'fab27485-472f-4f54-80a9-04a868b35f5c'
const AUTHORITY =
  'https://login.microsoftonline.com/9188040d-6c67-4c5b-b112-36a304b66dad'
const REDIRECT_URI = isLocalhost
  ? 'http://localhost:3000/home'
  : 'https://dashboard.bizly.bros.me'
const REDIRECT_POST_LOGOUT = isLocalhost
  ? 'http://localhost:3000'
  : 'https://dashboard.bizly.bros.me'
const SCOPES = [
  'profile',
  'email',
  'openid',
  'Calendars.ReadWrite',
  'Contacts.ReadWrite',
]

const mapEvents = (events: any) =>
  events.map((event: any) => ({
    eventScore: 1,
    type: {
      personal: true,
    },
    recurrence: event.recurrence || null,
    locationType: 'Video',
    timeFrom: new Date(event.start.dateTime),
    timeTo: new Date(event.end.dateTime),
    title: event.subject,
    organizer:
      event.organizer.emailAddress.name || event.organizer.emailAddress.address,
    members: event.attendees.map((attendee: any) => ({
      name: attendee.emailAddress.name,
      email: attendee.emailAddress.address,
    })),
    tags: [{title: 'Business'}, {title: 'Casual'}, {title: 'Smart'}],
  }))

const mapContacts = (contacts: any) =>
  contacts.map((contact: any) => contact.emailAddresses[0].address)

export const initMicrosoftGrapth = () => {
  const msalConfig = {
    auth: {
      clientId: MICROSOFT_CLIENT_ID,
      authority: AUTHORITY,
      redirectUri: REDIRECT_URI,
      postLogoutRedirectUri: REDIRECT_POST_LOGOUT,
    },
  }

  const msalApplication = new Msal.UserAgentApplication(msalConfig)
  const options = new MSALAuthenticationProviderOptions(SCOPES)
  const authProvider = new ImplicitMSALAuthenticationProvider(
    msalApplication,
    options,
  )

  const graphOptions = {
    authProvider, // An instance created from previous step
  }

  const client = Client.initWithMiddleware(graphOptions)

  return {
    client,
    authProvider,
  }
}

export const grantMicrosoftConsent = async (client: any) => {
  !client.config.authProvider.msalApplication.getAccount() &&
    (await client.config.authProvider.msalApplication.loginPopup(SCOPES))
}

export const getEvents = async (client: any) => {
  const preferTimeZone = `outlook.timezone="${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  }"`
  const allEvents = await client
    .api('/me/calendar/events')
    .header('Prefer', preferTimeZone)
    .get()

  return mapEvents(allEvents.value)
}

export const getContacts = async (client: any) => {
  const allContacts = await client.api('/me/contacts').get()
  return mapContacts(allContacts.value)
}
