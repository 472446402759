/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {FC} from 'react'
import styled from 'styled-components'

import AvatarChip, {AvatarChipProps} from '../AvatarChip/AvatarChip'

export interface AvatarChipContainerProps {
  members: Array<AvatarChipProps>
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const AvatarChipContainer: FC<AvatarChipContainerProps> = ({members}) => {
  let numberchip
  if (members.length > 4) {
    numberchip = <AvatarChip avatar={''} number={`+${members.length - 4}`} />
  }

  const slicedMembers = members.slice(0, 4)

  if (!!slicedMembers) {
    return (
      <Container>
        {slicedMembers.map((member, index) => (
          <AvatarChip avatar={member.avatar} name={member.name} key={index} />
        ))}
        {numberchip}
      </Container>
    )
  }
  return <span>Empty!</span>
}

export default AvatarChipContainer
