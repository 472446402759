import {createStyles, Theme, withStyles} from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

export interface IntegrationItemProps {
  integrationLogo: string
  integrationName: string
  integrationDescription: string
  borderBottom?: boolean
}

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 40,
      height: 22,
      padding: 0,
      display: 'flex',
      overflow: 'unset',
      alignItems: 'center',
    },
    switchBase: {
      padding: 3,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(18px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#5E1A6F',
          border: 'none',
        },
      },
    },
    thumb: {
      width: 16,
      height: 16,
      boxShadow: 'none',
    },
    track: {
      border: 'none',
      borderRadius: '12px',
      opacity: 1,
      backgroundColor: '#42424D',
    },
    checked: {},
  }),
)(Switch)

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 10;
  margin-right: 12px;

  @media ${BreakpointsMediaQueries.sm} {
    max-width: 220px;
    overflow: hidden;
    flex: 8;
  }

  @media ${BreakpointsMediaQueries.md} {
    max-width: 230px;
  } ;
`

const Title = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
`

const Description = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${BizlyPalette['subtitle-gray']};

  @media ${BreakpointsMediaQueries.md} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media ${BreakpointsMediaQueries.sm} {
    display: none;
  }
`

const LeftBox = styled.div`
  display: flex;
  flex: 11;

  @media ${BreakpointsMediaQueries.sm} {
    align-items: center;
  }
`

const SwitchContainer = styled.span`
  flex: 1;

  @media ${BreakpointsMediaQueries.md} {
    flex: 2;
  } ;
`

const OuterContainer = styled('div')<{borderBottom: boolean | undefined}>`
  font-family: 'Lato', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  height: 90px;
  background-color: ${BizlyPalette['base-black']};
  border-bottom: ${(props) =>
    !!props.borderBottom ? '1px solid #878787' : 'none'};
`

const LogoContainer = styled('div')<{integrationLogo: string}>`
  background-image: url(${(props) => props.integrationLogo});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
  flex: 1;
  border-radius: 4px;
  margin-right: 24px;
`

const IntegrationItem: FC<IntegrationItemProps> = ({
  integrationLogo,
  integrationName,
  integrationDescription,
  borderBottom,
}) => {
  const [state, setState] = React.useState({
    checked: true,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({...state, [event.target.name]: event.target.checked})
  }

  return (
    <OuterContainer borderBottom={borderBottom}>
      <LeftBox>
        <LogoContainer integrationLogo={integrationLogo} />
        <TextContainer>
          <Title>{integrationName}</Title>
          <Description>{integrationDescription}</Description>
        </TextContainer>
      </LeftBox>
      <SwitchContainer>
        <AntSwitch
          checked={state.checked}
          onChange={handleChange}
          name='checked'
        />
      </SwitchContainer>
    </OuterContainer>
  )
}

export default IntegrationItem
