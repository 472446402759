import {CognitoUserExt} from '../models/CognitoUser'

export const SET_EMAIL_FORM = 'SET_EMAIL_FORM'
export const COGNITO_SIGN_IN = 'COGNITO_SIGN_IN'
export const COGNITO_SIGN_UP = 'COGNITO_SIGN_UP'
export const COGNITO_SIGN_OUT = 'COGNITO_SIGNOUT'
export const COGNITO_CONFIRM_NEW_USER = 'COGNITO_CONFIRM_NEW_USER'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUBMIT = 'FORGOT_PASSWORD_SUBMIT'
export const UPDATE_USER = 'UPDATE_USER'

export interface SetEmailFormActions {
  type: typeof SET_EMAIL_FORM
  payload: string
}

export interface CognitoSignInActions {
  type: typeof COGNITO_SIGN_IN
  payload: CognitoUserExt | null
}

export interface CognitoSignUpActions {
  type: typeof COGNITO_SIGN_UP
}

export interface CognitoSignOutActions {
  type: typeof COGNITO_SIGN_OUT
}

export interface CognitoConfirmNewUserActions {
  type: typeof COGNITO_CONFIRM_NEW_USER
}

export interface CognitoForgotPasswordUserActions {
  type: typeof FORGOT_PASSWORD
  payload: string
}

export interface CognitoForgotPasswordSubmitUserActions {
  type: typeof FORGOT_PASSWORD_SUBMIT
}

export interface CognitoUpdateUserActions {
  type: typeof UPDATE_USER
  payload: CognitoUserExt
}

export type CognitoAuth =
  | SetEmailFormActions
  | CognitoSignInActions
  | CognitoSignUpActions
  | CognitoSignOutActions
  | CognitoConfirmNewUserActions
  | CognitoForgotPasswordUserActions
  | CognitoForgotPasswordSubmitUserActions
  | CognitoUpdateUserActions
