import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import DescriptionIcon from '@material-ui/icons/Description'
import GroupIcon from '@material-ui/icons/Group'
import React from 'react'
import styled from 'styled-components'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled.button`
  color: white;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 48px;
`

const BizlyIcon = styled.button`
  width: 44px;
  height: 44px;
  background-color: #fdb72e;
  border-radius: 50% 0 50% 50%;
  border: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  margin-bottom: 60px;
`

const AgendaOverlaySideNav = (props: any) => {
  const handleChange = (event: any) => {
    // Here, we invoke the callback with the new value
    props.onClick(event.target.value)
  }

  return (
    <Content>
      <BizlyIcon />
      <StyledButton onClick={handleChange}>{`< Back`}</StyledButton>
      <DescriptionIcon
        style={{
          fill: '#8d8d8f',
          width: '22px',
          height: '22px',
          marginBottom: '48px',
        }}
      />
      <ChatBubbleOutlineIcon
        style={{
          fill: '#8d8d8f',
          width: '22px',
          height: '22px',
          marginBottom: '48px',
        }}
      />
      <GroupIcon
        style={{
          fill: '#8d8d8f',
          width: '22px',
          height: '22px',
          marginBottom: '48px',
        }}
      />
    </Content>
  )
}

export default AgendaOverlaySideNav
