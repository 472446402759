export const INIT_DATA_PROVIDER = 'INIT_DATA_CALENDAR'
export const FETCH_DATA_PROVIDER = 'FETCH_DATA_PROVIDER'

export interface InitDataProvider {
  type: typeof INIT_DATA_PROVIDER
}

export interface FetchDataProvider {
  type: typeof FETCH_DATA_PROVIDER
  payload: any
}

export type CalendarActions = InitDataProvider | FetchDataProvider
