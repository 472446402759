import React, {FC} from 'react'
import styled from 'styled-components'
import SvgCircle from '../../assets/icons/SvgCircle'
import SvgGdocs from '../../assets/icons/SvgGdocs'
import SvgMsteams from '../../assets/icons/SvgMsteams'
import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

export interface ServiceButtonProps {
  type: string
}

const Button = styled.div`
  min-width: 92px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid ${BizlyPalette['outline-gray']};
  background-color: ${BizlyPalette['base-black']};
  cursor: pointer;

  &:hover {
    border: 2px solid ${BizlyPalette['base-purple']};
  }

  &:active {
    background-color: ${BizlyPalette['dark-gray']};
  }

  @media ${BreakpointsMediaQueries.sm} {
    min-width: 70px;
  }
`

const ServiceButton: FC<ServiceButtonProps> = ({type}) => {
  let icon

  switch (type) {
    case 'gdocs':
      icon = (
        <SvgGdocs height={20} width={20} fill={BizlyPalette['service-gray']} />
      )
      break
    case 'msteams':
      icon = (
        <SvgMsteams
          height={20}
          width={20}
          fill={BizlyPalette['service-gray']}
        />
      )
      break
    case 'circle':
      icon = (
        <SvgCircle height={20} width={20} fill={BizlyPalette['service-gray']} />
      )
      break
    default:
      icon = ''
  }

  return <Button>{icon}</Button>
}

export default ServiceButton
