import AddIcon from '@material-ui/icons/Add'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import DevicesIcon from '@material-ui/icons/Devices'
import GroupIcon from '@material-ui/icons/Group'
import HomeIcon from '@material-ui/icons/Home'
import PresentToAllIcon from '@material-ui/icons/PresentToAll'
import SettingsIcon from '@material-ui/icons/Settings'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LeftNavBarProps {}

const OuterContainer = styled.div`
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-width: 84px;
  max-width: 84px;
  height: 100vh;
  padding-bottom: 10%;
  background-color: ${BizlyPalette['base-black']};
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;

  @media ${BreakpointsMediaQueries.lg} {
    display: none;
  }
`

const TopGroup = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`

const MainGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const SettingsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconContainer = styled.div`
  height: 84px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BizlyIcon = styled.button`
  width: 44px;
  height: 44px;
  background-color: #fdb72e;
  border-radius: 50% 0 50% 50%;
  border: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }
`

const AddButton = styled.button`
  width: 50px;
  height: 50px;
  background-color: ${BizlyPalette['base-purple']};
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid ${BizlyPalette['info-yellow']};
  }
`

const AddContainer = styled.div`
  height: 84px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const MainButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BizlyPalette['base-black']};
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  margin-bottom: 90%;

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }
`

const LeftNavBar: FC<LeftNavBarProps> = ({}) => {
  return (
    <OuterContainer>
      <TopGroup>
        <Link to='/'>
          <IconContainer>
            <BizlyIcon />
          </IconContainer>
        </Link>
        <AddContainer>
          <Link to='/new-event'>
            <AddButton>
              <AddIcon style={{fill: 'white', width: '36px', height: '36px'}} />
            </AddButton>
          </Link>
        </AddContainer>
        <MainGroup>
          <Link to='/home'>
            <MainButton>
              <HomeIcon
                style={{fill: '#8d8d8f', width: '22px', height: '22px'}}
              />
            </MainButton>
          </Link>
          <Link to='/calendar'>
            <MainButton>
              <CalendarTodayIcon
                style={{fill: '#8d8d8f', width: '22px', height: '22px'}}
              />
            </MainButton>
          </Link>
          <Link to='/agenda'>
            <MainButton>
              <GroupIcon
                style={{fill: '#8d8d8f', width: '22px', height: '22px'}}
              />
            </MainButton>
          </Link>
          <Link to='/playbook'>
            <MainButton>
              <DevicesIcon
                style={{fill: '#8d8d8f', width: '22px', height: '22px'}}
              />
            </MainButton>
          </Link>
          <Link to='/agendacontrol'>
            <MainButton>
              <PresentToAllIcon
                style={{fill: '#8d8d8f', width: '22px', height: '22px'}}
              />
            </MainButton>
          </Link>
        </MainGroup>
      </TopGroup>
      <SettingsButtonContainer>
        <MainButton>
          <Link to='/settings-integrations'>
            <SettingsIcon
              style={{fill: '#8d8d8f', width: '22px', height: '22px'}}
            />
          </Link>
        </MainButton>
      </SettingsButtonContainer>
    </OuterContainer>
  )
}

export default LeftNavBar
