import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BusinessIcon from '@material-ui/icons/Business'
import CloudQueueIcon from '@material-ui/icons/CloudQueue'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import ExtensionIcon from '@material-ui/icons/Extension'
import GroupIcon from '@material-ui/icons/Group'
import StorefrontIcon from '@material-ui/icons/Storefront'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

export interface MenuItemProps {
  title: string
  description?: string
  type: string
  section: string
  eventNum?: number
  borderBottom?: boolean
  id?: string
}

const Button = styled.button`
    font-family: 'Lato', sans-serif;
    width: 100%;
    height: 80%;
    border-radius: 6px;
    background-color: transparent;
    outline: none;
    border: none;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${BizlyPalette['subtitle-gray']};

    @media ${BreakpointsMediaQueries.lg} {
        flex-direction: column !important;
    };

    @media ${BreakpointsMediaQueries.md} {
        height: unset;
    }

    &:active {
        background-color: ${BizlyPalette['accent-black']}
    };

    &:hover {
        color: ${BizlyPalette['info-yellow']};
`

const Text = styled.div`
  width: 100%;
  height: 98px;
  display: flex;
  flex: 3.5;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${BreakpointsMediaQueries.lg} {
    align-items: center !important;
    padding-left: 0 !important;
    margin-top: 10px;
  }
`

const TextBaseline = styled.div`
  width: 100%;
  height: 98px;
  display: flex;
  flex: 3.5;
  justify-content: space-around;
  align-items: center;

  @media ${BreakpointsMediaQueries.lg} {
    margin-top: 10px;
    justify-content: center;
  }

  @media ${BreakpointsMediaQueries.md} {
    position: relative;
  } ;
`

const Title = styled.h1`
  margin: 0;
  font-size: 14px;

  @media ${BreakpointsMediaQueries.md} {
    display: none;
  }
`

const Description = styled.p`
  margin: 0;
  font-size: 12px;
  margin-top: 4px;

  @media ${BreakpointsMediaQueries.lg} {
    display: none;
  }
`

const NumberOfEvents = styled.span`
  margin: 0;
  background-color: ${BizlyPalette['light-green']};
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 12px;

  @media ${BreakpointsMediaQueries.lg} {
    margin-left: 10px;
  }

  @media ${BreakpointsMediaQueries.md} {
    margin-left: 0;
    position: absolute;
    top: 0;
  } ;
`

const Container = styled('div')<{borderBottom: boolean | undefined}>`
  font-family: 'Lato', sans-serif;
  width: 100%;
  box-sizing: border-box;
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${BizlyPalette['subtitle-gray']};
  border-bottom: ${(props) =>
    !!props.borderBottom ? '1px solid #8d8d8f' : 'none'};

  @media ${BreakpointsMediaQueries.md} {
    border-bottom: none;
  } ;
`

const MenuItem: FC<MenuItemProps> = ({
  title,
  description,
  type,
  borderBottom,
  section,
  eventNum,
}) => {
  let iconContent

  switch (type) {
    case 'information':
      iconContent = (
        <AssignmentIcon
          style={{
            fill: BizlyPalette['icon-gray'],
            width: '22px',
            height: '22px',
            flex: '1',
          }}
        />
      )
      break
    case 'teams':
      iconContent = (
        <GroupIcon
          style={{
            fill: BizlyPalette['icon-gray'],
            width: '22px',
            height: '22px',
            flex: '1',
          }}
        />
      )
      break
    case 'reports':
      iconContent = (
        <EqualizerIcon
          style={{
            fill: BizlyPalette['icon-gray'],
            width: '22px',
            height: '22px',
            flex: '1',
          }}
        />
      )
      break
    case 'integrations':
      iconContent = (
        <ExtensionIcon
          style={{
            fill: BizlyPalette['icon-gray'],
            width: '22px',
            height: '22px',
            flex: '1',
          }}
        />
      )
      break
    case 'business':
      iconContent = (
        <BusinessIcon
          style={{
            fill: BizlyPalette['icon-gray'],
            width: '22px',
            height: '22px',
            flex: '1',
          }}
        />
      )
      break
    case 'leadership':
      iconContent = (
        <CloudQueueIcon
          style={{
            fill: BizlyPalette['icon-gray'],
            width: '22px',
            height: '22px',
            flex: '1',
          }}
        />
      )
      break
    case 'customers':
      iconContent = (
        <StorefrontIcon
          style={{
            fill: BizlyPalette['icon-gray'],
            width: '22px',
            height: '22px',
            flex: '1',
          }}
        />
      )
      break
    case 'addButton':
      iconContent = (
        <AddCircleIcon
          style={{fill: 'white', width: '22px', height: '22px', flex: '1'}}
        />
      )
      break
    case 'calendarAll':
      iconContent = (
        <AccountCircleIcon
          style={{fill: 'white', width: '22px', height: '22px', flex: '1'}}
        />
      )
      break
    default:
      iconContent = ''
      break
  }

  let textContent

  switch (section) {
    case 'settings':
      textContent = (
        <Text>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Text>
      )
      break
    case 'calendar':
      textContent = (
        <Text style={{alignItems: 'baseline', paddingLeft: '30px'}}>
          <Title>{title}</Title>
        </Text>
      )
      break
    case 'calendarAll':
      textContent = (
        <TextBaseline>
          <div>
            <Title style={{color: 'white'}}>{title}</Title>
            <Description>{description}</Description>
          </div>
          <NumberOfEvents>{eventNum}</NumberOfEvents>
        </TextBaseline>
      )
      break
    case 'addButton':
      textContent = (
        <Text style={{alignItems: 'baseline', paddingLeft: '30px'}}>
          <Title style={{alignItems: 'baseline', color: 'white'}}>
            {title}
          </Title>
        </Text>
      )
      break
    default:
      textContent = ''
      break
  }

  return (
    <Container borderBottom={borderBottom}>
      <Button>
        {iconContent}
        {textContent}
      </Button>
    </Container>
  )
}

export default MenuItem
