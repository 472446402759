import Modal from '@material-ui/core/Modal'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import SvgCalendar from '../../assets/icons/SvgCalendar'
import SvgCircle from '../../assets/icons/SvgCircle'
import SvgGdocs from '../../assets/icons/SvgGdocs'
import SvgMsteams from '../../assets/icons/SvgMsteams'
import {TailSpin} from '../../assets/icons/tail-spin'
import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import {
  InitDataProvider,
  FetchDataProvider,
} from '../../redux/actions/ProviderApi'
import {initGapiV2, grantGoogleConsent} from '../../core/services/GoogleAPI'
import {grantMicrosoftConsent} from '../../core/services/MicrosoftAPI'
import ActionBox from '../ActionBox/ActionBox'
import CallToAction from '../CallToAction/CallToAction'
import {DayEventItemProps} from '../DayEventItem/DayEventItem'
import DayEventItemContainer from '../DayEventItemContainer/DayEventItemContainer'
import InfoBox from '../InfoBox/InfoBox'
import TopNavBar from '../TopNavBar/TopNavBar'

export interface HomePageProps {
  date: string
  numberOfNewConections: number
  dayEvents: Array<DayEventItemProps>
}

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 24px;

  @media ${BreakpointsMediaQueries.md} {
    padding: 0;
    box-sizing: border-box;
    align-items: center;
  } ;
`

const DateComponent = styled.p`
  font-size: 32px;
  line-height: 38px;
  color: white;
  margin: 24px 0 32px;

  @media ${BreakpointsMediaQueries.sm} {
    font-size: 28px;
  } ;
`

const InnerContainer = styled.div`
  display: flex;

  @media ${BreakpointsMediaQueries.md} {
    flex-direction: column;
    align-items: center;
  } ;
`

const InfoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;

  @media ${BreakpointsMediaQueries.md} {
    margin-left: 0;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 515px;
    width: 100%;
    justify-content: space-between;
  }

  @media ${BreakpointsMediaQueries.sm} {
    justify-content: center;
  } ;
`

const ActionBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-conent: flex-start;

  @media ${BreakpointsMediaQueries.lg} {
    justify-content: center;
  }
`

const Title = styled.h1`
  font-size: 30px;
  line-height: 42px;
  max-width: 308px;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  margin-bottom: 56px;
  text-align: center;

  @media ${BreakpointsMediaQueries.sm} {
    margin-bottom: 24px;
    font-size: 24px;
  }
`

const OutterTitle = styled.h1`
  font-size: 30px;
  line-height: 42px;
  max-width: 308px;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  margin-bottom: 56px;
  flex: 1;
  text-align: center;

  @media ${BreakpointsMediaQueries.sm} {
    margin-bottom: 24px;
    font-size: 24px;
  }
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 3;
  min-height: 260px;

  @media ${BreakpointsMediaQueries.sm} {
    min-height: unset;
  }
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: 'Lato', sans-serif;
  border-radius: 8px;
  border: 1px solid ${BizlyPalette['outline-gray']};
  padding: 42px 38px 34px;
  box-sizing: border-box;
  margin-bottom: 24px;
  margin-right: 24px;
  width: 100%;
  max-width: 390px;

  @media ${BreakpointsMediaQueries.xl} {
    max-width: 380px;
  }

  @media ${BreakpointsMediaQueries.md} {
    margin-right: 0;
  }

  @media ${BreakpointsMediaQueries.sm} {
    padding: 12px;
    border: none;
  }
`

const InnerActionContainer = styled.div`
  display: flex;
  height: 470px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: 'Lato', sans-serif;
  border-radius: 8px;
  border: 1px solid #878787;
  padding: 42px 38px 34px;
  box-sizing: border-box;
  margin-bottom: 24px;
  margin-right: 24px;
  width: 100%;
  max-width: 390px;
  background-color: ${BizlyPalette['base-black']};
  position: absolute;
  top: 25%;
  left: 35%;
  height: 470px;
  outline: none;

  @media ${BreakpointsMediaQueries.xl} {
    max-width: 380px;
  }

  @media ${BreakpointsMediaQueries.md} {
    margin-right: 0;
  }

  @media ${BreakpointsMediaQueries.sm} {
    padding: 12px;
    border: none;
  }
`
const OutterSubtitleContainer = styled.p`
  font-size: 14px;
  max-width: 308px;
  width: 100%;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
  margin: 0 0 40px;
  color: ${BizlyPalette['subtitle-gray']};

  @media ${BreakpointsMediaQueries.sm} {
    margin-bottom: 20px;
  }
`
const SubtitleContainer = styled.p`
  font-size: 14px;
  max-width: 308px;
  width: 100%;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
  margin: 0 0 40px;
  color: ${BizlyPalette['subtitle-gray']};

  @media ${BreakpointsMediaQueries.sm} {
    margin-bottom: 20px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 308px;
  box-sizing: border-box;
  width: 100%;

  @media ${BreakpointsMediaQueries.sm} {
    margin-bottom: 12px;
  }
`

const Button = styled.div`
  min-width: 92px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid ${BizlyPalette['outline-gray']};
  background-color: ${BizlyPalette['base-black']};
  cursor: pointer;

  &:hover {
    border: 2px solid ${BizlyPalette['base-purple']};
  }

  &:active {
    background-color: ${BizlyPalette['dark-gray']};
  }

  @media ${BreakpointsMediaQueries.sm} {
    min-width: 70px;
  }
`

const IconContainer = styled.div`
  height: 94px;
  width: 97px;
  margin: 0 0 56px 0;

  @media ${BreakpointsMediaQueries.sm} {
    display: none;
  }
`

const StyledModal = styled(Modal)``

const getDayEvents = (events: any) => {
  const d = new Date()
  const today = d.toDateString()
  const time = d.getTime()
  return events.filter(
    (event: any) =>
      event.timeFrom.toDateString() === today &&
      event.timeFrom.getTime() > time,
  )
}

const HomePage = () => {
  const dispatch = useDispatch()
  const {user} = useSelector((state: RootStateOrAny) => state.cognitoAuth)
  const {events, status, msClient} = useSelector(
    (state: RootStateOrAny) => state.providerData,
  )
  const dayEvents = getDayEvents(events)

  const [open, setOpen] = useState(false)

  const userAuthProvider =
    user &&
    user.attributes.identities &&
    JSON.parse(user.attributes.identities)[0].providerName

  const providerSynced = user && user.attributes['custom:provider-synced']
  const dataProvider = user && user.attributes['custom:data-provider']

  useEffect(() => {
    async function fetchData() {
      if (providerSynced && dataProvider === 'Google') {
        setOpen(true)
        await initGapiV2()
        await grantGoogleConsent()
        dispatch(FetchDataProvider(dataProvider))
      }

      if (providerSynced && dataProvider === 'Microsoft') {
        setOpen(true)
        await grantMicrosoftConsent(msClient)
        dispatch(FetchDataProvider(dataProvider, msClient))
      }
    }

    fetchData()
  }, [user])

  useEffect(() => {
    if (events && open) {
      setOpen(false)
    }
  }, [events])

  const handleClose = (e: any) => {
    e.stopPropagation()
    setOpen(false)
  }

  const handleOpen = (provider: string) => async () => {
    if (!userAuthProvider || userAuthProvider === provider) {
      // init google
      if (provider === 'Google') {
        await initGapiV2()
        await grantGoogleConsent()
      }

      //init Microsoft
      if (provider === 'Microsoft') {
        await grantMicrosoftConsent(msClient)
      }
      setOpen(true)
      dispatch(InitDataProvider(provider))
    }
  }

  const Screen: JSX.Element =
    events.length > 0 && !open ? (
      <Content>
        <DateComponent>{moment().format('MMMM Do, YYYY')}</DateComponent>
        <InnerContainer>
          <DayEventItemContainer dayEvents={dayEvents} />
          <InfoBoxContainer>
            <InfoBox number={dayEvents.length} text='personal events today' />
            <InfoBox number={dayEvents.length} text='company events today' />
            <InfoBox number={40} text='connections today' color='yellow' />
          </InfoBoxContainer>
        </InnerContainer>
      </Content>
    ) : (
      <Content>
        <CallToAction
          title='You are Back!'
          description='Seems you were able to connect some data so far. Connect more info to make bizly more powerful!'
        />
        <ActionBoxContainer>
          <ActionContainer>
            <OutterTitle className='bsb-action-box-title'>
              Import Your Calendar And Contacts
            </OutterTitle>
            <BodyContainer>
              <IconContainer>
                <SvgCalendar
                  width={97}
                  height={94}
                  fill={BizlyPalette['icon-gray']}
                />
              </IconContainer>
              <OutterSubtitleContainer>
                Select account type below.
              </OutterSubtitleContainer>
              <ButtonsContainer>
                <Button onClick={handleOpen('Google')}>
                  <SvgGdocs
                    height={20}
                    width={20}
                    fill={BizlyPalette['service-gray']}
                  />
                  <StyledModal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'>
                    <InnerActionContainer>
                      {status !== 'synced' && (
                        <BodyContainer>
                          <Title className='bsb-action-box-title'>
                            WOAH! Someone's popular
                          </Title>
                          <TailSpin
                            className='tail-spin'
                            style={{
                              marginTop: '16%',
                              width: '70px',
                              height: '70px',
                            }}
                          />
                          <SubtitleContainer style={{marginBottom: '20px'}}>
                            Sit tight, we're importing your calendar.
                          </SubtitleContainer>
                        </BodyContainer>
                      )}
                    </InnerActionContainer>
                  </StyledModal>
                </Button>
                <Button onClick={handleOpen('Okta')}>
                  <SvgCircle
                    height={20}
                    width={20}
                    fill={BizlyPalette['service-gray']}
                  />
                  <StyledModal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'>
                    <InnerActionContainer>
                      {status !== 'synced' && (
                        <BodyContainer>
                          <Title className='bsb-action-box-title'>
                            WOAH! Someone's popular
                          </Title>
                          <TailSpin
                            className='tail-spin'
                            style={{
                              marginTop: '16%',
                              width: '70px',
                              height: '70px',
                            }}
                          />
                          <SubtitleContainer style={{marginBottom: '20px'}}>
                            Sit tight, we're importing your calendar.
                          </SubtitleContainer>
                        </BodyContainer>
                      )}
                    </InnerActionContainer>
                  </StyledModal>
                </Button>
                <Button onClick={handleOpen('Microsoft')}>
                  <SvgMsteams
                    height={20}
                    width={20}
                    fill={BizlyPalette['service-gray']}
                  />
                  <StyledModal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'>
                    <InnerActionContainer>
                      {status !== 'synced' && (
                        <BodyContainer>
                          <Title className='bsb-action-box-title'>
                            WOAH! Someone's popular
                          </Title>
                          <TailSpin
                            className='tail-spin'
                            style={{
                              marginTop: '16%',
                              width: '70px',
                              height: '70px',
                            }}
                          />
                          <SubtitleContainer style={{marginBottom: '20px'}}>
                            Sit tight, we're importing your calendar.
                          </SubtitleContainer>
                        </BodyContainer>
                      )}
                    </InnerActionContainer>
                  </StyledModal>
                </Button>
              </ButtonsContainer>
            </BodyContainer>
          </ActionContainer>
          <ActionBox
            title='Connect To Services'
            type='ConnectToServices'
            state='noBorder'
          />
        </ActionBoxContainer>
      </Content>
    )

  return (
    <ContainerDiv>
      <TopNavBar title='Dashboard' />
      {Screen}
    </ContainerDiv>
  )
}

export default HomePage
