/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles'
import TextField, {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from '@material-ui/core/TextField'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import AssignmentIcon from '@material-ui/icons/Assignment'
import EditIcon from '@material-ui/icons/Edit'
import EventNoteIcon from '@material-ui/icons/EventNote'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers'
import {FieldArray, Formik} from 'formik'
import moment from 'moment'
import React, {FC, useState} from 'react'
import {useDispatch, useSelector, RootStateOrAny} from 'react-redux'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'

import SvgPlus from '../../assets/icons/SvgPlus'
import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import {AddEvents} from '../../redux/actions/ApiActions'
import AttachmentsButton from '../AttachmentsButton/AttachmentsButton'
import {ChipItems} from '../ChipAutocomplete/ChipAutocomplete'
import {Collection} from '../FormSelect/FormSelect'
import MemberChip from '../MemberChip/MemberChip'
import MenuButton from '../MenuButton/MenuButton'
import SettingsButton from '../SettingsButton/SettingsButton'
import SimpleButton from '../SimpleButton/SimpleButton'
import Tag from '../Tag/Tag'

export interface EventFormNativeProps {
  playBookList: Array<{
    name: string
    title?: string
    locationType?: string
    locationAddress?: string
    description?: string
  }>
  locationList: Array<Collection>
  durationList: Array<Collection>
  tagList: Array<ChipItems>
  memberList: Array<ChipItems>
}

const theme = createMuiTheme({
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: `${BizlyPalette['base-purple']} !important`,
      },
    },
  },
})

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      fontFamily: "'Lato', sans-serif",
      width: '100%',
      margin: '0 1% 24px 0',
      height: '48px',
      borderRadius: '10px',
      border: '1px solid #898B8E',
      display: 'flex',
      justifyContent: 'center',
      '@media (max-width:576px)': {
        height: '44px',
      },
      '& div': {
        borderRadius: '10px',
        border: 'none',
        '& input': {
          color: '#898B8E',
          fontSize: '18px',
          lineHeight: '22px',
          border: 'none',
          outline: 'none',
          marginLeft: '24px',
          padding: 0,
          '@media (max-width:576px)': {
            fontSize: '14px',
          },
        },
        '&.MuiInput-underline': {
          '&:after': {
            borderBottom: 'unset',
            transistion: 'unset',
          },
          '&:before': {
            borderBottom: '1px solid #3b3b3d !important',
            transistion: 'unset',
          },
        },
        '& fieldset': {
          border: 'none',
        },
      },
    },
  }),
)

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 98%;
  margin-bottom: 60px;

  @media ${BreakpointsMediaQueries.md} {
    justify-content: center;
    flex-direction: column;
  } ;
`

const MainContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
  width: 100%;
  height: calc(100vh - 100px);

  @media ${BreakpointsMediaQueries.md} {
    width: 96%;
  } ;
`

const EventFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 64%;
  flex-wrap: wrap;

  @media ${BreakpointsMediaQueries.md} {
    width: 100%;
  } ;
`

const AgendaFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 34%;
  flex-wrap: wrap;

  @media ${BreakpointsMediaQueries.md} {
    width: 100%;
    margin-top: 48px;
  } ;
`

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0px;
  margin-bottom: 24px;
`

const StyledButton = styled.button`
  border: 1px solid green;
  margin-top: 8px;
  width: 100%;
  border-radius: 10px;
  height: 50px;
  font-size: 10px;
  line-height: 12px;
  padding: 0 12px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8a8a8b;
  outline: none;
  cursor: pointer;
  font-family: 'Lato', sans-serif;

  &:active {
    background-color: black;
  }

  &:hover {
    border: 1px solid purple;
  }
`

const Description = styled.span`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  flex: 10;
`

const PlusIcon = styled.span`
  flex: 1;
`

const Title = styled.div`
  margin-left: 12px;
  font-family: 'Lato', sans-serif;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  width: 100%;

  @media ${BreakpointsMediaQueries.md} {
    width: 88%;
    justify-content: space-around;
  }

  @media ${BreakpointsMediaQueries.sm} {
    width: 88%;
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: 160px;
  }

  @media ${BreakpointsMediaQueries.xs} {
    min-height: 200px;
  } ;
`

const HalfContainer = styled.div`
  width: 50%;
  display: flex;
`

const FullContainer = styled.div`
  width: 100%;
  display: flex;
`

const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: ${BizlyPalette['base-black']};
  color: white;
`

const StyledAccordion = styled(Accordion)`
  background-color: ${BizlyPalette['base-black']};
  box-shadow: unset;
  margin: 12px 0;
  border-top: 1px solid ${BizlyPalette['outline-gray']};
`

const RemoveAgendaItem = styled.button`
  border: 1px solid ${BizlyPalette['outline-gray']};
  background-color: ${BizlyPalette['base-black']};
  color: white;
  border-radius: 6px;
  padding: 6px 12px;
  margin-bottom: 24px;
  outline: none;
`

const EventMainTitle = styled(InputBase)`
  background-color: ${BizlyPalette['base-purple']};
  border-radius: 25px;
  width: 100%;
  color: white;
  margin: 0 3% 24px 0;
  box-sizing: border-box;
  height: 50px;
  padding: 0 25px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  @media (max-width: 576px) : {
    font-size: 16px;
  } ;
`

const StyledFormControl = styled(FormControl)`
  border-radius: 25px;
  fontfamily: Lato, sans-serif;
  width: 100%;
  color: white;
  margin: 0 1% 24px 0;
  border-bottom: white;
  & label {
    color: #8c8c8d !important;
    font-size: 14px;
    line-height: 21px;
    transition: unset;
  }
  & div {
    color: white;
    &:after {
      border-bottom: unset;
      transistion: unset;
    }
    &:before {
      border-bottom: 1px solid #3b3b3d !important;
      transistion: unset;
    }
    & select {
      color: white;
      font-size: 16px;
      line-height: 19px;
      &:focus {
        background-color: transparent;
      }
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    & svg {
      fill: white;
    }
  }
`

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  margin: 0 1% 24px 0;
`

const StyledTextField = styled(TextField)`
  & label {
    color: #8c8c8d !important;
    font-size: 14px;
    line-height: 21px;
    transition: unset;
  }
  & div {
    &:after {
      border-bottom: unset;
      transistion: unset;
    }
    &:before {
      border-bottom: 1px solid #3b3b3d !important;
      transistion: unset;
    }
    & div {
      & button {
        & span {
          & svg {
            fill: white;
          }
        }
      }
    }
    & input {
      color: white;
      font-size: 16px;
      line-height: 19px;
    }
  }
`

const StyledTextInput = styled(TextField)`
  width: 100%;
  margin: 0 1% 24px 0;
  & input {
    color: white;
    font-size: 16px;
    line-height: 19px;
  }
  & label {
    color: #8c8c8d !important;
    font-size: 14px;
    line-height: 21px;
    transition: unset;
  }
  & div {
    &:after {
      border-bottom: unset;
      transistion: unset;
    }
    &:before {
      border-bottom: 1px solid #3b3b3d !important;
      transistion: unset;
    }
  }
`

const StyledDatePicker = styled(KeyboardDatePicker)`
  width: 100%;
  margin: 0 1% 24px 0;
  & label {
    color: #8c8c8d !important;
    font-size: 14px;
    line-height: 21px;
    transition: unset;
  }
  & div {
    &:after {
      border-bottom: unset;
      transistion: unset;
    }
    &:before {
      border-bottom: 1px solid #3b3b3d !important;
      transistion: unset;
    }
    & input {
      color: white;
      font-size: 16px;
      line-height: 19px;
    }
  }
`

const StyledTimePicker = styled(KeyboardTimePicker)`
  width: 100%;
  margin: 0 1% 24px 0;
  & label {
    color: #8c8c8d !important;
    font-size: 14px;
    line-height: 21px;
    transition: unset;
  }
  & div {
    &:after {
      border-bottom: unset;
      transistion: unset;
    }
    &:before {
      border-bottom: 1px solid #3b3b3d !important;
      transistion: unset;
    }
    & input {
      color: white;
      font-size: 16px;
      line-height: 19px;
    }
  }
`

const EventFormNative: FC<EventFormNativeProps> = ({
  playBookList,
  locationList,
  tagList,
  memberList,
  durationList,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const Dispatch = useDispatch()

  const [expanded, setExpanded] = useState<string | false>(false)
  const handleChanges = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    event.preventDefault()
    setExpanded(isExpanded ? panel : false)
  }

  const {contacts} = useSelector((state: RootStateOrAny) => state.providerData)

  return (
    <Formik
      initialValues={{
        title: '',
        playbook: '',
        date: moment().format('yyyy/MM/DD'),
        timeFrom: moment().toDate(),
        timeTo: moment().toDate(),
        locationType: '',
        locationAddress: '',
        members: [{name: ''}],
        description: '',
        tags: [{title: ''}],
        agendaItems: [
          {
            title: '',
            duration: '',
            description: '',
            additionalInformation: '',
          },
        ],
      }}
      onSubmit={(values) => {
        Dispatch(AddEvents(values))
        history.push('/agendacontrol')
      }}>
      {({
        //   errors,
        //   handleBlur,
        handleChange,
        handleSubmit,
        // isSubmitting,
        //   isValid,
        //   dirty,
        //   touched,
        setFieldValue,
        values,
      }) => (
        <ThemeProvider theme={theme}>
          <MainContainer onSubmit={handleSubmit}>
            <FormContainer>
              <EventFormDiv>
                <EventMainTitle
                  endAdornment={
                    <InputAdornment position='end'>
                      <EditIcon
                        style={{
                          fill: 'white',
                          cursor: 'pointer',
                          height: '18px',
                          width: '18px',
                        }}
                      />
                    </InputAdornment>
                  }
                  placeholder='Event Title'
                  id='title'
                  name='title'
                  onChange={handleChange}
                  value={values.title}
                />
                <StyledFormControl>
                  <InputLabel shrink htmlFor='form-select'>
                    Select Playbook
                  </InputLabel>
                  <NativeSelect
                    name='playbook'
                    id='playbook'
                    value={values.playbook}
                    onChange={(val: {target: {value: string}}) => {
                      if (val.target.value === 'PlayBook 1') {
                        setFieldValue('title', playBookList[1].title)
                        setFieldValue('playbook', val.target.value)
                        setFieldValue(
                          'locationType',
                          playBookList[1].locationType,
                        )
                        setFieldValue(
                          'locationAddress',
                          playBookList[1].locationAddress,
                        )
                        setFieldValue(
                          'description',
                          playBookList[1].description,
                        )
                      } else if (val.target.value === 'PlayBook 2') {
                        setFieldValue('title', playBookList[2].title)
                        setFieldValue('playbook', val.target.value)
                        setFieldValue(
                          'locationType',
                          playBookList[2].locationType,
                        )
                        setFieldValue(
                          'locationAddress',
                          playBookList[2].locationAddress,
                        )
                        setFieldValue(
                          'description',
                          playBookList[2].description,
                        )
                      } else if (val.target.value === 'PlayBook 3') {
                        setFieldValue('title', playBookList[3].title)
                        setFieldValue('playbook', val.target.value)
                        setFieldValue(
                          'locationType',
                          playBookList[3].locationType,
                        )
                        setFieldValue(
                          'locationAddress',
                          playBookList[3].locationAddress,
                        )
                        setFieldValue(
                          'description',
                          playBookList[3].description,
                        )
                      } else if (val.target.value === 'PlayBook 4') {
                        setFieldValue('title', playBookList[4].title)
                        setFieldValue('playbook', val.target.value)
                        setFieldValue(
                          'locationType',
                          playBookList[4].locationType,
                        )
                        setFieldValue(
                          'locationAddress',
                          playBookList[4].locationAddress,
                        )
                        setFieldValue(
                          'description',
                          playBookList[4].description,
                        )
                      } else if (val.target.value === 'PlayBook 5') {
                        setFieldValue('title', playBookList[5].title)
                        setFieldValue('playbook', val.target.value)
                        setFieldValue(
                          'locationType',
                          playBookList[5].locationType,
                        )
                        setFieldValue(
                          'locationAddress',
                          playBookList[5].locationAddress,
                        )
                        setFieldValue(
                          'description',
                          playBookList[5].description,
                        )
                      } else {
                        setFieldValue('title', '')
                        setFieldValue('playbook', val.target.value)
                        setFieldValue('locationType', '')
                        setFieldValue('locationAddress', '')
                        setFieldValue('description', '')
                      }
                    }}>
                    {playBookList.map((item) => (
                      <option
                        key={item.name}
                        value={item.name}>{`${item.name}`}</option>
                    ))}
                  </NativeSelect>
                </StyledFormControl>
                <FullContainer>
                  <HalfContainer>
                    <StyledDatePicker
                      label='Date'
                      placeholder={`${new Date()}`}
                      id='date'
                      value={values.date}
                      onChange={(value: any) =>
                        setFieldValue('date', value?.format('yyyy/MM/DD'))
                      }
                      variant='inline'
                      format='yyyy/MM/DD'
                      keyboardIcon={
                        <EventNoteIcon
                          style={{fill: 'white', width: '16px', height: '16px'}}
                        />
                      }
                    />
                  </HalfContainer>
                  <HalfContainer>
                    <StyledTimePicker
                      // ampm={false}
                      variant='inline'
                      label='Time from'
                      id='timeFrom'
                      value={values.timeFrom}
                      onChange={(value: any) => {
                        setFieldValue('timeFrom', value?.toDate())
                      }}
                      keyboardIcon={
                        <AccessTimeIcon
                          style={{fill: 'white', width: '16px', height: '16px'}}
                        />
                      }
                    />
                    <StyledTimePicker
                      // ampm={false}
                      variant='inline'
                      label='Time to'
                      id='timeTo'
                      value={values.timeTo}
                      onChange={(value: any) => {
                        setFieldValue('timeTo', value?.toDate())
                      }}
                      keyboardIcon={
                        <AccessTimeIcon
                          style={{fill: 'white', width: '16px', height: '16px'}}
                        />
                      }
                    />
                  </HalfContainer>
                </FullContainer>
                <StyledFormControl>
                  <InputLabel shrink htmlFor='form-select'>
                    Location
                  </InputLabel>
                  <NativeSelect
                    name='locationType'
                    id='locationType'
                    value={values.locationType}
                    onChange={handleChange}>
                    {locationList.map((item) => (
                      <option
                        key={item.name}
                        value={item.name}>{`${item.name}`}</option>
                    ))}
                  </NativeSelect>
                </StyledFormControl>
                <TextField
                  variant='outlined'
                  className={classes.textField}
                  placeholder='Location'
                  id='locationAddress'
                  value={values.locationAddress}
                  onChange={handleChange}
                />
                <StyledAutocomplete
                  multiple
                  options={!!contacts ? contacts : []}
                  renderTags={(value: any) =>
                    value.map((option: string, index: number) => (
                      <MemberChip name={option} key={index} />
                    ))
                  }
                  onChange={(value: any) => {
                    for (let i: number = 0; i < value.length; i++) {
                      setFieldValue(`members[${i}].name`, value[i])
                    }
                  }}
                  renderInput={(
                    params:
                      | (JSX.IntrinsicAttributes & StandardTextFieldProps)
                      | (JSX.IntrinsicAttributes & FilledTextFieldProps)
                      | (JSX.IntrinsicAttributes & OutlinedTextFieldProps),
                  ) => (
                    <StyledTextField
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='Members'
                      placeholder='+ Add'
                    />
                  )}
                />
                <StyledTextInput
                  label='Description'
                  id='description'
                  value={values.description}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <EditIcon
                          style={{
                            fill: 'white',
                            cursor: 'pointer',
                            height: '18px',
                            width: '18px',
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder='Description'
                />

                <StyledAutocomplete
                  multiple
                  options={
                    !!tagList ? tagList.map((option) => option.name) : []
                  }
                  renderTags={(value: any) =>
                    value.map((option: string, index: number) => (
                      <Tag text={option} size='medium' key={index} />
                    ))
                  }
                  onChange={(value: any) => {
                    for (let i: number = 0; i < value.length; i++) {
                      setFieldValue(`tags[${i}].title`, value[i])
                    }
                  }}
                  id='tags'
                  renderInput={(params: any) => (
                    <StyledTextField
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='Tags'
                      placeholder='+ Add'
                    />
                  )}
                />
                <AttachmentsButton />
              </EventFormDiv>
              <FieldArray
                name='agendaItems'
                render={(arrayHelpers) => (
                  <AgendaFormDiv>
                    <HeaderDiv>
                      <AssignmentIcon
                        style={{fill: 'white', width: '18px', height: '22px'}}
                      />
                      <Title>Agenda</Title>
                    </HeaderDiv>
                    {values.agendaItems.map((item, index) => {
                      const title: string = `agendaItems[${index}].title`
                      const duration: string = `agendaItems[${index}].duration`
                      const description: string = `agendaItems[${index}].description`
                      const additionalInformation: string = `agendaItems[${index}].additionalInformation`

                      return (
                        <StyledAccordion
                          expanded={expanded === `$panel${index}`}
                          onChange={handleChanges(`$panel${index}`)}
                          key={index}>
                          <StyledAccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                style={{
                                  fill: 'white',
                                  cursor: 'pointer',
                                  height: '18px',
                                  width: '18px',
                                }}
                              />
                            }>
                            {`Title: ${item.title}`}
                          </StyledAccordionSummary>
                          <div>
                            <StyledTextInput
                              label='Title'
                              id='agendaItems.title'
                              name={title}
                              value={item.title}
                              onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <EditIcon
                                      style={{
                                        fill: 'white',
                                        cursor: 'pointer',
                                        height: '18px',
                                        width: '18px',
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder='Title'
                            />
                            <StyledFormControl>
                              <InputLabel shrink htmlFor='form-select'>
                                Duration
                              </InputLabel>
                              <NativeSelect
                                id='agendaItems.duration'
                                name={duration}
                                value={item.duration}
                                onChange={handleChange}>
                                {durationList.map((i) => (
                                  <option
                                    key={i.name}
                                    value={i.name}>{`${i.name}`}</option>
                                ))}
                              </NativeSelect>
                            </StyledFormControl>
                            <StyledTextInput
                              label='Description'
                              id='agendaItems.description'
                              name={description}
                              value={item.description}
                              onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <EditIcon
                                      style={{
                                        fill: 'white',
                                        cursor: 'pointer',
                                        height: '18px',
                                        width: '18px',
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder='Description'
                            />
                            <StyledTextInput
                              label='Include any additional information'
                              id='agendaItems.additionalInformation'
                              name={additionalInformation}
                              value={item.additionalInformation}
                              onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <EditIcon
                                      style={{
                                        fill: 'white',
                                        cursor: 'pointer',
                                        height: '18px',
                                        width: '18px',
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder='Additional Information'
                            />
                            <RemoveAgendaItem
                              type='button'
                              onClick={() => arrayHelpers.remove(index)}>
                              Remove Item
                            </RemoveAgendaItem>
                          </div>
                        </StyledAccordion>
                      )
                    })}
                    <StyledButton
                      onClick={(e: {preventDefault: () => void}) => {
                        e.preventDefault()
                        arrayHelpers.push({
                          title: '',
                          duration: '',
                          description: '',
                          additionalInformation: '',
                        })
                      }}>
                      <PlusIcon>
                        <SvgPlus fill='#878787' width={24} height={24} />
                      </PlusIcon>
                      <Description>Add Agenda Item</Description>
                    </StyledButton>
                  </AgendaFormDiv>
                )}
              />
            </FormContainer>
            <ButtonContainer>
              <SettingsButton />
              <MenuButton />
              <SimpleButton title='Cancel' size='small' color='black' />
            </ButtonContainer>
          </MainContainer>
        </ThemeProvider>
      )}
    </Formik>
  )
}

export default EventFormNative
