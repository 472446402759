import Grid from '@material-ui/core/Grid'
import React, {FC, useEffect, useState} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import TextInput from '../TextInput/TextInput'
import {TailSpin} from '../../assets/icons/tail-spin'
import {RootStateOrAny, useSelector, useDispatch} from 'react-redux'
import {validateForm, FormAttr} from '../../core/utility/Utils'

import {
  onVerificationCode,
  onForgotPassword,
  onForgotPasswordSubmit,
} from '../../redux/actions/CognitoAuth'
import {useHistory} from 'react-router'

export interface SignActionsProps {
  title: string
  type: 'recover' | 'thanks' | 'success'
  buttonText?: string
  subtitle?: string
}

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  font-size: 32px;
  line-height: 42px;
  font-style: normal;
  font-weight: bold;
  margin: 80px 0 80px;
`

const Title = styled.div`
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  color: white;
`

const Subtitle = styled.div`
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  margin-top: 30px;
  color: ${BizlyPalette['text-gray']};
`

const ActionButton = styled.button`
  width: 100%;
  outline: none;
  border: none;
  font-family: 'Lato', sans-serif;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BizlyPalette['base-purple']};
  height: 50px;
  font-size: 16px;
  margin: 40px 0;
  border-radius: 10px;
  cursor: pointer;
`

const TextDiv = styled.div`
  margin-bottom: 60px;
`

// const Link = styled.a`
//   color: ${BizlyPalette['text-gray']};
//   text-decoration: none;
//   cursor: pointer;
//   font-family: 'Lato', sans-serif;
// `

const SignButton = styled.button`
  background-color: ${BizlyPalette['base-black']};
  border: 1px solid ${BizlyPalette['outline-gray']};
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 12px 12px;
  border-radius: 10px;
  margin-bottom: 60px;
`

const Logo = styled('div')<{type: 'recover' | 'thanks' | 'success'}>`
  height: 207px;
  width: 207px;
  border-radius: ${(props) =>
    props.type === 'recover'
      ? '50% 0 50% 50%'
      : props.type === 'success'
      ? '50% 0 50% 50%'
      : '50% 50% 0 50%'};
  background-color: ${(props) =>
    props.type === 'recover'
      ? BizlyPalette['light-yellow']
      : props.type === 'success'
      ? BizlyPalette['base-purple']
      : BizlyPalette['base-purple']};
`

const SignActions: FC<SignActionsProps> = ({
  title,
  subtitle,
  // buttonText,
  type,
}) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [
    forgotPasswordConfirmationCodeError,
    setForgotPasswordConfirmationCodeError,
  ] = useState('')
  const [
    forgotPasswordConfirmationCode,
    setForgotPasswordConfirmationCode,
  ] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationCodeError, setVerificationCodeError] = useState('')
  const {loading, error} = useSelector((state: RootStateOrAny) => state.common)
  const {
    waitingVerificationCode,
    emailForm,
    forgotPassword,
    forgotPasswordSubmit,
  } = useSelector((state: RootStateOrAny) => state.cognitoAuth)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleVerification = () => {
    const formToValidate: Array<FormAttr> = [
      {
        name: 'Verification code',
        value: verificationCode,
        required: true,
        onError: setVerificationCodeError,
      },
    ]

    const isValid = validateForm(formToValidate)

    if (!isValid) {
      return
    }
    dispatch(onVerificationCode(emailForm, verificationCode))
  }

  const handleForgotPassword = () => {
    const formToValidate: Array<FormAttr> = [
      {
        name: 'Email',
        value: email,
        type: 'email',
        required: true,
        onError: setEmailError,
      },
    ]

    const isValid = validateForm(formToValidate)

    if (!isValid) {
      return
    }

    dispatch(onForgotPassword(email))
  }

  const handleForgotPasswordSubmit = () => {
    const formToValidate: Array<FormAttr> = [
      {
        name: 'Password',
        value: password,
        required: true,
        onError: setPasswordError,
      },
      {
        name: 'Verification code',
        value: forgotPasswordConfirmationCode,
        required: true,
        onError: setForgotPasswordConfirmationCodeError,
      },
    ]

    const isValid = validateForm(formToValidate)

    if (!isValid) {
      return
    }

    dispatch(
      onForgotPasswordSubmit(
        emailForm,
        forgotPasswordConfirmationCode,
        password,
      ),
    )
  }

  useEffect(() => {
    if (!waitingVerificationCode && type === 'thanks') {
      history.push('/')
    }

    if (forgotPasswordSubmit && type === 'recover') {
      window.location.href = '/'
    }
  })

  useEffect(() => {
    setVerificationCodeError('')
  }, [verificationCode])

  useEffect(() => {
    setEmailError('')
  }, [email])

  useEffect(() => {
    setPasswordError('')
  }, [password])

  useEffect(() => {
    setForgotPasswordConfirmationCodeError('')
  }, [forgotPasswordConfirmationCode])

  let content

  switch (type) {
    case 'recover':
      content = (
        <React.Fragment>
          <Grid item xs={12}>
            <HeaderDiv>
              <Logo type={type} />
            </HeaderDiv>
          </Grid>
          <Grid item xs={12}>
            <TextDiv>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
            </TextDiv>
          </Grid>
          {!forgotPassword ? (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextInput
                  required
                  error={!!error || !!emailError}
                  errorMessage={error.message || emailError}
                  onChange={setEmail}
                  value={email}
                  title='Email'
                  adornment='mail'
                  type='text'
                />
              </Grid>
              <Grid item container justify='center' xs={12}>
                {loading ? (
                  <TailSpin className='tail-spin' style={{margin: '40px 0'}} />
                ) : (
                  <ActionButton type='submit' onClick={handleForgotPassword}>
                    Continue
                  </ActionButton>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextInput
                  required
                  error={!!passwordError}
                  errorMessage={passwordError}
                  value={password}
                  onChange={setPassword}
                  title='Password'
                  adornment='password'
                  type='password'
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  required
                  error={!!forgotPasswordConfirmationCodeError || !!error}
                  errorMessage={
                    forgotPasswordConfirmationCodeError || error.message
                  }
                  type='number'
                  onChange={setForgotPasswordConfirmationCode}
                  title='Verification code'
                />
              </Grid>
              <Grid item container justify='center' xs={12}>
                {loading ? (
                  <TailSpin className='tail-spin' style={{margin: '40px 0'}} />
                ) : (
                  <ActionButton
                    type='submit'
                    onClick={handleForgotPasswordSubmit}>
                    Recover
                  </ActionButton>
                )}
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      )
      break

    case 'thanks':
      content = (
        <React.Fragment>
          <Grid item xs={12}>
            <HeaderDiv>
              <Logo type={type} />
            </HeaderDiv>
          </Grid>
          <Grid item xs={12}>
            <TextDiv>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
            </TextDiv>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextInput
                required
                error={!!error || !!verificationCodeError}
                errorMessage={error.message || verificationCodeError}
                type='number'
                onChange={setVerificationCode}
                title='Verification code'
              />
            </Grid>
            <Grid item container justify='center' xs={12}>
              {loading ? (
                <TailSpin className='tail-spin' style={{margin: '40px 0'}} />
              ) : (
                <SignButton type='submit' onClick={handleVerification}>
                  Verify
                </SignButton>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      )
      break

    case 'success':
      content = (
        <React.Fragment>
          <Grid item xs={12}>
            <HeaderDiv>
              <Logo type={type} />
            </HeaderDiv>
          </Grid>
          <Grid item xs={12}>
            <TextDiv>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
            </TextDiv>
          </Grid>
          <Grid
            item
            xs={12}
            style={{display: 'flex', justifyContent: 'center'}}>
            <SignButton>Sign In</SignButton>
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid
              item
              xs={3}
              style={{display: 'flex', justifyContent: 'center'}}>
              <div
                style={{
                  height: '30px',
                  width: '30px',
                  borderRadius: '50% 0 50% 0',
                  backgroundColor: BizlyPalette['light-yellow'],
                }}></div>
            </Grid>
            <Grid
              item
              xs={3}
              style={{display: 'flex', justifyContent: 'center'}}>
              <div
                style={{
                  height: '30px',
                  width: '30px',
                  borderRadius: '0 50% 0 0',
                  backgroundColor: '#dee1f1',
                }}></div>
            </Grid>
            <Grid
              item
              xs={3}
              style={{display: 'flex', justifyContent: 'center'}}>
              <div
                style={{
                  height: '30px',
                  width: '30px',
                  borderRadius: '50% 50% 50% 0',
                  backgroundColor: '#40b9d4',
                }}></div>
            </Grid>
            <Grid
              item
              xs={3}
              style={{display: 'flex', justifyContent: 'center'}}>
              <div
                style={{
                  height: '30px',
                  width: '30px',
                  borderRadius: '0 0 50% 0',
                  backgroundColor: '#fea4ae',
                }}></div>
            </Grid>
          </Grid>
        </React.Fragment>
      )
      break

    default:
      content = ''
      break
  }

  return (
    <Grid
      container
      style={{display: 'flex', justifyContent: 'center', maxWidth: '450px'}}>
      {content}
    </Grid>
  )
}

export default SignActions
