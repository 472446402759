import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

export interface InfoBoxProps {
  text: string
  number: number
  color?: string
}

const Container = styled.div`
  max-width: 366px;
  border-radius: 8px;
  border: 1px solid ${BizlyPalette['outline-gray']};
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: 'Lato', sans-serif;
  color: ${BizlyPalette['avatar-gray']};
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 24px;

  @media ${BreakpointsMediaQueries.md} {
    width: 49%;
  }

  @media ${BreakpointsMediaQueries.sm} {
    width: 100%;
    padding: 0 20px;
  } ;
`

const TextBox = styled.p`
  margin: 0;
  max-width: 201px;
  font-size: 36px;
  line-height: 35px;
  font-weight: normal;

  @media ${BreakpointsMediaQueries.lg} {
    font-size: 22px;
  }

  @media ${BreakpointsMediaQueries.md} {
    font-size: 24px;
  } ;
`

const NumberBox = styled('p')<{color: string | undefined; fontSize: string}>`
  margin: 0 12px 0 0;
  line-height: 108px;
  font-weight: bold;
  color: ${(props) => (props.color === 'yellow' ? '#FEC000' : '#5E1A6F')};
  font-size: ${(props) => props.fontSize};

  @media ${BreakpointsMediaQueries.lg} {
    font-size: 40px;
  }

  @media ${BreakpointsMediaQueries.md} {
    font-size: 50px;
  } ;
`

const InfoBox: FC<InfoBoxProps> = ({color, text, number}) => {
  const size: string = number < 10 ? 'small' : number < 100 ? 'normal' : 'large'

  let fontSize: string
  switch (size) {
    case 'small':
      fontSize = '90px'
      break
    case 'normal':
      fontSize = '74px'
      break
    case 'large':
      fontSize = '53px'
      break
    default:
      fontSize = '10px'
      break
  }

  return (
    <Container>
      <NumberBox fontSize={fontSize} color={color}>
        {number}
      </NumberBox>
      <TextBox>{text}</TextBox>
    </Container>
  )
}

export default InfoBox
