import {
  Camera,
  Clear,
  MeetingManager,
  Microphone,
  ScreenShare,
  useContentShareControls,
  useLocalVideo,
  useMeetingManager,
  useToggleLocalMute,
  VideoTileGrid,
} from 'amazon-chime-sdk-component-library-react'
import React, {useEffect, useState} from 'react'
import {RootStateOrAny, useSelector} from 'react-redux'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import axios from 'axios'
import Loader from '../../core/components/Loader'
import TextInput from '../TextInput/TextInput'
import {Auth} from 'aws-amplify'

const StyledButton = styled.button`
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background-color: white;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;

  &: active {
    background-color: yellow;
  }
`

const StyledJoinButton = styled.div`
  display: flex;
  min-height: 100px;
  min-width: 100px;
  border-radius: 50%;
  background-color: white;
  outline: none;
  border: none;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

const StyledExitButton = styled.button`
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background-color: white;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledVideoTileGrid = styled(VideoTileGrid)`
  width: 100%;
  height: 80%;
  background-color: ${BizlyPalette['base-black']};
  border: 1px solid white;
  border-radius: 6px;
`

const StyledVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 60px;
  width: 100%;
  height: 100%;
`

const ButtonsContainer = styled.div`
  margin: 60px auto 0 auto;
  width: 50%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

const JoinMeetingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`

const MainContainer = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const MeetingIdText = styled.p`
  color: white;
  margin-top: 32px;
`

enum NewMeetingEvent {
  create = 'CREATE',
  join = 'JOIN',
}

interface NewMeetingInterface {
  event: NewMeetingEvent
  meetingId?: string
  userId: string
  token: string
}

const joinOrCreateMeetingSession = async (
  newMeetingProps: NewMeetingInterface,
) => {
  try {
    const refreshUser = await Auth.currentAuthenticatedUser()
    let meetingId
    if (newMeetingProps.event === NewMeetingEvent.create) {
      const {
        data: {
          Meeting: {MeetingId},
        },
      } = await axios.post(
        'https://cnb6pcohve.execute-api.us-west-2.amazonaws.com/dev/meeting',
        {},
        {
          headers: {
            Authorization: `Bearer ${refreshUser.signInUserSession.idToken.jwtToken}`,
          },
        },
      )
      meetingId = MeetingId
    }

    const {
      data: {Attendee, Meeting},
    } = await axios.post(
      `https://cnb6pcohve.execute-api.us-west-2.amazonaws.com/dev/attendee?meetingId=${
        meetingId || newMeetingProps.meetingId
      }&userId=${newMeetingProps.userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshUser.signInUserSession.idToken.jwtToken}`,
        },
      },
    )

    const joinData: {meetingInfo: any; attendeeInfo: any} = {
      meetingInfo: Meeting,
      attendeeInfo: Attendee,
    }
    return {joinData}
  } catch (error) {
    console.log(error)
    return {error: 'Error creating meeting session'}
  }
}

const VideoChat = () => {
  const meetingManager: MeetingManager = useMeetingManager()
  const {isVideoEnabled, toggleVideo}: any = useLocalVideo()
  const {muted, toggleMute}: any = useToggleLocalMute()
  const {toggleContentShare}: any = useContentShareControls()
  const {user} = useSelector((state: RootStateOrAny) => state.cognitoAuth)
  const [meeting, setMeeting] = useState({
    meetingInfo: null,
    attendeeInfo: null,
  })
  const [loading, setLoading] = useState(false)
  const [joinMeetingId, setJoinMeetingId] = useState('')
  const [meetingSessionError, setMeetingSessionError] = useState('')

  useEffect(() => {
    setMeetingSessionError('')
  }, [joinMeetingId])

  const leaveMeeting = () => {
    meetingManager.leave()
    setMeeting({meetingInfo: null, attendeeInfo: null})
  }

  const handleNewMeetingSession = (event: NewMeetingEvent) => async () => {
    if (event === NewMeetingEvent.join && !joinMeetingId) {
      setMeetingSessionError('Meeting id cannot be empty')
      return
    }

    setLoading(true)

    const {error, joinData} = await joinOrCreateMeetingSession({
      event,
      meetingId: joinMeetingId,
      userId: user.attributes.sub,
      token: user.signInUserSession.idToken.jwtToken,
    })

    if (error) {
      setMeetingSessionError(error)
    }

    if (joinData) {
      await meetingManager.join(joinData)
      await meetingManager.start()
      setMeeting(joinData)
      setJoinMeetingId(joinData.meetingInfo.MeetingId)
    }
    setLoading(false)
  }

  return (
    <MainContainer>
      {loading && <Loader />}
      {!loading && !meeting.meetingInfo && (
        <ControlsContainer>
          <StyledJoinButton
            onClick={handleNewMeetingSession(NewMeetingEvent.create)}>
            Start Meeting
          </StyledJoinButton>
          <JoinMeetingContainer>
            <StyledJoinButton
              onClick={handleNewMeetingSession(NewMeetingEvent.join)}>
              Join Meeting
            </StyledJoinButton>
            <TextInput
              required
              error={!!meetingSessionError}
              errorMessage={meetingSessionError}
              value={joinMeetingId}
              type='text'
              onChange={setJoinMeetingId}
              title='Meeting ID'
            />
          </JoinMeetingContainer>
        </ControlsContainer>
      )}
      {!loading && meeting.meetingInfo && (
        <StyledVideo>
          <StyledVideoTileGrid layout='featured' />
          <ButtonsContainer>
            <StyledButton onClick={toggleVideo}>
              <Camera disabled={!isVideoEnabled} />
            </StyledButton>
            <StyledButton onClick={toggleMute}>
              <Microphone muted={muted} />
            </StyledButton>
            <StyledButton onClick={toggleContentShare}>
              <ScreenShare />
            </StyledButton>
            <StyledExitButton onClick={leaveMeeting}>
              <Clear />
            </StyledExitButton>
          </ButtonsContainer>
          <MeetingIdText>{`Meeting Id: ${joinMeetingId}`}</MeetingIdText>
        </StyledVideo>
      )}
    </MainContainer>
  )
}

export default VideoChat
