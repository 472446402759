import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import GetInitials from '../../assets/utils/GetInitials'

export interface AvatarChipProps {
  avatar?: string
  name?: string
  number?: string
}

const Chip = styled.a`
  font-family: 'Lato', sans-serif;
  min-height: 30px;
  min-width: 30px;
  border-radius: 50%;
  background-color: ${BizlyPalette['avatar-gray']};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin: -8px;
  border: 1px solid black;
  transition: margin 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    border: 1px solid ${BizlyPalette['base-purple']};
  }
`

const Image = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`

const AvatarChip: FC<AvatarChipProps> = ({avatar, name, number}) => {
  const initials: string = GetInitials(name || '')

  if (!!avatar) {
    return (
      <Chip>
        <Image src={avatar} />
      </Chip>
    )
  }
  return (
    <Chip>
      {number}
      {initials}
    </Chip>
  )
}

export default AvatarChip
