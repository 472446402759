// import BackspaceIcon from '@material-ui/icons/Backspace'
import React, {FC} from 'react'
import styled from 'styled-components'

export interface TagProps {
  text: string
  size: string
  color?: string
}

const Text = styled.span`
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  margin-right: 12px;
`

// const Button = styled.button`
//     padding: 0;
//     border: 1px solid $white;
//     background-color: transparent;
//     outline: none;
//     border: none;
//     display: flex;
//     height: 24px;
//     width: 24px;
//     border-radius: 8px;
//     justify-content: space-around;
//     align-items: center;
//     cursor: pointer;

//     &:active {
//         background-color: $dimmed-gray;
//     }
// `

const Container = styled('div')<{
  buttonMaxWidth: string
  buttonMinWidth: string
  buttonHeight: string
  buttonFontSize: string
  borderColor: string
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Lato', sans-serif;
  height: ${(props) => props.buttonHeight};
  max-width: ${(props) => props.buttonMaxWidth};
  min-width: ${(props) => props.buttonMinWidth};
  padding: 0 8px;
  border: 1px solid ${(props) => props.borderColor};
  margin: 8px 8px 8px 0;
  color: white;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: bold;
  font-size: ${(props) => props.buttonFontSize};
  line-height: 17px;
  overflow: hidden;
`

const Tag: FC<TagProps> = ({text, size, color}) => {
  const colors: Array<string> = ['#6c1236', '#18a0fb', '#1e5e13'] // red, blue, greeen

  let borderColor: string

  switch (color) {
    case 'red':
      borderColor = colors[0]
      break
    case 'blue':
      borderColor = colors[1]
      break
    case 'green':
      borderColor = colors[2]
      break
    default:
      borderColor = 'white'
      break
  }

  let buttonMaxWidth: string
  let buttonMinWidth: string
  let buttonHeight: string
  let buttonFontSize: string

  switch (size) {
    case 'mini':
      buttonMaxWidth = '90px'
      buttonMinWidth = '45px'
      buttonHeight = '18px'
      buttonFontSize = '6px'
      break
    case 'medium':
      buttonMaxWidth = '200px'
      buttonMinWidth = '120px'
      buttonHeight = '36px'
      buttonFontSize = '14px'
      break
    default:
      buttonMaxWidth = '100%'
      buttonMinWidth = '100%'
      buttonHeight = '36px'
      buttonFontSize = '14px'
  }

  return (
    <Container
      borderColor={borderColor}
      buttonMaxWidth={buttonMaxWidth}
      buttonMinWidth={buttonMinWidth}
      buttonHeight={buttonHeight}
      buttonFontSize={buttonFontSize}>
      <Text>{text}</Text>
      {/* <Button type='button'>
                <BackspaceIcon style={{ fill: 'white', height: '12px', width: '14px' }} />
            </Button> */}
    </Container>
  )
}

export default Tag
