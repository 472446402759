import InputAdornment from '@material-ui/core/InputAdornment'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import GetAppIcon from '@material-ui/icons/GetApp'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, {VFC} from 'react'
import styled from 'styled-components'

import SvgPlus from '../../assets/icons/SvgPlus'
import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
interface Attachments {
  name: string
}

export interface AttachmentsTooltipProps {
  attachments: Array<Attachments>
}

const Container = styled.div`
  box-sizing: border-box;
  padding: 10px;
  font-family: 'Lato', sans-serif;
  width: 167px;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  border: 1px solid black;
  background-color: ${BizlyPalette['light-black']};
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderText = styled.div`
  display: flex;
  align-items: baseline;
`

const HeaderSearch = styled.div`
  border-bottom: 1px solid white;
  margin-top: 10px;
`

const Button = styled.button`
  border: 1px solid ${BizlyPalette['outline-gray']};
  margin-top: 8px;
  width: 100%;
  border-radius: 10px;
  height: 25px;
  font-size: 10px;
  line-height: 12px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${BizlyPalette['light-gray']};
  outline: none;
  cursor: pointer;

  &:active {
    background-color: ${BizlyPalette['base-black']};
  }

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }
`

const ButtonIcon = styled.span`
  display: flex;
  flex: 1;
`

const ButtonText = styled.span`
  flex: 10;
  color: ${BizlyPalette['outline-gray']};
`

const MemberTitle = styled.p`
  margin: 0 10px 0 0;
  color: ${BizlyPalette['light-yellow']};
  font-weight: bold;
`

const MemberItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`

const MemberList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  font-weight: bold;
  color: ${BizlyPalette['lighter-gray']};
`

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      color: 'white',
      '& div': {
        padding: '0 !important',
        color: 'white',
      },
    },
    textField: {
      padding: '0',
      '& input::placeholder': {
        color: 'white',
        fontSize: '12px',
      },
      '& input': {
        color: 'white',
        fontSize: '12px',
      },
    },
  }),
)

const AttachmentsTooltip: VFC<AttachmentsTooltipProps> = ({attachments}) => {
  const classes = useStyles()

  const AttachmentsContent: string =
    attachments.length === 1
      ? `${attachments.length} guest`
      : `${attachments.length} Attachments`

  const AttachmentsList = []
  for (let i: number = 0; i < attachments.length; i++) {
    AttachmentsList.push(
      <MemberItem>
        {attachments[i].name}
        <GetAppIcon style={{fill: '#898B8E', width: '12px', height: '12px'}} />
      </MemberItem>,
    )
  }

  const AutocompleteList = []
  for (let i: number = 0; i < attachments.length; i++) {
    AutocompleteList.push(attachments[i].name)
  }

  return (
    <Container>
      <Header>
        <HeaderText>
          <MemberTitle>{AttachmentsContent}</MemberTitle>
        </HeaderText>
        <HeaderSearch>
          <Autocomplete
            id='autcomplete-field'
            options={AutocompleteList}
            className={classes.container}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon
                        style={{fill: 'white', width: '12px', height: '12px'}}
                      />
                    </InputAdornment>
                  ),
                }}
                placeholder='Search'
              />
            )}
          />
        </HeaderSearch>
      </Header>
      <div>
        <MemberList>{AttachmentsList}</MemberList>
        <Button>
          <ButtonIcon>
            <SvgPlus fill='#878787' width={12} height={12} />
          </ButtonIcon>
          <ButtonText>Add File</ButtonText>
        </Button>
      </div>
    </Container>
  )
}

export default AttachmentsTooltip
