import AttachmentIcon from '@material-ui/icons/Attachment'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import AgendaItem, {AgendaItemProps} from '../AgendaItem/AgendaItem'
import AttachmentsTooltip from '../AttachmentsTooltip/AttachmentsTooltip'
export interface AgendaItemsContainerProps {
  title?: string
  description?: string
  isInform?: boolean
  agendaItems: Array<AgendaItemProps>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  color: white;
  max-width: 840px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;

  @media ${BreakpointsMediaQueries.sm} {
    padding: 10px;
  } ;
`

const AttachmentLength = styled.span`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  margin-right: 4px;
  color: black;
  background-color: ${BizlyPalette['light-gray']};
`

const AttachmentsContainer = styled.div`
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
`

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 98%;
  max-width: 480px;
`
const Title = styled('h1')<{title: string | undefined}>`
  margin: 0 0 30px 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  display: ${(props) => (!!props.title ? 'block' : 'none')};
`

const Description = styled('p')<{description: string | undefined}>`
  margin: 0 0 30px 0;
  font-size: 15px;
  line-height: 26px;
  display: ${(props) => (!!props.description ? 'block' : 'none')};
`

const IsInformContainer = styled('div')<{isInform: boolean | undefined}>`
  display: ${(props) => (props.isInform ? 'flex' : 'none')};
  cursor: pointer;

  &:active ${AttachmentsContainer} {
    display: block;
  }
`

const InnerContainerTitle = styled('div')<{isInform: boolean | undefined}>`
  margin: ${(props) => (props.isInform ? '0 0 30px 0' : '0 0 30px 10%')};
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
`

const Attachments: {
  name: string
}[] = [
  {name: 'deepcollab.docx'},
  {name: 'dctechniques.json'},
  {name: 'dctechniques.pdf'},
]

const AgendaItemsContainer: FC<AgendaItemsContainerProps> = ({
  title,
  description,
  agendaItems,
  isInform,
}) => {
  return (
    <Container>
      <Title title={title}>{title}</Title>
      <Description description={description}>{description}</Description>
      <div>
        <HeaderContainer>
          <InnerContainerTitle isInform={isInform}>Agenda:</InnerContainerTitle>
          <IsInformContainer isInform={isInform}>
            <AttachmentIcon
              style={{
                fill: '#707078',
                width: '20px',
                height: '20px',
                marginRight: '12px',
              }}
            />
            <AttachmentLength>{agendaItems.length}</AttachmentLength>
            <AttachmentsContainer>
              <AttachmentsTooltip attachments={Attachments} />
            </AttachmentsContainer>
          </IsInformContainer>
        </HeaderContainer>
        {agendaItems.map((item, i) => (
          <AgendaItem
            timeNumber={item.duration?.split(' ')[0]}
            timeUnit={item.duration?.split(' ')[1]}
            title={item.title}
            description={item.description}
            isInform={false}
            key={i}
          />
        ))}
      </div>
    </Container>
  )
}

export default AgendaItemsContainer
