import React, {FC} from 'react'
import {Route} from 'react-router-dom'

import FormPageData from '../../assets/utils/FormPageData'
import AgendaInfoPage from '../../components/AgendaInfoPage/AgendaInfoPage'
import AgendaPage from '../../components/AgendaPage/AgendaPage'
import CalendarPage from '../../components/CalendarPage/CalendarPage'
import FormPage from '../../components/FormPage/FormPage'
import HomePage from '../../components/HomePage/HomePage'
import IntegrationPage from '../../components/IntegrationPage/IntegrationPage'
import LeftNavBar from '../../components/LeftNavBar/LeftNavBar'
import PlaybookPage from '../../components/PlaybookPage/PlaybookPage'

// tslint:disable-next-line: no-empty-interface
export interface BizlyMainProps {}

// eslint-disable-next-line no-empty-pattern
const BizlyMain: FC<BizlyMainProps> = ({}) => {
  return (
    <div
      className='BizlyMain'
      style={{display: 'flex', justifyContent: 'center'}}>
      <LeftNavBar />
      <Route path='/home' exact render={() => <HomePage />} />
      <Route
        path='/settings-integrations'
        exact
        render={() => <IntegrationPage />}
      />
      <Route path='/calendar' exact render={() => <CalendarPage />} />
      <Route
        path='/new-event'
        exact
        render={(props) => (
          <FormPage
            {...props}
            playBookList={FormPageData.playBookList}
            locationList={FormPageData.locationList}
            tagList={FormPageData.tagList}
            memberList={FormPageData.memberList}
            durationList={FormPageData.durationList}
          />
        )}
      />
      <Route path='/agenda' exact render={() => <AgendaInfoPage />} />
      <Route path='/playbook' exact render={() => <PlaybookPage />} />
      <Route path='/agendacontrol' exact render={() => <AgendaPage />} />
    </div>
  )
}

export default BizlyMain
