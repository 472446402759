import {
  CalendarReducer,
  DayEventsReducer,
  EventsReducer,
  PlaybookReducer,
  SettingsReducer,
  UserReducer,
} from './ApiReducer'
import Auth from './Auth'
import CognitoAuth from './CognitoAuthReducer'
import CommonReducer from './CommonReducer'
import Settings from './Setting'
import ProviderReducer from './ProviderApiReducer'

// tslint:disable-next-line: typedef
const reducers = {
  settings: Settings,
  auth: Auth,
  cognitoAuth: CognitoAuth,
  common: CommonReducer,
  dayEvents: DayEventsReducer,
  calendarEvents: CalendarReducer,
  settingsData: SettingsReducer,
  playbookData: PlaybookReducer,
  userData: UserReducer,
  appData: EventsReducer,
  providerData: ProviderReducer,
}

export default reducers
