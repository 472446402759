import React, {VFC} from 'react'
import styled from 'styled-components'

import SvgCalendar from '../../assets/icons/SvgCalendar'
import SvgEmail from '../../assets/icons/SvgEmail'
import {TailSpin} from '../../assets/icons/tail-spin'
import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import ConnectButton from '../ConnectButton/ConnectButton'
import ServiceButton from '../ServiceButton/ServiceButton'
import SimpleButton from '../SimpleButton/SimpleButton'
import TextInput from '../TextInput/TextInput'

export interface ActionBoxProps {
  title?: string
  icon?: string
  subtitle?: string
  state?: string
  type?: string
}

const Title = styled.h1`
  font-size: 30px;
  line-height: 42px;
  max-width: 308px;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  margin-bottom: 56px;
  flex: 1;
  text-align: center;

  @media ${BreakpointsMediaQueries.sm} {
    margin-bottom: 24px;
    font-size: 24px;
  }
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 3;
  min-height: 260px;

  @media ${BreakpointsMediaQueries.sm} {
    min-height: unset;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 308px;
  box-sizing: border-box;
  width: 100%;

  @media ${BreakpointsMediaQueries.sm} {
    margin-bottom: 12px;
  }
`

const SubtitleContainer = styled.p`
  font-size: 14px;
  max-width: 308px;
  width: 100%;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
  margin: 0 0 40px;
  color: ${BizlyPalette['subtitle-gray']};

  @media ${BreakpointsMediaQueries.sm} {
    margin-bottom: 20px;
  }
`

const IconContainer = styled.div`
  height: 94px;
  width: 97px;
  margin: 0 0 56px 0;

  @media ${BreakpointsMediaQueries.sm} {
    display: none;
  }
`

const ActionContainer = styled('div')<{
  bodyBorder: string
  bodyBackgroundColor: string
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: 'Lato', sans-serif;
  border-radius: 8px;
  border: 1px solid ${(props) => props.bodyBorder};
  background-color: ${(props) => props.bodyBackgroundColor};
  padding: 42px 38px 34px;
  box-sizing: border-box;
  margin-bottom: 24px;
  margin-right: 24px;
  width: 100%;
  max-width: 390px;

  @media ${BreakpointsMediaQueries.xl} {
    max-width: 380px;
  }

  @media ${BreakpointsMediaQueries.md} {
    margin-right: 0;
  }

  @media ${BreakpointsMediaQueries.sm} {
    padding: 12px;
    border: none;
  }
`

const ActionBox: VFC<ActionBoxProps> = ({title, subtitle, state, type}) => {
  let bodyContent

  switch (type) {
    case 'ImportContacts':
      bodyContent = (
        <BodyContainer>
          <IconContainer>
            <SvgEmail width={97} height={94} fill={BizlyPalette['icon-gray']} />
          </IconContainer>
          <SubtitleContainer>{subtitle}</SubtitleContainer>
          <ButtonsContainer>
            <ServiceButton type='circle' />
            <ServiceButton type='gdocs' />
            <ServiceButton type='msteams' />
          </ButtonsContainer>
        </BodyContainer>
      )
      break
    case 'ImportCalendar':
      bodyContent = (
        <BodyContainer>
          <IconContainer>
            <SvgCalendar
              width={97}
              height={94}
              fill={BizlyPalette['icon-gray']}
            />
          </IconContainer>
          <SubtitleContainer>{subtitle}</SubtitleContainer>
          <ButtonsContainer>
            <ServiceButton type='circle' />
            <ServiceButton type='gdocs' />
            <ServiceButton type='msteams' />
          </ButtonsContainer>
        </BodyContainer>
      )
      break
    case 'ContactsSuccessImport':
      bodyContent = (
        <BodyContainer>
          <IconContainer>
            <SvgEmail width={97} height={94} fill={BizlyPalette['icon-gray']} />
          </IconContainer>
          <SubtitleContainer style={{fontSize: '42px', marginBottom: '60px'}}>
            {subtitle}
          </SubtitleContainer>
        </BodyContainer>
      )
      break
    case 'CalendarSuccessImport':
      bodyContent = (
        <BodyContainer>
          <IconContainer>
            <SvgCalendar
              width={97}
              height={94}
              fill={BizlyPalette['icon-gray']}
            />
          </IconContainer>
          <SubtitleContainer style={{fontSize: '42px', marginBottom: '60px'}}>
            {subtitle}
          </SubtitleContainer>
        </BodyContainer>
      )
      break
    case 'ConnectToServices':
      bodyContent = (
        <BodyContainer>
          <ConnectButton type='gdocs' />
          <ConnectButton type='zoom' />
          <ConnectButton type='msteams' />
          <ConnectButton type='slack' />
        </BodyContainer>
      )
      break
    case 'DialogLoading':
      bodyContent = (
        <BodyContainer>
          <TailSpin
            className='tail-spin'
            style={{marginTop: '16%', width: '70px', height: '70px'}}
          />
          <SubtitleContainer style={{marginBottom: '20px'}}>
            {subtitle}
          </SubtitleContainer>
        </BodyContainer>
      )
      break
    case 'CalendarDialogSuccess':
      bodyContent = (
        <BodyContainer>
          <SubtitleContainer style={{fontSize: '82px', margin: '30px 0 40px'}}>
            {subtitle}
          </SubtitleContainer>
          <SimpleButton title='Import Calendar' color='purple' size='medium' />
          <SimpleButton title='No Thanks' color='black' size='medium' />
        </BodyContainer>
      )
      break
    case 'ContactsDialogSuccess':
      bodyContent = (
        <BodyContainer>
          <SubtitleContainer style={{fontSize: '82px', margin: '30px 0 40px'}}>
            {subtitle}
          </SubtitleContainer>
          <SimpleButton title='Import Contacts' color='purple' size='medium' />
          <SimpleButton title='No Thanks' color='black' size='medium' />
        </BodyContainer>
      )
      break
    case 'DialogLogin':
      bodyContent = (
        <BodyContainer style={{padding: '20px 20px', boxSizing: 'border-box'}}>
          <TextInput title='Email' type='text' />
          <TextInput title='Password' type='password' />
          <SubtitleContainer>{subtitle}</SubtitleContainer>
        </BodyContainer>
      )
      break
    default:
      bodyContent = ''
  }

  let bodyBackgroundColor: string
  let bodyBorder: string

  switch (state) {
    case 'default':
      bodyBackgroundColor = ''
      bodyBorder = BizlyPalette['outline-gray']
      break
    case 'noBorder':
      bodyBackgroundColor = ''
      bodyBorder = 'none'
      break
    case 'success':
      bodyBackgroundColor = BizlyPalette['sucess-green']
      bodyBorder = BizlyPalette['outline-gray']
      break
    default:
      bodyBackgroundColor = ''
      bodyBorder = ''
  }

  return (
    <ActionContainer
      bodyBackgroundColor={bodyBackgroundColor}
      bodyBorder={bodyBorder}>
      <Title className='bsb-action-box-title'>{title}</Title>
      {bodyContent}
    </ActionContainer>
  )
}

export default ActionBox
