/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id:
    'us-west-2:5db48a48-31de-4c49-89b4-863df07e40fd',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_dlpjbnGAd',
  aws_user_pools_web_client_id: '5rc4c6c6voo2tgqn4fo1joh4sh',
  oauth: {
    domain:
      'bizlydashboard6ea28f29-6ea28f29-staging.auth.us-west-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'http://localhost:3000/,https://dashboard.bizly.bros.me/',
    redirectSignOut: 'http://localhost:3000/,https://dashboard.bizly.bros.me/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
}

export default awsmobile
