// tslint:disable: no-implicit-dependencies

// import AppLayout from '@crema/core/AppLayout'
// import AuthRoutes from '@crema/utility/AuthRoutes'
import DateFnsUtils from '@date-io/date-fns'
import CssBaseline from '@material-ui/core/CssBaseline'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {
  lightTheme,
  MeetingProvider,
} from 'amazon-chime-sdk-component-library-react'
import {ConnectedRouter} from 'connected-react-router'
import ContextProvider from 'core/utility/ContextProvider'
import CremaStyleProvider from 'core/utility/CremaStyleProvider'
import CremaThemeProvider from 'core/utility/CremaThemeProvider'
import LocaleProvider from 'core/utility/LocaleProvider'
import React from 'react'
import {Provider} from 'react-redux'
import {Route, Switch, Redirect} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
// import { PersistGate } from 'redux-persist/lib/integration/react'

import SignPages from './components/SignPages/SignPages'
import BizlyMain from './containers/BizlyMain/BizlyMain'
import configureStore, {history, AppState} from './redux/store'
import {RootStateOrAny, useSelector} from 'react-redux'

const store: AppState = configureStore().store
// const persistor = configureStore().persistor

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
interface RouteProps {
  component: any
}

const PrivateRoute = (props: RouteProps) => {
  const {component: Component, ...rest} = props

  const {user} = useSelector((state: RootStateOrAny) => state.cognitoAuth)

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        user ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  )
}

const App = () => (
  <ThemeProvider theme={lightTheme}>
    <MeetingProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ContextProvider>
          <Provider store={store}>
            {/* <PersistGate persistor={persistor} loading={null}> */}
            <CremaThemeProvider>
              <CremaStyleProvider>
                <LocaleProvider>
                  <ConnectedRouter history={history}>
                    {/* <AuthRoutes> */}
                    <CssBaseline />
                    {/* <AppLayout /> */}
                    <Switch>
                      <Route
                        exact
                        path='/'
                        render={(props) =>
                          store.getState().cognitoAuth.user ? (
                            <Redirect
                              to={{
                                pathname: '/home',
                              }}
                            />
                          ) : (
                            <SignPages
                              {...props}
                              buttonText='Create an account?'
                              buttonLink='signup'
                              type='signIn'
                            />
                          )
                        }
                      />
                      <Route
                        exact
                        path='/signup'
                        render={(props) => {
                          if (store.getState().cognitoAuth.user) {
                            return (
                              <Redirect
                                to={{
                                  pathname: '/home',
                                }}
                              />
                            )
                          }

                          if (
                            store.getState().cognitoAuth.waitingVerificationCode
                          ) {
                            return (
                              <Redirect
                                to={{
                                  pathname: '/thanks',
                                }}
                              />
                            )
                          }

                          return (
                            <SignPages
                              {...props}
                              buttonText='Already have an account?'
                              buttonLink=''
                              type='signUp'
                            />
                          )
                        }}
                      />
                      <Route
                        exact
                        path='/recover'
                        render={(props) =>
                          store.getState().cognitoAuth.user ? (
                            <Redirect
                              to={{
                                pathname: '/home',
                              }}
                            />
                          ) : (
                            <SignPages
                              {...props}
                              buttonText='Already have an account?'
                              buttonLink=''
                              type='recover'
                            />
                          )
                        }
                      />
                      <Route
                        exact
                        path='/tell-more'
                        render={(props) => {
                          if (store.getState().cognitoAuth.user) {
                            return (
                              <Redirect
                                to={{
                                  pathname: '/home',
                                }}
                              />
                            )
                          }

                          if (
                            store.getState().cognitoAuth.waitingVerificationCode
                          ) {
                            return (
                              <Redirect
                                to={{
                                  pathname: '/thanks',
                                }}
                              />
                            )
                          }

                          return (
                            <SignPages
                              {...props}
                              buttonText='Already have an account?'
                              buttonLink=''
                              type='tellMore'
                            />
                          )
                        }}
                      />
                      <Route
                        exact
                        path='/thanks'
                        render={(props) => {
                          if (store.getState().cognitoAuth.user) {
                            return (
                              <Redirect
                                to={{
                                  pathname: '/home',
                                }}
                              />
                            )
                          }

                          if (
                            store.getState().cognitoAuth.waitingVerificationCode
                          ) {
                            return (
                              <SignPages
                                {...props}
                                buttonText='Already have an account?'
                                buttonLink=''
                                type='thanks'
                              />
                            )
                          }

                          return (
                            <Redirect
                              to={{
                                pathname: '/',
                              }}
                            />
                          )
                        }}
                      />
                      <PrivateRoute component={BizlyMain} />
                    </Switch>
                    {/* </AuthRoutes> */}
                  </ConnectedRouter>
                </LocaleProvider>
              </CremaStyleProvider>
            </CremaThemeProvider>

            {/* </PersistGate> */}
          </Provider>
        </ContextProvider>
      </MuiPickersUtilsProvider>
    </MeetingProvider>
  </ThemeProvider>
)

export default App
