import {AppActions} from '../../types'
import {
  INIT_DATA_PROVIDER,
  FETCH_DATA_PROVIDER,
} from '../../types/actions/ProviderApi.actions'
import {initMicrosoftGrapth} from '../../core/services/MicrosoftAPI'

const {client} = initMicrosoftGrapth()

const providerData = localStorage.getItem('providerData')

const parseDates = (events: any) =>
  events.map((event: any) => ({
    ...event,
    timeFrom: new Date(event.timeFrom),
    timeTo: new Date(event.timeTo),
  }))

const INIT_STATE: {
  events: Array<any>
  contacts: Array<any>
  status: string
  msClient: any
} = {
  events: providerData ? parseDates(JSON.parse(providerData).events) : [],
  contacts: providerData ? JSON.parse(providerData).contacts : [],
  status: 'init',
  msClient: client,
}

const DataProvider = (state = INIT_STATE, action: AppActions) => {
  switch (action.type) {
    case FETCH_DATA_PROVIDER: {
      return {
        ...state,
        ...action.payload,
        status: 'synced',
      }
    }
    case INIT_DATA_PROVIDER: {
      return {
        ...state,
        status: 'loading',
      }
    }
    default:
      return state
  }
}
export default DataProvider
