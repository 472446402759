import React, {useEffect} from 'react'
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import {FetchPlaybookData} from '../../redux/actions/ApiActions'
import PlaybookNavContainer from '../PlaybookNavContainer/PlaybookNavContainer'
import {PlaybookTagProps} from '../PlaybookTag/PlaybookTag'
import PlaybookTagContainer from '../PlaybookTagContainer/PlaybookTagContainer'
import TopNavBar from '../TopNavBar/TopNavBar'

export interface PlaybookPageProps {
  playbookTags: Array<PlaybookTagProps>
}

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  @media ${BreakpointsMediaQueries.md} {
    justify-content: center;
  }
`

const PlaybookPage = () => {
  // tslint:disable-next-line: typedef
  const Dispatch = useDispatch()

  useEffect(() => {
    Dispatch(FetchPlaybookData())
  }, [])

  const state: any = useSelector((data: RootStateOrAny) => data.playbookData)

  return (
    <ContainerDiv>
      <TopNavBar title='Playbooks' />
      <Content>
        <PlaybookNavContainer playbookTags={state.playbookTags} />
        <PlaybookTagContainer playbookTags={state.playbookTags} />
      </Content>
    </ContainerDiv>
  )
}

export default PlaybookPage
