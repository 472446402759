import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

export interface AgendaDescriptionContainerProps {
  title: string
  description: string
  whyTitle: string
  whyDescription: string
  whenTitle: string
  whenDescription: Array<string>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  color: white;
  max-width: 640px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;

  @media ${BreakpointsMediaQueries.sm} {
    padding: 10px;
  } ;
`

const Title = styled.h1`
  margin: 0 0 30px 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 29px;
`

const Description = styled.p`
  margin: 0 0 30px 0;
  font-size: 15px;
  line-height: 26px;
`

const Subtitle = styled.h2`
  margin: 0 0 30px 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 29px;
  color: ${BizlyPalette['light-yellow']};
`

const Checkbox = styled.input`
  width: 18px;
  height: 18px;
  background-color: ${BizlyPalette['background-gray']};
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid ${BizlyPalette['background-gray']};
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 34px;
`

const WhenItemsContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
`

const WhenItem = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
`

const AgendaItemsContainer: FC<AgendaDescriptionContainerProps> = ({
  title,
  description,
  whyTitle,
  whyDescription,
  whenTitle,
  whenDescription,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Subtitle>{whyTitle}</Subtitle>
      <Description>{whyDescription}</Description>
      <Subtitle>{whenTitle}</Subtitle>
      {whenDescription.map((item) => (
        <WhenItemsContainer>
          <Checkbox type='checkbox' />
          <WhenItem>{item}</WhenItem>
        </WhenItemsContainer>
      ))}
    </Container>
  )
}

export default AgendaItemsContainer
