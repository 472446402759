import TuneIcon from '@material-ui/icons/Tune'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SettingsButtonProps {}

const Button = styled.button`
  display: flex;
  color: ${BizlyPalette['lighter-gray-icon']};
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  outline: none;
  background-color: ${BizlyPalette['base-black']};
  border: none;
  cursor: pointer;

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }

  &:active {
    background-color: ${BizlyPalette['accent-black']};
  }
`

const SettingsButton: FC<SettingsButtonProps> = ({}) => {
  return (
    <Button>
      <TuneIcon style={{fill: '#8e8f93', width: '40px', height: '40px'}} />
    </Button>
  )
}

export default SettingsButton
