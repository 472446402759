import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import GetInitials from '../../assets/utils/GetInitials'

export interface CalendarItemProps {
  startTime: string
  finishTIme: string
  description: string
  location: string
  name: string
}

const OuterContainer = styled.div`
  font-family: 'Lato', sans-serif;
  display: flex;
  justify-content: space-between;
  height: 68px;
  width: 100%;
  border-radius: 12px;
  background-color: ${BizlyPalette['base-gray']};
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 0 12px;

  @media ${BreakpointsMediaQueries.lg} {
    padding: 0 24px;
  } ;
`
const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15%;
  border-radius: 12px 0 0 12px;
`

const ClockContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  min-width: 36px;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  mix-blend-mode: normal;
  border: 1px solid ${BizlyPalette['outline-gray']};
  outline: none;
  background-color: ${BizlyPalette['base-black']};
  cursor: pointer;

  @media ${BreakpointsMediaQueries.xs} {
    display: none;
  }

  @media ${BreakpointsMediaQueries.lg} {
    height: 30px;
    width: 30px;
  }

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }

  &:active {
    background-color: ${BizlyPalette['accent-black']};
  }
`

const NumbersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 12px;
`

const StartTime = styled.div`
  margin: 0;
  color: ${BizlyPalette.white};
  font-size: 14px;
  line-height: 21px;

  @media ${BreakpointsMediaQueries.md} {
    font-size: 12px;
  }
`

const FinishTime = styled.div`
  margin: 0;
  color: ${BizlyPalette['text-gray']};
  font-size: 12px;
  line-height: 18px;

  @media ${BreakpointsMediaQueries.md} {
    font-size: 8px;
  }
`
const DescriptionContainer = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  margin: 0;
  color: ${BizlyPalette['text-gray']};
  font-size: 14px;

  @media ${BreakpointsMediaQueries.sm} {
    width: 60%;
  }
`

const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BizlyPalette['accent-black']};
  width: 35%;
  color: ${BizlyPalette.white};
  font-size: 14px;
  height: 36px;
  border-radius: 8px;
  margin: auto;

  @media ${BreakpointsMediaQueries.sm} {
    display: none;
  } ;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15%;
  border-radius: 0 12px 12px 0;
`

const Button = styled.button`
  display: flex;
  color: ${BizlyPalette['lighter-gray-icon']};
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 10px;
  outline: none;
  background-color: ${BizlyPalette['base-black']};
  border: 1px solid ${BizlyPalette['outline-gray']};
  cursor: pointer;

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }

  &:active {
    background-color: ${BizlyPalette['accent-black']};
  }

  @media ${BreakpointsMediaQueries.sm} {
    margin-left: 0;
    margin-right: 8px;
  } ;
`

const TextSpan = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 6px;
  width: 100%;
`

const CalendarItem: FC<CalendarItemProps> = ({
  startTime,
  finishTIme,
  description,
  location,
  name,
}) => {
  const initials: string = GetInitials(name || '')

  return (
    <OuterContainer>
      <TimeContainer>
        <ClockContainer type='button'>
          <QueryBuilderIcon
            style={{fill: '#8e8f93', height: '18px', width: '18px'}}
          />
        </ClockContainer>
        <NumbersContainer>
          <StartTime>{startTime}</StartTime>
          <FinishTime>{finishTIme}</FinishTime>
        </NumbersContainer>
      </TimeContainer>
      <DescriptionContainer style={{overflow: 'hidden'}}>
        <TextSpan>{description}</TextSpan>{' '}
      </DescriptionContainer>
      <LocationContainer style={{overflow: 'hidden'}}>
        <TextSpan>{location}</TextSpan>
      </LocationContainer>
      <ButtonContainer>
        <Button>{initials}</Button>
        <Button>
          <MoreHorizIcon
            style={{fill: '#8e8f93', height: '18px', width: '18px'}}
          />
        </Button>
      </ButtonContainer>
    </OuterContainer>
  )
}

export default CalendarItem
