import axios from 'axios'

import {
  ADD_EVENT,
  CHANGE_STATE,
  FETCH_CALENDAR_EVENTS,
  FETCH_DAY_EVENTS,
  FETCH_PLAYBOOK_DATA,
  FETCH_SETTINGS_DATA,
  FETCH_USER,
  GET_APP_DATA,
} from '../../types/actions/Api.actions'

const DayPageURL: string =
  'https://run.mocky.io/v3/c91dbcf3-1ff9-4648-894b-09a74ff4edc1'
const UserURL: string =
  'https://run.mocky.io/v3/d425f115-ef62-469d-a4f3-d75885efcaf2'
const AppDataURL: string =
  'https://run.mocky.io/v3/ff5989b5-68fc-445e-827b-4a7398467fce'
const CalendarPageURL: string =
  'https://run.mocky.io/v3/f9150b4f-8548-481d-ad9a-55b75984a2f4'
const SettingsPageURL: string =
  'https://run.mocky.io/v3/ff1c4b38-d04f-4c25-9554-479ca437e0c7'
const PlaybookPageURL: string =
  'https://run.mocky.io/v3/6d4843d6-89e2-4c0f-8f1a-d2d252c7952f'

export const FetchDayEvents = () => (
  dispatch: (arg0: {type: string; payload: any}) => any,
) => {
  axios(DayPageURL).then((dayEvents) => {
    dispatch({
      type: FETCH_DAY_EVENTS,
      payload: dayEvents.data,
    })
  })
}

export const FetchCalendarEvents = () => (
  dispatch: (arg0: {type: string; payload: any}) => any,
) => {
  axios(CalendarPageURL).then((calendarEvents) => {
    dispatch({
      type: FETCH_CALENDAR_EVENTS,
      payload: calendarEvents.data,
    })
  })
}

export const FetchSettingsData = () => (
  dispatch: (arg0: {type: string; payload: any}) => any,
) => {
  axios(SettingsPageURL).then((settingsData) => {
    dispatch({
      type: FETCH_SETTINGS_DATA,
      payload: settingsData.data,
    })
  })
}

export const FetchPlaybookData = () => (
  dispatch: (arg0: {type: string; payload: any}) => any,
) => {
  axios(PlaybookPageURL).then((playbookData) => {
    dispatch({
      type: FETCH_PLAYBOOK_DATA,
      payload: playbookData.data,
    })
  })
}

export const FetchUser = () => (
  dispatch: (arg0: {type: string; payload: any}) => any,
) => {
  axios(UserURL).then((userData) => {
    dispatch({
      type: FETCH_USER,
      payload: userData.data,
    })
  })
}

export const GetAppData = () => (
  dispatch: (arg0: {type: string; payload: any}) => any,
) => {
  axios(AppDataURL).then((appData) => {
    dispatch({
      type: GET_APP_DATA,
      payload: appData.data,
    })
  })
}

export const AddEvents = (payload: any) => {
  return {
    type: ADD_EVENT,
    payload,
  }
}

export const ChangeState = (payload: any) => {
  return {
    type: CHANGE_STATE,
    payload,
  }
}
