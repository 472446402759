import Grid from '@material-ui/core/Grid'
import React, {FC, useEffect, useState} from 'react'
// import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import TextInput from '../TextInput/TextInput'
import {TailSpin} from '../../assets/icons/tail-spin'
import {RootStateOrAny, useSelector, useDispatch} from 'react-redux'
import {validateForm, FormAttr} from '../../core/utility/Utils'

import {onCognitoAuthSignUp} from '../../redux/actions/CognitoAuth'
import {useHistory} from 'react-router'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TellMoreFormProps {}

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  font-size: 32px;
  line-height: 42px;
  font-style: normal;
  font-weight: bold;
  margin: 40px 0 80px;

  @media ${BreakpointsMediaQueries.sm} {
    margin: 20px 0 40px;
  } ;
`

const Title = styled.div`
  font-family: 'Lato', sans-serif;
  text-align: center;
  margin-bottom: 30px;
`

const Subtitle = styled.div`
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: white;

  @media ${BreakpointsMediaQueries.sm} {
    color: ${BizlyPalette['text-gray']};
  } ;
`

const ContinueButton = styled.button`
  width: 100%;
  outline: none;
  border: none;
  font-family: 'Lato', sans-serif;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BizlyPalette['base-purple']};
  height: 50px;
  font-size: 16px;
  margin: 40px 0;
  border-radius: 10px;
  cursor: pointer;
`

const TellMoreForm: FC<TellMoreFormProps> = () => {
  const {waitingVerificationCode, emailForm} = useSelector(
    (state: RootStateOrAny) => state.cognitoAuth,
  )
  const [email, setEmail] = useState(emailForm)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')

  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const history = useHistory()

  const dispatch = useDispatch()
  const {loading, error} = useSelector((state: RootStateOrAny) => state.common)

  const handleSubmit = async () => {
    const formToValidate: Array<FormAttr> = [
      {
        name: 'Email',
        value: email,
        type: 'email',
        required: true,
        onError: setEmailError,
      },
      {
        name: 'Password',
        value: password,
        required: true,
        onError: setPasswordError,
      },
    ]

    const isValid = validateForm(formToValidate)

    if (!isValid) {
      return
    }

    dispatch(
      onCognitoAuthSignUp({
        email,
        firstName,
        lastName,
        password,
      }),
    )
  }

  useEffect(() => {
    if (waitingVerificationCode) {
      history.push('/thanks')
    }
  })

  useEffect(() => {
    setEmailError('')
  }, [email])

  useEffect(() => {
    setPasswordError('')
  }, [password])

  return (
    <Grid
      container
      style={{display: 'flex', justifyContent: 'center', maxWidth: '450px'}}>
      <Grid item xs={12}>
        <HeaderDiv>
          <Title>Tell us more about yourself</Title>
          <Subtitle>Enter your details to kick things off.</Subtitle>
        </HeaderDiv>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <TextInput
            required
            error={!!emailError}
            errorMessage={emailError}
            onChange={setEmail}
            value={email}
            title='Email'
            adornment='mail'
            type='text'
          />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={6}>
            <TextInput
              onChange={setFirstName}
              title='First Name'
              adornment='person'
              type='text'
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              onChange={setLastName}
              title='Last Name'
              adornment='person'
              type='text'
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            required
            error={!!passwordError || !!error}
            errorMessage={passwordError || error.message}
            onChange={setPassword}
            title='Password'
            adornment='password'
            type='password'
          />
        </Grid>
        <Grid item container justify='center' xs={12}>
          {loading ? (
            <TailSpin className='tail-spin' style={{margin: '40px 0'}} />
          ) : (
            <ContinueButton type='submit' onClick={handleSubmit}>
              Sign In
            </ContinueButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TellMoreForm
