import React, {FC} from 'react'
import styled from 'styled-components'

import SvgCircle from '../../assets/icons/SvgCircle'
import SvgGdocs from '../../assets/icons/SvgGdocs'
import SvgMsteams from '../../assets/icons/SvgMsteams'
import SvgSlack from '../../assets/icons/SvgSlack'
import SvgZoom from '../../assets/icons/SvgZoom'
import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
export interface ConnectButtonProps {
  type: string
  state?: string
  emoji?: string
  onClick?: () => void
}

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  width: 100%;
  font-family: 'Lato', sans-serif;
  height: 50px;
  outline: none;
  border-radius: 10px;
  background-color: ${BizlyPalette['base-black']};
  border: 1px solid ${BizlyPalette['outline-gray']};
  font-weight: bold;
  color: white;
  box-sizing: border-box;
  margin-bottom: 18px;

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }

  &:active {
    background-color: ${BizlyPalette['accent-black']};
  }
`

const ButtonLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-width: 50px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  flex: 1;
  border-radius: 10px 0 0 10px;
`

const ButtonRight = styled.div`
  outline: none;
  display: flex;
  height: 50px;
  flex: 10;
  align-items: center;
  justify-content: center;
  border: none;
  border-left: 1px solid ${BizlyPalette['outline-gray']};
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  border-radius: 0 10px 10px 0;

  @media ${BreakpointsMediaQueries.sm} {
    font-size: 12px;
  }
`

const StyledSpan = styled.span`
  margin-left: 8px;
`

const ConnectButton: FC<ConnectButtonProps> = ({
  type,
  state,
  emoji,
  onClick,
}) => {
  let icon
  let text

  switch (type) {
    case 'gdocs':
      icon = (
        <SvgGdocs height={20} width={20} fill={BizlyPalette['service-gray']} />
      )
      text = 'Connect to Google Docs'
      break
    case 'msteams':
      icon = (
        <SvgMsteams
          height={20}
          width={20}
          fill={BizlyPalette['service-gray']}
        />
      )
      text = 'Connect to Microsoft Teams'
      break
    case 'zoom':
      icon = (
        <SvgZoom height={20} width={20} fill={BizlyPalette['service-gray']} />
      )
      text = 'Connect Zoom Account'
      break
    case 'slack':
      icon = (
        <SvgSlack height={20} width={20} fill={BizlyPalette['service-gray']} />
      )
      text = 'Connect to Slack'
      break
    case 'signGoogle':
      icon = (
        <SvgGdocs height={20} width={20} fill={BizlyPalette['service-gray']} />
      )
      text = 'Sign In with Google'
      break
    case 'signMS':
      icon = (
        <SvgMsteams
          height={20}
          width={20}
          fill={BizlyPalette['service-gray']}
        />
      )
      text = 'Sign In with Microsoft'
      break
    case 'signOkta':
      icon = (
        <SvgCircle height={20} width={20} fill={BizlyPalette['service-gray']} />
      )
      text = 'Sign In with Okta'
      break
    default:
      icon = ''
      break
  }

  return (
    <Container onClick={onClick} className={`bsb-connectContainer ${state}`}>
      <ButtonLeft>{icon}</ButtonLeft>
      <ButtonRight>
        <span>
          {text}
          <StyledSpan>{emoji}</StyledSpan>
        </span>
      </ButtonRight>
    </Container>
  )
}

export default ConnectButton
