// tslint:disable: typedef

const Integrations = [
  {
    integrationLogo: 'https://logo.clearbit.com/dropbox.com',
    integrationName: 'Dropbox',
    integrationDescription:
      'Bring a faster teamwork and faster file synchronization',
    borderBottom: true,
  },
  {
    integrationLogo: 'https://logo.clearbit.com/invisionapp.com',
    integrationName: 'Invision',
    integrationDescription: 'Design better and faster',
    borderBottom: true,
  },
  {
    integrationLogo: 'https://logo.clearbit.com/atlassian.com',
    integrationName: 'Jira',
    integrationDescription: 'Stay agile and increase productivity',
    borderBottom: true,
  },
  {
    integrationLogo: 'https://logo.clearbit.com/intercom.com',
    integrationName: 'Intercom',
    integrationDescription: 'Communicate faster with clients',
    borderBottom: true,
  },
  {
    integrationLogo: 'https://logo.clearbit.com/trello.com',
    integrationName: 'Trello',
    integrationDescription: 'Collaborate with your teammates faster',
    borderBottom: true,
  },
  {
    integrationLogo: 'https://logo.clearbit.com/419v.com',
    integrationName: 'Product Hunt',
    integrationDescription:
      'Get new products notifications right on your dashboard',
    borderBottom: true,
  },
  {
    integrationLogo: 'https://logo.clearbit.com/google.com',
    integrationName: 'Google Drive',
    integrationDescription:
      'Connect all your documents in one placeConnect all your documents in one placeConnect all your documents in one place',
  },
]

export default Integrations
