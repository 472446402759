const API_KEY = 'AIzaSyBkMGSffZP428ue8-7CGbBtSEYhJKA-oYY'
const GOOGLE_CLIENT_ID =
  '165859089323-kigkftl3pifsfdhjcslblr1s7u90bvvv.apps.googleusercontent.com'
const GOOGLE_AUTH_SCOPE =
  'openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/directory.readonly'
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  'https://www.googleapis.com/discovery/v1/apis/people/v1/rest',
]

const mapEvents = (events: any) =>
  events.map((event: any) => ({
    eventScore: 1,
    type: {
      personal: true,
    },
    recurrence: event.recurrence || null,
    locationType: 'Video',
    timeFrom: new Date(event.start.dateTime),
    timeTo: new Date(event.end.dateTime),
    title: event.summary,
    organizer:
      event.organizer.displayName || event.organizer.email || event.organizer,
    members: event.attendees.map((attendee: any) => ({
      name: attendee.name,
      email: attendee.email,
    })),
    tags: [{title: 'Business'}, {title: 'Casual'}, {title: 'Smart'}],
  }))

const mapContacts = (contacts: any) =>
  contacts.map((contact: any) => contact.emailAddresses[0].value)

export const initGapiV2 = async () => {
  const g = window.gapi
  await new Promise((resolve) => {
    g.load('client:auth2', resolve)
  })

  await g.client.init({
    apiKey: API_KEY,
    clientId: GOOGLE_CLIENT_ID,
    discoveryDocs: DISCOVERY_DOCS,
    scope: GOOGLE_AUTH_SCOPE,
  })
}

export const grantGoogleConsent = async () => {
  const g = window.gapi
  const googleUser = g.auth2.getAuthInstance().currentUser.get()

  await googleUser.grant({prompt: 'consent', scope: GOOGLE_AUTH_SCOPE})
}

export const getEvents = async () => {
  const allEvents = await gapi.client.calendar.events.list({
    calendarId: 'primary',
    timeMin: new Date().toISOString(),
    showDeleted: false,
    singleEvents: true,
    orderBy: 'startTime',
  })

  return mapEvents(allEvents.result.items)
}

export const getContacts = async () => {
  const connections = await gapi.client.people.people.connections.list({
    resourceName: 'people/me',
    personFields: 'names,emailAddresses',
  })

  const directoryConnections = await gapi.client.people.people.listDirectoryPeople(
    {
      readMask: 'names,emailAddresses',
      sources: 'DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE',
      mergeSources: 'DIRECTORY_MERGE_SOURCE_TYPE_CONTACT',
    },
  )

  const connectionArray: any[] = mapContacts(
    connections.result.connections || [],
  )
  const directoryConnectionsArray: any[] = mapContacts(
    directoryConnections.result.people || [],
  )

  //@ts-ignore
  return [...new Set([...connectionArray, ...directoryConnectionsArray])]
}
