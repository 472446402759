import DateRangeIcon from '@material-ui/icons/DateRange'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import DayEventItem, {DayEventItemProps} from '../DayEventItem/DayEventItem'

export interface DayEventItemContainerProps {
  dayEvents: Array<DayEventItemProps>
}

const Container = styled.div`
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 515px;
  border-radius: 8px;
  border: 1px solid ${BizlyPalette['outline-gray']};
  overflow: scroll;
  box-sizing: border-box;
  padding: 0 40px;
  background-color: ${BizlyPalette['base-black']};

  @media ${BreakpointsMediaQueries.md} {
    padding: 0 20px;
    margin-bottom: 30px;
  }

  @media ${BreakpointsMediaQueries.sm} {
    padding: 0;
    border: none;
  } ;
`

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 26px 0 30px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  color: white;
  z-index: 1;
  background-color: ${BizlyPalette['base-black']};

  @media ${BreakpointsMediaQueries.sm} {
    padding: 26px 12px 24px;
  } ;
`

const Title = styled.h1`
  margin: 0;
  margin-bottom: 8px;
  justify-content: space-around;
  font-weight: bold;
  font-size: 16px;
`

const Description = styled.p`
  margin: 0;
  justify-content: space-around;
  font-size: 14px;
  color: ${BizlyPalette['subtitle-gray']};
`

const Adornments = styled.div`
  display: flex;
  align-items: center;
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ViewButton = styled.button`
  background-color: ${BizlyPalette['base-black']};
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: ${BizlyPalette['subtitle-gray']};
  outline: none;
  border: none;

  &: hover {
    color: ${BizlyPalette['info-yellow']};
  }
`

const DayEventItemContainer: FC<DayEventItemContainerProps> = ({dayEvents}) => {
  const ContainerBody = !!dayEvents
    ? dayEvents.map((item, i) => (
        <DayEventItem
          locationType={item.locationType}
          timeFrom={item.timeFrom}
          timeTo={item.timeTo}
          title={item.title}
          organizer={item.organizer}
          members={item.members}
          eventScore={item.eventScore}
          key={i}
          tags={item.tags}
        />
      ))
    : ''

  return (
    <Container>
      <Header>
        <TextBox>
          <Title>Today's Events</Title>
          <Description>Scheduled meetings and calls</Description>
        </TextBox>
        <Adornments>
          <DateRangeIcon
            style={{width: '18px', height: '18px', fill: '#8b8b8d'}}
          />
          <ViewButton>View schedule</ViewButton>
        </Adornments>
      </Header>
      {ContainerBody}
    </Container>
  )
}

export default DayEventItemContainer
