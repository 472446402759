import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import IntegrationItem, {
  IntegrationItemProps,
} from '../IntegrationItem/IntegrationItem'

export interface IntegrationContainerProps {
  integrationList: Array<IntegrationItemProps>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 8;
  background-color: ${BizlyPalette['base-black']};
  padding-top: 40px;

  @media ${BreakpointsMediaQueries.md} {
    flex: 4;
  }

  @media ${BreakpointsMediaQueries.sm} {
    padding-top: 0;
    width: 80%;
  }
`

const IntegrationContainer: FC<IntegrationContainerProps> = ({
  integrationList,
}) => {
  return (
    <Container>
      {integrationList.map((item, i) => (
        <IntegrationItem
          integrationLogo={item.integrationLogo}
          integrationName={item.integrationName}
          integrationDescription={item.integrationDescription}
          borderBottom={item.borderBottom}
          key={i}
        />
      ))}
    </Container>
  )
}

export default IntegrationContainer
