import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import logo from '../../assets/images/logo.png'
import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

export interface SignTopNavBarProps {
  buttonText: string
  buttonLink: string
}

const OuterContainer = styled.div`
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: ${BizlyPalette['base-black']};
  margin-bottom: 16px;
  border-bottom: 1px solid ${BizlyPalette['outline-gray']};

  @media ${BreakpointsMediaQueries.sm} {
    display: none;
  } ;
`

const Logo = styled.div`
  background-image: url(${logo});
  width: 150px;
  height: 45px;
  background-repeat: no-repeat;
  background-size: contain;
`

const AccountButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid ${BizlyPalette['outline-gray']};
  font-size: 16px;
  line-height: 19px;
  background-color: ${BizlyPalette['base-black']};
  color: white;
  padding: 6px 12px;
  border-radius: 10px;
`

const SignTopNavBar: FC<SignTopNavBarProps> = ({buttonText, buttonLink}) => {
  return (
    <OuterContainer>
      <Link to='/'>
        <Logo />
      </Link>
      <Link to={`/${buttonLink}`}>
        <AccountButton>{buttonText}</AccountButton>
      </Link>
    </OuterContainer>
  )
}

export default SignTopNavBar
