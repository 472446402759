import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import MenuItem, {MenuItemProps} from '../MenuItem/MenuItem'

export interface CalendarNavProps {
  settingsList: Array<MenuItemProps>
}

const Container = styled.div`
  font-family: 'Lato', sans-serif;
  color: white;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${BizlyPalette['base-black']};
  width: 30%;

  @media ${BreakpointsMediaQueries.md} {
    padding: 10px 0 0 20px;
  }

  @media ${BreakpointsMediaQueries.md} {
    width: 100%;
    padding: 0;
  }
`

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${BreakpointsMediaQueries.md} {
    flex-direction: row;
    width: 100%;
  } ;
`

const CalendarNav: FC<CalendarNavProps> = ({settingsList}) => {
  const settingsContent: JSX.Element[] | '' = !!settingsList
    ? settingsList.map((item) => (
        <MenuItem
          title={item.title}
          description={item.description}
          type={item.type}
          borderBottom={item.borderBottom}
          section={item.section}
          eventNum={item.eventNum}
          key={item.id}
        />
      ))
    : ''

  return (
    <Container>
      <SettingsContainer>{settingsContent}</SettingsContainer>
    </Container>
  )
}

export default CalendarNav
