import {AppActions} from '../../types'
import {
  SET_EMAIL_FORM,
  COGNITO_SIGN_UP,
  COGNITO_SIGN_IN,
  COGNITO_SIGN_OUT,
  COGNITO_CONFIRM_NEW_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUBMIT,
  UPDATE_USER,
} from '../../types/actions/CognitoAuth.actions'
import {CognitoUserExt} from '../../types/models/CognitoUser'

const emailForm = localStorage.getItem('emailForm')
const user = localStorage.getItem('user')
const waitingVerificationCode = localStorage.getItem('waitingVerificationCode')
const forgotPassword = localStorage.getItem('forgotPassword')

const INIT_STATE: {
  emailForm: string
  user: CognitoUserExt | null
  forgotPassword: boolean
  forgotPasswordSubmit: boolean
  waitingVerificationCode: boolean
} = {
  emailForm: emailForm ? emailForm : '',
  user: user ? JSON.parse(user) : null,
  waitingVerificationCode: waitingVerificationCode
    ? JSON.parse(waitingVerificationCode)
    : false,
  forgotPassword: forgotPassword ? JSON.parse(forgotPassword) : false,
  forgotPasswordSubmit: false,
}

const CognitoAuth = (state = INIT_STATE, action: AppActions) => {
  switch (action.type) {
    case SET_EMAIL_FORM: {
      return {
        ...state,
        emailForm: action.payload,
      }
    }
    case COGNITO_SIGN_IN: {
      return {
        ...state,
        user: action.payload,
      }
    }
    case COGNITO_SIGN_UP: {
      return {
        ...state,
        waitingVerificationCode: true,
      }
    }
    case COGNITO_SIGN_OUT: {
      return {
        ...state,
        emailForm: '',
        user: null,
        forgotPassword: false,
        forgotPasswordSubmit: false,
        waitingVerificationCode: false,
      }
    }
    case COGNITO_CONFIRM_NEW_USER: {
      return {
        ...state,
        waitingVerificationCode: false,
      }
    }
    case FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPassword: true,
        forgotPasswordSubmit: false,
        emailForm: action.payload,
      }
    }
    case FORGOT_PASSWORD_SUBMIT: {
      return {
        ...state,
        forgotPassword: false,
        forgotPasswordSubmit: true,
      }
    }
    case UPDATE_USER: {
      return {
        ...state,
        user: action.payload,
      }
    }
    default:
      return state
  }
}
export default CognitoAuth
