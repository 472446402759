import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SimpleButtonProps {
  title?: string
  color?: string
  size?: string
}

const SimpleButtons = styled('button')<{
  buttonWidth: string
  buttonHeight: string
  buttonFontSize: string
  buttonBackgroundColor: string
}>`
  display: flex;
  color: ${BizlyPalette.white};
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  height: ${(props) => props.buttonHeight};
  width: ${(props) => props.buttonWidth};
  border-radius: 10px;
  outline: none;
  background-color: ${(props) => props.buttonBackgroundColor};
  border: none;
  cursor: pointer;
  ffont-family: 'Lato', sans-serif;
  font-size: ${(props) => props.buttonFontSize};
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: center;
  padding: 0 8px;
  border: 1px solid ${BizlyPalette['outline-gray']};

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }

  &:active {
    background-color: ${BizlyPalette['accent-black']};
  }
`

const SimpleButton: FC<SimpleButtonProps> = ({title, color, size}) => {
  let buttonBackgroundColor: string

  switch (color) {
    case 'black':
      buttonBackgroundColor = BizlyPalette['base-black']
      break
    case 'purple':
      buttonBackgroundColor = BizlyPalette['base-purple']
      break
    default:
      buttonBackgroundColor = 'white'
  }

  let buttonWidth: string
  let buttonHeight: string
  let buttonFontSize: string

  switch (size) {
    case 'mini':
      buttonWidth = '45px'
      buttonHeight = '18px'
      buttonFontSize = '6px'
      break
    case 'small':
      buttonWidth = '128px'
      buttonHeight = '50px'
      buttonFontSize = '16px'
      break
    case 'medium':
      buttonWidth = '236px'
      buttonHeight = '50px'
      buttonFontSize = '16px'
      break
    default:
      buttonWidth = '100%'
      buttonHeight = '50px'
      buttonFontSize = '16px'
  }

  return (
    <SimpleButtons
      buttonBackgroundColor={buttonBackgroundColor}
      buttonWidth={buttonWidth}
      buttonHeight={buttonHeight}
      buttonFontSize={buttonFontSize}>
      {title}
    </SimpleButtons>
  )
}

export default SimpleButton
