import React, {VFC} from 'react'

interface SvgPlusProps {
  height: number
  width: number
  fill: string
}

const SvgPlus: VFC<SvgPlusProps> = ({height, width, fill}) => {
  return (
    <svg
      height={height}
      width={width}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 26 26'>
      <path d='M13.5 3.188C7.805 3.188 3.188 7.805 3.188 13.5S7.805 23.813 13.5 23.813 23.813 19.195 23.813 13.5 19.195 3.188 13.5 3.188zM19 15h-4v4h-3v-4H8v-3h4V8h3v4h4v3z' />
    </svg>
  )
}

export default SvgPlus
