import React from 'react'
import {Redirect} from 'react-router-dom'

import {createRoutes} from '../core/utility/Utils'
import ImportPage from '../components/ImportPage/ImportPage'
import {initialUrl} from '../shared/constants/AppConst'

import {authRouteConfig} from './auth'
import {dashboardPagesConfig} from './dashboard'
import {errorPagesConfigs} from './errorPages'

const routeConfigs = [
  ...dashboardPagesConfig,
  ...errorPagesConfigs,
  ...authRouteConfig,
]

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  // {
  //   component: () => <Redirect to='/error-pages/error-404' />,
  // },
  {
    path: '/import-page',
    exact: true,
    component: ImportPage,
  },
]

export default routes
