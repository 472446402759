import React, {VFC} from 'react'

interface SvgCircleProps {
  height: number
  width: number
  fill: string
}

const SvgCircle: VFC<SvgCircleProps> = ({height, width, fill}) => {
  return (
    <svg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill={fill}>
      <path d='M20.788 3.832c-.101-.105-.197-.213-.301-.317-.103-.103-.211-.202-.32-.302A11.903 11.903 0 0012 0a11.926 11.926 0 00-8.486 3.514C-1.062 8.09-1.16 15.47 3.213 20.168c.099.108.197.214.3.32.104.103.21.2.317.3A11.92 11.92 0 0012 24c3.206 0 6.22-1.247 8.487-3.512 4.576-4.576 4.673-11.956.301-16.656zm-16.655.301A11.057 11.057 0 0112 .874c2.825 0 5.49 1.048 7.55 2.958l-1.001 1.002A9.646 9.646 0 0012 2.292a9.644 9.644 0 00-6.865 2.844A9.644 9.644 0 002.292 12c0 2.448.9 4.753 2.542 6.549L3.831 19.55C-.201 15.191-.101 8.367 4.133 4.133zm13.798 1.318v.002l-1.015 1.014A7.346 7.346 0 0012 4.589 7.357 7.357 0 006.761 6.76 7.362 7.362 0 004.589 12a7.34 7.34 0 001.877 4.913l-1.014 1.016A8.77 8.77 0 013.167 12a8.77 8.77 0 012.588-6.245A8.771 8.771 0 0112 3.167c2.213 0 4.301.809 5.931 2.284zM18.537 12c0 1.745-.681 3.387-1.916 4.622S13.746 18.538 12 18.538a6.491 6.491 0 01-4.296-1.621l-.001-.004c-.11-.094-.22-.188-.324-.291a6.027 6.027 0 01-.293-.326A6.47 6.47 0 015.466 12c0-1.746.679-3.387 1.914-4.621A6.488 6.488 0 0112 5.465c1.599 0 3.105.576 4.295 1.62.111.096.224.19.326.295.104.104.2.214.295.324A6.482 6.482 0 0118.537 12zM7.084 17.534h.001A7.349 7.349 0 0012 19.413a7.35 7.35 0 005.239-2.174A7.354 7.354 0 0019.412 12a7.364 7.364 0 00-1.876-4.916l1.013-1.012A8.777 8.777 0 0120.834 12a8.765 8.765 0 01-2.589 6.246A8.764 8.764 0 0112 20.834a8.782 8.782 0 01-5.93-2.285l1.014-1.015zm12.783 2.333A11.046 11.046 0 0112 23.125a11.042 11.042 0 01-7.551-2.957l1.004-1.001a9.64 9.64 0 006.549 2.542 9.639 9.639 0 006.865-2.846A9.642 9.642 0 0021.71 12a9.64 9.64 0 00-2.543-6.548l1.001-1.002c4.031 4.359 3.935 11.182-.301 15.417z' />
    </svg>
  )
}

export default SvgCircle
