import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import StoreIcon from '@material-ui/icons/Store'
import VideocamIcon from '@material-ui/icons/Videocam'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import {AvatarChipProps} from '../AvatarChip/AvatarChip'
import AvatarChipContainer from '../AvatarChipContainer/AvatarChipContainer'
import SimpleButton, {SimpleButtonProps} from '../SimpleButton/SimpleButton'

interface EventType {
  personal?: boolean
  company?: boolean
}
export interface DayEventItemProps {
  locationType: string
  timeFrom: Date
  timeTo: Date
  title: string
  organizer: string
  eventScore: number
  type?: EventType
  members: Array<AvatarChipProps>
  tags: Array<SimpleButtonProps>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Lato', sans-serif;
  margin-bottom: 20px;
  max-width: 600px;

  @media ${BreakpointsMediaQueries.sm} {
    padding: 0;
  } ;
`

const InfoContainer = styled.div`
  display: flex;
  margin-bottom: 6px;
  color: white;
`

const CheckBox = styled.input`
  width: 15px;
  height: 15px;
  border: 1px solid ${BizlyPalette['outline-gray']};
  margin: 0 0 12px;
  background-color: ${BizlyPalette['base-black']};
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid ${BizlyPalette['background-gray']};
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  &.false {
    background-color: ${BizlyPalette['outline-black']};
  }

  &:hover {
    border: 1px solid $base-purple;
  }
`

const MessagesBox = styled.div`
  display: flex;
`

const TimeContainer = styled.div`
  margin-right: 12px;
`

const NameContainer = styled.div`
  overflow: hidden;
  max-width: 240px;
`

const StartTime = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const FinishTime = styled.div`
  font-size: 12px;
  color: ${BizlyPalette['service-gray']};
`

const EventName = styled.div`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Organizer = styled.div`
  font-size: 12px;
  color: ${BizlyPalette['service-gray']};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ActionBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-right: 12px;
`

const DescriptionBox = styled.div`
  margin-right: 12px;
  max-width: 100%;
`

const MemberBox = styled.div`
  margin-right: 12px;

  @media ${BreakpointsMediaQueries.sm} {
    display: none;
  } ;
`

const MemberNumber = styled.div`
  display: none;
  color: white;
  font-size: 12px;

  @media ${BreakpointsMediaQueries.sm} {
    display: block;
    margin: 30px 12px 30px 0;
  } ;
`

const TypeBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  align-items: flex-end;
`

const TagContainer = styled.div`
  margin-left: -6px;
  display: flex;
  flex-wrap: wrap;
`

const ScoreBarContainer = styled.div`
  display: flex;
  flexdirection: column;
  width: 70%;
  position: relative;
`

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 90px;
  justify-content: space-between;

  @media ${BreakpointsMediaQueries.sm} {
    flex-direction: column;
  } ;
`

const LeftBox = styled.div`
  display: flex;

  @media ${BreakpointsMediaQueries.sm} {
    width: 90%;
    display: flex;
    justify-content: center;
  }

  @media ${BreakpointsMediaQueries.sm} {
    width: 70%;
  } ;
`

const RightBox = styled.div`
  display: flex;
`

const ScoreBar = styled('hr')<{eventScore: number}>`
  border: 1px solid #5e1a6f;
  width: ${(props) => props.eventScore * 10}%;
  boxsizing: border-box;
  margin: -6px;
  position: absolute;
`

const DayEventItem: FC<DayEventItemProps> = ({
  timeFrom,
  timeTo,
  title,
  organizer,
  members,
  locationType,
  eventScore,
  tags,
}) => {
  const [state, setState] = React.useState(true)

  const handleChange = () => {
    setState(!state)
  }

  let locationIcon

  switch (locationType) {
    case 'In person':
      locationIcon = (
        <StoreIcon
          style={{
            width: '14px',
            height: '14px',
            fill: BizlyPalette['icon-gray'],
          }}
        />
      )
      break
    case 'Video':
      locationIcon = (
        <VideocamIcon
          style={{
            width: '14px',
            height: '14px',
            fill: BizlyPalette['icon-gray'],
          }}
        />
      )
      break
    case 'Chat':
      locationIcon = (
        <KeyboardIcon
          style={{
            width: '14px',
            height: '14px',
            fill: BizlyPalette['icon-gray'],
          }}
        />
      )
      break
    default:
      locationIcon = ''
      break
  }

  const tagList = !!tags
    ? tags
        .map((item, i) => (
          <SimpleButton title={item.title} size='mini' color='black' key={i} />
        ))
        .slice(0, 3)
    : ''

  return (
    <Container>
      <InnerContainer>
        <LeftBox>
          <ActionBox>
            <CheckBox type='checkbox' checked={state} onChange={handleChange} />
            <MessagesBox>
              <QuestionAnswerIcon
                style={{
                  width: '12px',
                  height: '12px',
                  fill: BizlyPalette['icon-gray'],
                }}
              />
              <FiberManualRecordIcon
                style={{
                  width: '5px',
                  height: '5px',
                  fill: BizlyPalette['light-green'],
                }}
              />
            </MessagesBox>
          </ActionBox>
          <DescriptionBox>
            <InfoContainer>
              <TimeContainer>
                <StartTime>{`${timeFrom.getHours()}:${
                  timeFrom.getMinutes() < 10 ? '0' : ''
                }${timeFrom.getMinutes()}`}</StartTime>
                <FinishTime>{`${timeTo.getHours()}:${
                  timeTo.getMinutes() < 10 ? '0' : ''
                }${timeTo.getMinutes()}`}</FinishTime>
              </TimeContainer>
              <NameContainer>
                <EventName>{title}</EventName>
                <Organizer>{organizer}</Organizer>
              </NameContainer>
            </InfoContainer>
            <TagContainer>{tagList}</TagContainer>
          </DescriptionBox>
        </LeftBox>
        <RightBox>
          <MemberBox>
            <AvatarChipContainer members={members} />
          </MemberBox>
          <MemberNumber>{`${members.length} Members`}</MemberNumber>
          <TypeBox>{locationIcon}</TypeBox>
        </RightBox>
      </InnerContainer>
      <ScoreBarContainer>
        <ScoreBar eventScore={eventScore} />
      </ScoreBarContainer>
    </Container>
  )
}

export default DayEventItem
