import Grid from '@material-ui/core/Grid'
import React, {FC, useState, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {RootStateOrAny, useSelector, useDispatch} from 'react-redux'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import ConnectButton from '../ConnectButton/ConnectButton'
import TextInput from '../TextInput/TextInput'
import {TailSpin} from '../../assets/icons/tail-spin'
import {validateForm, FormAttr} from '../../core/utility/Utils'

import {
  onSetEmailForm,
  onCognitoAuthSignIn,
  onGoogleSignIn,
  getCurrentUser,
  onOktaSignIn,
  onMicrosoftSignIn,
} from '../../redux/actions/CognitoAuth'

export interface SignFormProps {
  type?: 'signIn' | 'signUp'
  title?: string
}

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  font-size: 32px;
  line-height: 42px;
  font-style: normal;
  font-weight: bold;
  margin: 40px 0 80px;

  @media ${BreakpointsMediaQueries.sm} {
    margin: 20px 0 40px;
  } ;
`

const Title = styled.div`
  font-family: 'Lato', sans-serif;
  text-align: center;
`

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  background-color: ${(props) =>
    props.checked ? BizlyPalette['base-purple'] : 'none'};
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid ${BizlyPalette['base-purple']};
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 34px;

  &:hover {
    border: 1px solid ${BizlyPalette['light-yellow']};
  }
`

const ActionsCheck = styled.div`
  display: flex;
  align-items: center;
`

const ActionsBox = styled.div`
  font-family: 'Lato', sans-serif;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SignButton = styled.button`
  width: 100%;
  outline: none;
  border: none;
  font-family: 'Lato', sans-serif;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BizlyPalette['base-purple']};
  height: 50px;
  font-size: 16px;
  margin: 40px 0;
  border-radius: 10px;
  cursor: pointer;
`

const Divider = styled.div`
  font-family: 'Lato', sans-serif;
  color: white;
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
`

const DividerLine = styled.hr`
  width: 25%;
  height: 0;
`

const DividerText = styled.span`
  margin: 0 12px;
`

const SignForm: FC<SignFormProps> = ({type, title}) => {
  const [oktaProvider, setOktaProvider] = useState('')
  const [oktaProviderError, setOktaProviderError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [termsError, setTermsError] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [terms, setTerms] = useState(false)
  const [remember, setRemember] = useState(false)

  const history = useHistory()

  const dispatch = useDispatch()
  const {emailForm, user} = useSelector(
    (state: RootStateOrAny) => state.cognitoAuth,
  )
  const {msClient} = useSelector((state: RootStateOrAny) => state.providerData)

  const {loading, error} = useSelector((state: RootStateOrAny) => state.common)

  const toogleTerms = () => setTerms(!terms)

  const toggleRemember = () => setRemember(!remember)

  useEffect(() => {
    dispatch(getCurrentUser())
  }, [dispatch])

  useEffect(() => {
    if (user && type === 'signIn') {
      history.push('/home')
    }

    if (emailForm && type === 'signUp') {
      history.push('/tell-more')
    }
  })

  useEffect(() => {
    setEmailError('')
  }, [email, type])

  useEffect(() => {
    setPasswordError('')
  }, [password, type])

  useEffect(() => {
    setTermsError('')
  }, [terms, type])

  useEffect(() => {
    setOktaProviderError('')
  }, [oktaProvider, type])

  useEffect(() => {
    if (!user && msClient.config.authProvider.msalApplication.getAccount()) {
      //user logged out of bizly
      //needs to logged out from microsoft
      msClient.config.authProvider.msalApplication.logout()
    }
  }, [user])

  const setEmailForm = () => {
    const formToValidate: Array<FormAttr> = [
      {
        name: 'Email',
        value: email,
        type: 'email',
        required: true,
        onError: setEmailError,
      },
      {
        name: 'Terms',
        value: terms,
        type: 'terms',
        required: true,
        onError: setTermsError,
      },
    ]

    const isValid = validateForm(formToValidate)

    if (!isValid) {
      return
    }
    dispatch(onSetEmailForm(email))
  }

  const handleSubmit = async () => {
    const formToValidate: Array<FormAttr> = [
      {
        name: 'Email',
        value: email,
        type: 'email',
        required: true,
        onError: setEmailError,
      },
      {
        name: 'Password',
        value: password,
        required: true,
        onError: setPasswordError,
      },
    ]

    const isValid = validateForm(formToValidate)

    if (!isValid) {
      return
    }
    dispatch(onCognitoAuthSignIn(email, password))
  }

  const handleGoogleSignIn = () => dispatch(onGoogleSignIn())

  const handleOktaSignIn = () => {
    const formToValidate: Array<FormAttr> = [
      {
        name: 'Your company name',
        value: oktaProvider,
        required: true,
        onError: setOktaProviderError,
      },
    ]

    const isValid = validateForm(formToValidate)

    if (!isValid) {
      return
    }
    dispatch(onOktaSignIn(`okta-${oktaProvider}`))
  }

  const handleMicrosoftLogin = () => dispatch(onMicrosoftSignIn())

  const initForm = (): JSX.Element => {
    if (type === 'signUp') {
      return (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TextInput
              required
              error={!!emailError || !!termsError}
              errorMessage={emailError || termsError}
              value={email}
              type='text'
              onChange={setEmail}
              title='Email'
              adornment='mail'
            />
          </Grid>
          <Grid item xs={12}>
            {ActionsContent}
          </Grid>
          <Grid item xs={12}>
            <Link to={!!emailError && email !== '' ? '/tell-more' : '/signUp'}>
              <SignButton onClick={setEmailForm}>Sign Up</SignButton>
            </Link>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid item xs={12}>
        <Grid item xs={12}>
          <TextInput
            required
            error={!!emailError}
            errorMessage={emailError}
            value={email}
            type='email'
            onChange={setEmail}
            title='Email'
            adornment='mail'
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            required
            error={!!passwordError || !!error.message}
            errorMessage={passwordError || error.message}
            onChange={setPassword}
            title='Password'
            adornment='password'
            type='password'
          />
        </Grid>
        <Grid item xs={12}>
          {ActionsContent}
        </Grid>
        <Grid item container justify='center' xs={12}>
          {loading ? (
            <TailSpin className='tail-spin' style={{margin: '40px 0'}} />
          ) : (
            <SignButton type='submit' onClick={handleSubmit}>
              Sign In
            </SignButton>
          )}
        </Grid>
      </Grid>
    )
  }

  const ActionsContent: JSX.Element =
    type === 'signIn' ? (
      <ActionsBox>
        <ActionsCheck>
          <Checkbox
            type='checkbox'
            checked={remember}
            onChange={toggleRemember}
          />
          <span>Remember me</span>
        </ActionsCheck>
        <Link to='/recover'>
          <span>Recover Password</span>
        </Link>
      </ActionsBox>
    ) : (
      <ActionsBox>
        <ActionsCheck>
          <Checkbox type='checkbox' checked={terms} onChange={toogleTerms} />
          <span>I agree with terms & conditions</span>
        </ActionsCheck>
      </ActionsBox>
    )

  return (
    <Grid
      container
      style={{display: 'flex', justifyContent: 'center', maxWidth: '450px'}}>
      <Grid item xs={12}>
        <HeaderDiv>
          <Title>
            Welcome to Bizly <br /> {title}{' '}
          </Title>
        </HeaderDiv>
      </Grid>
      {initForm()}
      <Divider>
        <DividerLine />
        <DividerText>Or</DividerText>
        <DividerLine />
      </Divider>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <ConnectButton onClick={handleGoogleSignIn} type='signGoogle' />
        <ConnectButton onClick={handleMicrosoftLogin} type='signMS' />
        <ConnectButton onClick={handleOktaSignIn} type='signOkta' />
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={8}>
            <TextInput
              required
              error={!!oktaProviderError}
              errorMessage={oktaProviderError}
              value={oktaProvider}
              type='text'
              onChange={setOktaProvider}
              title='Your company'
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              disabled
              placeholder={`.okta.com`}
              type='text'
              title='Okta'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignForm
