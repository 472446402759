import React, {VFC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {ChipItems} from '../ChipAutocomplete/ChipAutocomplete'
import EventFormNative from '../EventFormNative/EventFormNative'
import {Collection} from '../FormSelect/FormSelect'
import TopNavBar from '../TopNavBar/TopNavBar'

export interface FormPageProps {
  playBookList: Array<Collection>
  locationList: Array<Collection>
  tagList: Array<ChipItems>
  memberList: Array<ChipItems>
  durationList: Array<Collection>
}

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lato', sans-serif;
  width: 100%;
  background-color: ${BizlyPalette['base-black']};
`

const FormPage: VFC<FormPageProps> = ({
  playBookList,
  locationList,
  tagList,
  memberList,
  durationList,
}) => {
  return (
    <ContainerDiv>
      <TopNavBar title='Add New Event' />
      <EventFormNative
        playBookList={playBookList}
        locationList={locationList}
        tagList={tagList}
        memberList={memberList}
        durationList={durationList}
      />
    </ContainerDiv>
  )
}

export default FormPage
