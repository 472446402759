import React, {FC} from 'react'
import styled from 'styled-components'

import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

export interface PlaybookTagProps {
  text: string
  number: string
  category: string
  color?: string
}

const TextDiv = styled.div`
  height: 92px;
  min-width: 90px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0 11px 11px 0;
  overflow: hidden;
  padding: 0 24px;
`

const StyledSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Container = styled('div')<{tagColor: string}>`
  display: flex;
  font-family: 'Lato', sans-serif;
  min-height: 92px;
  max-width: 75%;
  min-width: 50%;
  background-color: ${(props) => props.tagColor};
  border-radius: 11px;
  color: white;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 24px;

  @media ${BreakpointsMediaQueries.md} {
    min-width: 100%;
  }
`

const NumberDiv = styled('div')<{tagDarkColor: string}>`
  min-height: 92px;
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px 0 0 11px;
  background-color: ${(props) => props.tagDarkColor};
`

const PlaybookTag: FC<PlaybookTagProps> = ({text, number, color}) => {
  const colors: Array<string> = [
    '#314788',
    '#3b1b44',
    '#863357',
    '#4d7788',
    '#83754d',
  ] // blue, purple, pink, green, yellow
  const darkPairColors: Array<string> = [
    '#2f3b63',
    '#342139',
    '#622f46',
    '#3f5863',
    '#60573f',
  ]

  let tagColor: string
  let tagDarkColor: string

  switch (color) {
    case 'blue':
      tagColor = colors[0]
      tagDarkColor = darkPairColors[0]
      break
    case 'purple':
      tagColor = colors[1]
      tagDarkColor = darkPairColors[1]
      break
    case 'pink':
      tagColor = colors[2]
      tagDarkColor = darkPairColors[2]
      break
    case 'green':
      tagColor = colors[3]
      tagDarkColor = darkPairColors[3]
      break
    case 'yellow':
      tagColor = colors[4]
      tagDarkColor = darkPairColors[4]
      break
    default:
      tagColor = 'darkgray'
      tagDarkColor = 'gray'
      break
  }

  return (
    <Container tagColor={tagColor}>
      <NumberDiv tagDarkColor={tagDarkColor}>#{number}</NumberDiv>
      <TextDiv>
        <StyledSpan>{text}</StyledSpan>
      </TextDiv>
    </Container>
  )
}

export default PlaybookTag
