// tslint:disable: typedef
import {
  ADD_EVENT,
  CHANGE_STATE,
  FETCH_CALENDAR_EVENTS,
  FETCH_DAY_EVENTS,
  FETCH_PLAYBOOK_DATA,
  FETCH_SETTINGS_DATA,
  FETCH_USER,
  GET_APP_DATA,
} from '../../types/actions/Api.actions'
import EventsInitialState from '../state/EventsInitialState'

const InitialDayPageState = {
  title: '',
  date: '',
  name: '',
  numberOfNewConections: 0,
  dayEvents: [],
}

const InitialUser = {
  name: '',
}

const InitialCalendarPageState = {
  title: '',
  subtitle: '',
  name: '',
  eventGroups: [],
  events: [],
}

const InitialSettingsPageState = {
  title: '',
  subtitle: '',
  name: '',
  eventGroups: [],
  events: [],
}

const InitialPlaybookPageState = {
  title: '',
  name: '',
  playbookTags: [],
}

export function DayEventsReducer(
  state: typeof InitialDayPageState = InitialDayPageState,
  action: {type: any; payload: any},
) {
  switch (action.type) {
    case FETCH_DAY_EVENTS:
      return {
        ...state,
        title: action.payload.title,
        date: action.payload.date,
        name: action.payload.name,
        numberOfNewConections: action.payload.numberOfNewConections,
        dayEvents: action.payload.dayEvents,
      }
    default:
      return state
  }
}

export function CalendarReducer(
  state: typeof InitialCalendarPageState = InitialCalendarPageState,
  action: {type: any; payload: any},
) {
  switch (action.type) {
    case FETCH_CALENDAR_EVENTS:
      return {
        ...state,
        title: action.payload.title,
        date: action.payload.date,
        name: action.payload.name,
        eventGroups: action.payload.eventGroups,
        events: action.payload.events,
      }
    default:
      return state
  }
}

export function SettingsReducer(
  state: typeof InitialSettingsPageState = InitialSettingsPageState,
  action: {type: any; payload: any},
) {
  switch (action.type) {
    case FETCH_SETTINGS_DATA:
      return {
        ...state,
        title: action.payload.title,
        name: action.payload.name,
        orgName: action.payload.orgName,
        numberOfEmployees: action.payload.numberOfEmployees,
      }
    default:
      return state
  }
}

export function PlaybookReducer(
  state: typeof InitialPlaybookPageState = InitialPlaybookPageState,
  action: {type: any; payload: any},
) {
  switch (action.type) {
    case FETCH_PLAYBOOK_DATA:
      return {
        ...state,
        title: action.payload.title,
        name: action.payload.name,
        playbookTags: action.payload.playbookTags,
      }
    default:
      return state
  }
}

export function UserReducer(
  state: typeof InitialUser = InitialUser,
  action: {type: any; payload: any},
) {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        name: action.payload.name,
      }
    default:
      return state
  }
}

export function EventsReducer(
  state: typeof EventsInitialState = EventsInitialState,
  action: {type: any; payload: any},
) {
  switch (action.type) {
    case GET_APP_DATA:
      return {
        ...state,
        events: [...state.events],
      }
    case ADD_EVENT:
      return {
        ...state,
        events: [...state.events, action.payload],
      }
    case CHANGE_STATE:
      return {
        ...state,
        state: action.payload,
      }
    default:
      return state
  }
}
