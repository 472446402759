import {Dispatch} from 'redux'
import {AppActions} from '../../types'
import {
  INIT_DATA_PROVIDER,
  FETCH_DATA_PROVIDER,
} from '../../types/actions/ProviderApi.actions'
import {UPDATE_USER} from '../../types/actions/CognitoAuth.actions'
import {fetchError, fetchStart, fetchSuccess} from './Common'
import {Auth} from 'aws-amplify'

import {
  getEvents as getGoogleCalendarEvents,
  getContacts as getGoogleContacts,
} from '../../core/services/GoogleAPI'
import {
  getEvents as getMicrosoftCalendarEvents,
  getContacts as getMicrosoftContacts,
} from '../../core/services/MicrosoftAPI'

export const FetchDataProvider = (provider?: string, client?: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    dispatch({type: INIT_DATA_PROVIDER})
    try {
      let events: any = []
      let contacts: any = []
      if (provider === 'Google') {
        events = await getGoogleCalendarEvents()
        contacts = await getGoogleContacts()
      }
      if (provider === 'Microsoft') {
        events = await getMicrosoftCalendarEvents(client)
        contacts = await getMicrosoftContacts(client)
      }
      localStorage.setItem(
        'providerData',
        JSON.stringify({
          events,
          contacts,
        }),
      )
      dispatch({
        type: FETCH_DATA_PROVIDER,
        payload: {
          events,
          contacts,
        },
      })
      dispatch(fetchSuccess())
    } catch (error) {
      console.log(error)
      dispatch(fetchError(error))
    }
  }
}

export const InitDataProvider = (provider: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    try {
      dispatch({type: INIT_DATA_PROVIDER})
      const attrs = {
        'custom:provider-synced': JSON.stringify(true),
        'custom:data-provider': provider,
      }
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, attrs)
      const updatedUser = await Auth.currentAuthenticatedUser()
      localStorage.setItem('user', JSON.stringify(updatedUser))
      dispatch({type: UPDATE_USER, payload: updatedUser})
      dispatch(fetchSuccess())
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}
