import React, {VFC} from 'react'

interface SvgEmailProps {
  height: number
  width: number
  fill: string
}

const SvgEmail: VFC<SvgEmailProps> = ({height, width, fill}) => {
  return (
    <svg
      height={height}
      width={width}
      fill={fill}
      viewBox='0 0 511.998 511.998'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M431.665 139.258V17.218H80.333v122.04L0 196.638V494.78h511.998V196.638zM181.992 342.069L30 450.633V233.505zm74.007-15.994L450.19 464.78H61.808zm74.007 15.994l151.992-108.564v217.127zm141.185-137.712l-39.526 28.232v-56.464zM401.665 47.218v206.8l-97.466 69.618-48.2-34.428-48.2 34.428-97.466-69.618v-206.8zM80.333 232.59l-39.526-28.232 39.526-28.232z' />
      <path d='M152.716 86.074h103.283v30H152.716zM152.716 154.929h206.566v30H152.716zM152.716 223.785h206.566v30H152.716z' />
    </svg>
  )
}

export default SvgEmail
