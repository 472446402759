import {Dispatch} from 'redux'
// import axios from 'axios'
import {AppActions} from '../../types'
import {
  SET_EMAIL_FORM,
  COGNITO_SIGN_UP,
  COGNITO_SIGN_IN,
  COGNITO_SIGN_OUT,
  COGNITO_CONFIRM_NEW_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUBMIT,
} from '../../types/actions/CognitoAuth.actions'
import {fetchError, fetchStart, fetchSuccess} from './Common'

import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth/lib/types'

import awsConfig from '../../aws-exports'
import Amplify, {Auth} from 'aws-amplify'
import {isLocalhost} from '../../core/utility/Utils'

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(',')

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(',')

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
}

Amplify.configure(updatedAwsConfig)

interface NewUser {
  email: string
  firstName: string
  lastName: string
  password: string
}

export const onSetEmailForm = (email: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SET_EMAIL_FORM,
      payload: email,
    })
    localStorage.setItem('emailForm', email)
  }
}

export const onCognitoAuthSignIn = (email: string, password: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    try {
      const user = await Auth.signIn(email, password)
      dispatch(fetchSuccess())
      dispatch({
        type: COGNITO_SIGN_IN,
        payload: user,
      })
      localStorage.setItem('user', JSON.stringify(user))
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}

export const onCognitoAuthSignUp = (newUser: NewUser) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    try {
      const {email, password} = newUser
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      })
      dispatch(fetchSuccess())
      localStorage.setItem('emailForm', email)
      localStorage.setItem('waitingVerificationCode', JSON.stringify(true))
      dispatch({
        type: COGNITO_SIGN_UP,
      })
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}

export const onCognitoSignOut = () => {
  return async (dispatch: Dispatch<AppActions>, getState: any) => {
    dispatch(fetchStart())
    try {
      localStorage.removeItem('user')
      localStorage.removeItem('providerData')
      await Auth.signOut({global: true})
      dispatch(fetchSuccess())
      dispatch({
        type: COGNITO_SIGN_OUT,
      })
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}

export const onVerificationCode = (email: string, code: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    try {
      await Auth.confirmSignUp(email, code)
      dispatch(fetchSuccess())
      localStorage.removeItem('emailForm')
      localStorage.removeItem('waitingVerificationCode')
      dispatch({
        type: COGNITO_CONFIRM_NEW_USER,
      })
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}

export const onForgotPassword = (email: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    try {
      await Auth.forgotPassword(email)
      dispatch(fetchSuccess())
      localStorage.setItem('emailForm', email)
      localStorage.setItem('forgotPassword', JSON.stringify(true))
      dispatch({
        type: FORGOT_PASSWORD,
        payload: email,
      })
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}

export const onForgotPasswordSubmit = (
  username: string,
  code: string,
  password: string,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    try {
      await Auth.forgotPasswordSubmit(username, code, password)
      dispatch(fetchSuccess())
      localStorage.removeItem('forgotPassword')
      localStorage.removeItem('emailForm')
      dispatch({
        type: FORGOT_PASSWORD_SUBMIT,
      })
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}

export const onGoogleSignIn = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      })
      dispatch(fetchSuccess())
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}

export const onOktaSignIn = (provider: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    try {
      // Okta is a valid provider but not registerd on CognitoHostedUIIdentityProvider interface.
      // Workaround: ignore tslint.
      //@ts-ignore
      await Auth.federatedSignIn({provider})
      dispatch(fetchSuccess())
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}

export const onMicrosoftSignIn = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    try {
      // Microsoft is a valid provider but not registerd on CognitoHostedUIIdentityProvider interface.
      // Workaround: ignore tslint.
      //@ts-ignore
      await Auth.federatedSignIn({provider: 'Microsoft'})
      dispatch(fetchSuccess())
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}

export const getCurrentUser = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    try {
      const user = await Auth.currentAuthenticatedUser()
      dispatch({
        type: COGNITO_SIGN_IN,
        payload: user,
      })
      localStorage.setItem('user', JSON.stringify(user))
      dispatch(fetchSuccess())
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}
