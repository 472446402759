import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'

export interface PlaybookNavItemProps {
  color?: string
  text: string
}

const Container = styled.div`
  display: flex;
  color: white;
  font-size: 22px;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
  align-items: center;
  overflow: hidden;
  margin-bottom: 24px;
  width: 100%;
`

const TextContainer = styled.span`
  margin-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`

const Checkbox = styled('input')<{borderColor: string}>`
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid ${(props) => props.borderColor};
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 24px;

  &:hover {
    border: 2px solid ${BizlyPalette['base-purple']};
  }
`

const PlaybookNavItem: FC<PlaybookNavItemProps> = ({color, text}) => {
  const colors: Array<string> = [
    '#314788',
    '#3b1b44',
    '#863357',
    '#4d7788',
    '#83754d',
  ] // blue, purple, pink, green, yellow

  let borderColor: string

  switch (color) {
    case 'blue':
      borderColor = colors[0]
      break
    case 'purple':
      borderColor = colors[1]
      break
    case 'pink':
      borderColor = colors[2]
      break
    case 'green':
      borderColor = colors[3]
      break
    case 'yellow':
      borderColor = colors[4]
      break
    default:
      borderColor = '#8d8d8f'
      break
  }

  return (
    <Container>
      <Checkbox borderColor={borderColor} type='checkbox' />
      <TextContainer>{text}</TextContainer>
    </Container>
  )
}

export default PlaybookNavItem
