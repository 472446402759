import InputAdornment from '@material-ui/core/InputAdornment'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EditIcon from '@material-ui/icons/Edit'
import LockIcon from '@material-ui/icons/Lock'
import MailIcon from '@material-ui/icons/Mail'
import PersonIcon from '@material-ui/icons/Person'
import React, {FC} from 'react'

export interface TextInputProps {
  placeholder?: string
  adornment?: string
  title?: string
  type: string
  value?: string
  error?: boolean
  required?: boolean
  errorMessage?: string
  onChange?: (value: string) => void
  disabled?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    textArea: {
      fontFamily: "'Lato', sans-serif",
      width: '100%',
      margin: '0 1% 24px 0',
      '& input': {
        color: 'white',
        fontSize: '16px',
        lineHeight: '19px',
      },
      '& label': {
        color: '#8c8c8d',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#8c8c8d',
      },
      '& div': {
        '&.MuiInput-underline': {
          '&:after': {
            borderBottom: 'unset',
            transistion: 'unset',
          },
          '&:before': {
            borderBottom: '1px solid #3b3b3d !important',
            transistion: 'unset',
          },
        },
      },
    },
  }),
)

const TextInput: FC<TextInputProps> = ({
  onChange,
  placeholder,
  title,
  adornment,
  type,
  value,
  error = false,
  errorMessage,
  required = false,
  disabled = false,
}) => {
  const classes = useStyles()

  let adormentIcon
  switch (adornment) {
    case 'edit':
      adormentIcon = (
        <EditIcon
          style={{
            fill: 'white',
            cursor: 'pointer',
            height: '18px',
            width: '18px',
          }}
        />
      )
      break
    case 'password':
      adormentIcon = (
        <LockIcon
          style={{
            fill: 'white',
            cursor: 'pointer',
            height: '18px',
            width: '18px',
          }}
        />
      )
      break
    case 'mail':
      adormentIcon = (
        <MailIcon
          style={{
            fill: 'white',
            cursor: 'pointer',
            height: '18px',
            width: '18px',
          }}
        />
      )
      break
    case 'person':
      adormentIcon = (
        <PersonIcon
          style={{
            fill: 'white',
            cursor: 'pointer',
            height: '18px',
            width: '18px',
          }}
        />
      )
      break
    case 'verify':
      adormentIcon = (
        <CheckCircleIcon
          style={{
            fill: 'white',
            cursor: 'pointer',
            height: '18px',
            width: '18px',
          }}
        />
      )
      break
    default:
      adormentIcon = ''
      break
  }

  return (
    <TextField
      disabled={disabled}
      required={required}
      error={error}
      helperText={errorMessage}
      value={value}
      label={title}
      type={type}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>{adormentIcon}</InputAdornment>
        ),
      }}
      className={classes.textArea}
      placeholder={placeholder}
      onChange={(event) => onChange && onChange(event.target.value)}
    />
  )
}

export default TextInput
