import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
export interface AgendaItemProps {
  timeNumber?: string
  timeUnit?: string
  duration?: string
  title?: string
  description?: string
  isInform?: boolean
}

const Container = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Lato', sans-serif;
  margin-bottom: 20px;
`

const DescriptionSpan = styled.span`
  overflow: scroll;
`

const TimeContainer = styled.div`
  max-width: 117px;
  min-width: 117px;
  height: 117px;
  background-color: ${BizlyPalette['dark-gray']};
  border-radius: 11px 0 0 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  line-height: 30px;
  color: white;
  font-weight: bold;
  flex: 3;

  @media ${BreakpointsMediaQueries.sm} {
    font-size: 18px;
    padding: 0 10px;
  }
`

const TextContainer = styled.div`
  box-sizing: border-box;
  max-width: 405px;
  min-width: 300px;
  max-height: 117px;
  background-color: ${BizlyPalette['base-black']};
  border-radius: 0 11px 11px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  line-height: 30px;
  color: white;
  padding: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  overflow: scroll;
  flex: 6;

  @media ${BreakpointsMediaQueries.sm} {
    min-width: 200px;
  }
`

const InnerContainer = styled('div')<{state: boolean}>`
  display: flex;
  border: 3px solid ${BizlyPalette['outline-black']};
  border-radius: 14px;
  cursor: pointer;
  opacity: ${(props) => (props.state ? '1' : '0.5')};

  &:hover {
    border: 3px solid ${BizlyPalette['base-purple']};
  }

  @media ${BreakpointsMediaQueries.md} {
    max-width: 420px;
  }
`

const Checkbox = styled('input')<{
  state: boolean
  isInform: boolean | undefined
}>`
  min-width: 20px;
  min-height: 20px;
  background-color: ${(props) => (props.state ? '#46464b' : '#3A3A3A')};
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid ${BizlyPalette['background-gray']};
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 34px;
  display: ${(props) => (props.isInform ? 'none' : 'block')};

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }

  @media ${BreakpointsMediaQueries.sm} {
    display: none;
  } ;
`

const TitleSpan = styled('span')<{title: string | undefined}>`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 12px;
  display: ${(props) => (!!props.title ? 'block' : 'none')};
`

const AgendaItem: FC<AgendaItemProps> = ({
  timeNumber,
  timeUnit,
  description,
  isInform,
  title,
}) => {
  const [state, setState] = React.useState(true)

  const handleChange = () => {
    setState(!state)
  }

  return (
    <Container>
      <Checkbox
        state={state}
        isInform={isInform}
        type='checkbox'
        checked={state}
        onChange={handleChange}
      />
      <InnerContainer state={state} onClick={handleChange}>
        <TimeContainer>
          <div className='bsb-agenda-item-container-time-number'>
            {timeNumber}
          </div>
          <div className='bsb-agenda-item-container-time-unit'>{timeUnit}</div>
        </TimeContainer>
        <TextContainer>
          <DescriptionSpan>
            <TitleSpan title={title}>{title}</TitleSpan>
            {description}
          </DescriptionSpan>
        </TextContainer>
      </InnerContainer>
    </Container>
  )
}

export default AgendaItem
