import {createStyles, makeStyles} from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MenuButtonProps {}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;
  width: 220px;
  background-color: ${BizlyPalette['base-purple']};
  border-radius: 10px;

  &:active {
    background-color: ${BizlyPalette['accent-black']};
  }

  @media ${BreakpointsMediaQueries.sm} {
    width: 200px;
  } ;
`

const ButtonLeft = styled.button`
  outline: none;
  border: none;
  display: flex;
  height: 50px;
  width: 160px;
  color: ${BizlyPalette.white};
  align-items: center;
  justify-content: center;
  background-color: ${BizlyPalette['base-purple']};
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  border-radius: 10px 0 0 10px;

  &:hover {
    color: ${BizlyPalette['info-yellow']};
  }

  &:active {
    background-color: ${BizlyPalette['accent-black']};
  }

  @media ${BreakpointsMediaQueries.sm} {
    font-size: 14px;
  } ;
`

const ButtonRight = styled.button`
  outline: none;
  display: flex;
  height: 50px;
  width: 60px;
  color: ${BizlyPalette.white};
  align-items: center;
  justify-content: center;
  background-color: ${BizlyPalette['base-purple']};
  border: none;
  border-left: 1px solid white;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  border-radius: 0 10px 10px 0;

  &:hover {
    color: ${BizlyPalette['info-yellow']};
  }

  &:active {
    background-color: ${BizlyPalette['accent-black']};
  }
`

const useStyles = makeStyles(() =>
  createStyles({
    SVG: {
      '&:hover': {
        fill: `${BizlyPalette['info-yellow']} !important`,
      },
    },
  }),
)

const MenuButton: FC<MenuButtonProps> = ({}) => {
  const classes = useStyles()

  return (
    <Container>
      <ButtonLeft type='submit'>Create New Event</ButtonLeft>
      <ButtonRight>
        <MoreHorizIcon
          style={{fill: 'white', width: '40px', height: '40px'}}
          className={classes.SVG}
        />
      </ButtonRight>
    </Container>
  )
}

export default MenuButton
