// tslint:disable: typedef
import {nanoid} from 'nanoid'

const Settings = [
  {
    title: 'Company information',
    description: 'Company photo, name & details',
    type: 'information',
    borderBottom: true,
    section: 'settings',
    id: nanoid(),
  },
  {
    title: 'Teams & Users',
    description: 'Add new users and create teams',
    type: 'teams',
    borderBottom: true,
    section: 'settings',
    id: nanoid(),
  },
  {
    title: 'Reports',
    description: 'Usage and actions reports',
    type: 'reports',
    borderBottom: true,
    section: 'settings',
    id: nanoid(),
  },
  {
    title: 'Integrations',
    description: 'Setup app integrations',
    type: 'integrations',
    borderBottom: false,
    section: 'settings',
    id: nanoid(),
  },
]

export default Settings
