import saMessages from '../locales/es_ES.json'
import {esES} from '@material-ui/core/locale'

const saLang = {
  messages: {
    ...saMessages,
  },
  muiLocale: esES,
  locale: 'es',
}
export default saLang
