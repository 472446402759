import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

export interface CallToActionProps {
  title?: string
  description?: string
}

const Container = styled.div`
  max-width: 960px;
  width: 100%;
  display: flex;
  height: 148px;
  border-radius: 10px;
  background-color: ${BizlyPalette['base-purple']};
  color: white;
  font-family: 'Lato', sans-serif;
  justify-content: space-between;
  margin: 42px 0;

  @media ${BreakpointsMediaQueries.xs} {
    flex-direction: column;
    margin: 22px 0;
  }

  @media ${BreakpointsMediaQueries.md} {
    padding: 16px;
    box-sizing: border-box;
  }

  @media ${BreakpointsMediaQueries.md} {
    width: 90%;
  } ;
`

const CtaButton = styled.button`
  background-color: ${BizlyPalette['base-purple']};
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  max-width: 240px;
  width: 100%;
  height: 44px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  outline: none;
  cursor: pointer;

  &:active {
    background-color: #34103e;
  }

  &:hover {
    border: 1px solid ${BizlyPalette['info-yellow']};
  }

  @media ${BreakpointsMediaQueries.md} {
    height: unset;
    height: unset;
    max-width: 150px;
    padding: 10px 0;
    margin-left: 12px;
  }

  @media ${BreakpointsMediaQueries.xs} {
    margin-left: 6px;
    font-size: 14px;
  }
`

const Title = styled.h1`
  font-size: 26px;
  height: 38px;
  font-weight: bold;
  line-height: 38px;
  margin: 0;
`

const Description = styled.p`
  font-size: 14px;
  max-width: 380px;
  width: 100%;
  font-weight: normal;
  line-height: 21px;
  color: ${BizlyPalette['subtitle-gray']};
  margin: 0;
  margin-top: 12px;

  @media ${BreakpointsMediaQueries.md} {
    margin-top: 4px;
  }

  @media ${BreakpointsMediaQueries.sm} {
    display: none;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5.5;
  justify-content: center;
  padding-left: 40px;
  align-items: flex-start;

  @media ${BreakpointsMediaQueries.sm} {
    padding-left: 0;
    align-items: center;
    margin-bottom: 6px;
  } ;
`

const ActionContainer = styled.div`
  display: flex;
  flex: 3.5;
  align-items: center;

  @media ${BreakpointsMediaQueries.xs} {
    padding-left: 3;
    justify-content: center;
  }
`

const CallToAction: FC<CallToActionProps> = ({title, description}) => {
  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
      <ActionContainer>
        <CtaButton>Create your first meeting!</CtaButton>
      </ActionContainer>
    </Container>
  )
}

export default CallToAction
