import React, {useEffect} from 'react'
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import Integrations from '../../assets/utils/Integrations.enum'
import Settings from '../../assets/utils/Settings.enum'
import {FetchSettingsData} from '../../redux/actions/ApiActions'
import IntegrationContainer from '../IntegrationContainer/IntegrationContainer'
import {IntegrationItemProps} from '../IntegrationItem/IntegrationItem'
import {MenuItemProps} from '../MenuItem/MenuItem'
import SettingsNav from '../SettingsNav/SettingsNav'
import TopNavBar from '../TopNavBar/TopNavBar'

export interface IntegrationPageProps {
  orgName: string
  numberOfEmployees: string
  settings: Array<MenuItemProps>
  integrations: Array<IntegrationItemProps>
}

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  width: 96%;

  @media ${BreakpointsMediaQueries.md} {
    width: 94%;
  }

  @media ${BreakpointsMediaQueries.sm} {
    flex-direction: column;
    align-items: center;
  }
`

const IntegrationPage = () => {
  // tslint:disable-next-line: typedef
  const Dispatch = useDispatch()

  useEffect(() => {
    Dispatch(FetchSettingsData())
  }, [])

  const state: any = useSelector((data: RootStateOrAny) => data.settingsData)

  return (
    <ContainerDiv>
      <TopNavBar title='Settings' />
      <Content>
        <SettingsNav
          settingsList={Settings}
          orgName={state.orgName}
          numberOfEmployees={state.numberOfEmployees}
        />
        <IntegrationContainer integrationList={Integrations} />
      </Content>
    </ContainerDiv>
  )
}

export default IntegrationPage
