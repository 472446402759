import React, {useEffect} from 'react'
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import {GetAppData} from '../../redux/actions/ApiActions'
import AgendaItemsContainer, {
  AgendaItemsContainerProps,
} from '../AgendaItemsContainer/AgendaItemsContainer'
import DummyChat from '../DummyChat/DummyChat'
import TopNavBar from '../TopNavBar/TopNavBar'
import AgendaInfoBar from '../AgendaInfoBar/AgendaInfoBar'

export interface AgendaPageProps {
  agendaItems: AgendaItemsContainerProps
}

// const members = [
//     { name: 'Tom Addams', confirmed: true },
//     { name: 'Paul X', rejected: true },
//     { name: 'Joe Luna', confirmed: false },
//     { name: 'Charles Lucas', confirmed: false },
//     { name: 'Andre Matterson', confirmed: true },
//     { name: 'Claire Santiago', rejected: true },
//     { name: 'Woody York', confirmed: true },
//     { name: 'Guy Franco', confirmed: false },
//     { name: 'Liam Jonas', confirmed: true },
// ]

const ContainerDiv = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: unset;

  @media ${BreakpointsMediaQueries.lg} {
    justify-content: center;
  }
`

const AgendaPage = () => {
  // tslint:disable-next-line: typedef
  const Dispatch = useDispatch()

  useEffect(() => {
    Dispatch(GetAppData())
  }, [])

  const state: any = useSelector((data: RootStateOrAny) => data.appData)

  return (
    <ContainerDiv>
      <TopNavBar title='Agenda' />
      <AgendaInfoBar text='meeting' tags={[{name: 'Personal'}]} />
      <Content>
        <AgendaItemsContainer
          title={state.events[state.events.length - 1].title}
          description={state.events[state.events.length - 1].description}
          agendaItems={state.events[state.events.length - 1].agendaItems}
          isInform={false}
        />
        <DummyChat />
      </Content>
    </ContainerDiv>
  )
}

export default AgendaPage
