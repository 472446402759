import moment from 'moment'

const EventsInitialState = {
  state: false,
  events: [
    {
      id: '1',
      userId: '1',
      title: 'Introduction to: The World Of Tomorrow Today! <by Frank Miller>',
      playbook: [],
      date: moment(),
      timeFrom: new Date(),
      timeTo: new Date(),
      locationType: 'Video',
      locationAddress: 'locationAddress 1',
      members: [
        {
          avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/women/11.jpg',
        },
        {
          name: 'Guy Manuel',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/men/9.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
        },
        {
          name: 'Cristina Luna',
        },
        {
          name: 'Joe Martin',
        },
      ],
      agendaDescription: {
        title: 'Intro to Deep Collaboration',
        description:
          'Numerous studies have shown that this “always available” model is not only less productive, but also takes us away from “Flow” and “Happiness”. Deep Work, in contrast move us towards Flow, where one tends to maximize creativity and feel an inherent enjoyment of the activity.',
        whyTitle: 'Why should you use this playbook?',
        whyDescription:
          'Numerous studies have shown that this “always available” model is not only less productive, but also takes us away from “Flow” and “Happiness”. Deep Work, in contrast move us towards Flow, where one tends to maximize creativity and feel an inherent enjoyment.',
        whenTitle: 'When should you use this playbook?',
        whenDescription: [
          'Bi-Weekly',
          'After engineering meetings',
          'After a long weekend',
        ],
      },
      agendaItems: [
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
      ],
      description: 'description 1',
      tags: [
        {
          title: 'Business',
        },
        {
          title: 'Casual',
        },
        {
          title: 'Smart',
        },
      ],
      eventScore: 8,
      type: {
        company: true,
      },
    },
    {
      id: '2',
      userId: '1',
      title: 'Introduction to: The World Of Tomorrow Today! <by Frank Miller>',
      playbook: [],
      date: new Date(),
      timeFrom: new Date(),
      timeTo: new Date(),
      locationType: 'Video',
      locationAddress: 'locationAddress 1',
      members: [
        {
          avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/women/11.jpg',
        },
        {
          name: 'Guy Manuel',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/men/9.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
        },
        {
          name: 'Cristina Luna',
        },
        {
          name: 'Joe Martin',
        },
      ],
      agendaItems: [
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
      ],
      description: 'description 1',
      tags: [
        {
          title: 'Business',
        },
        {
          title: 'Casual',
        },
        {
          title: 'Smart',
        },
      ],
      eventScore: 8,
      type: {
        company: true,
      },
    },
    {
      id: '3',
      userId: '1',
      title: 'Introduction to: The World Of Tomorrow Today! <by Frank Miller>',
      playbook: [],
      date: new Date(),
      timeFrom: new Date(),
      timeTo: new Date(),
      locationType: 'Video',
      locationAddress: 'locationAddress 1',
      members: [
        {
          avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/women/11.jpg',
        },
        {
          name: 'Guy Manuel',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/men/9.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
        },
        {
          name: 'Cristina Luna',
        },
        {
          name: 'Joe Martin',
        },
      ],
      agendaItems: [
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
      ],
      description: 'description 1',
      tags: [
        {
          title: 'Business',
        },
        {
          title: 'Casual',
        },
        {
          title: 'Smart',
        },
      ],
      eventScore: 8,
      type: {
        company: true,
      },
    },
    {
      id: '4',
      userId: '1',
      title: 'Introduction to: The World Of Tomorrow Today! <by Frank Miller>',
      playbook: [],
      date: new Date(),
      timeFrom: new Date(),
      timeTo: new Date(),
      locationType: 'Video',
      locationAddress: 'locationAddress 1',
      members: [
        {
          avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/women/11.jpg',
        },
        {
          name: 'Guy Manuel',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/men/9.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
        },
        {
          name: 'Cristina Luna',
        },
        {
          name: 'Joe Martin',
        },
      ],
      agendaItems: [
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
      ],
      description: 'description 1',
      tags: [
        {
          title: 'Business',
        },
        {
          title: 'Casual',
        },
        {
          title: 'Smart',
        },
      ],
      eventScore: 8,
      type: {
        company: true,
      },
    },
    {
      id: '5',
      userId: '1',
      title: 'Introduction to: The World Of Tomorrow Today! <by Frank Miller>',
      playbook: [],
      date: new Date(),
      timeFrom: new Date(),
      timeTo: new Date(),
      locationType: 'Video',
      locationAddress: 'locationAddress 1',
      members: [
        {
          avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/women/11.jpg',
        },
        {
          name: 'Guy Manuel',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/men/9.jpg',
        },
        {
          avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
        },
        {
          name: 'Cristina Luna',
        },
        {
          name: 'Joe Martin',
        },
      ],
      agendaItems: [
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
        {
          duration: '20 min',
          description:
            'Collaboration and productivity tooling evolved separately. The move to remote or hybrid work is the catalyst we’ve needed to fuse them.',
          isInform: true,
        },
      ],
      description: 'description 1',
      tags: [
        {
          title: 'Business',
        },
        {
          title: 'Casual',
        },
        {
          title: 'Smart',
        },
      ],
      eventScore: 8,
      type: {
        company: true,
      },
    },
  ],
}

export default EventsInitialState
