import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

interface Element {
  name: string
}

export interface PlaybookTagDropdownMenuProps {
  elements: Array<Element>
}

const DropdownButton = styled.button`
  outline: none;
  background-color: ${BizlyPalette['base-black']};
  color: white;
  font-weight: bold;
  font-size: 48px;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;

  @media ${BreakpointsMediaQueries.sm} {
    font-size: 24px;
  }
`

const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  padding: 12px;
  border-radius: 9px;
  background-color: ${BizlyPalette['base-black']};
  z-index: 1;
  top: 70px;
`

const DropdownContainer = styled.div`
  flex: 3;
  background-color: ${BizlyPalette['base-black']};
  width: 250px;
  border-radius: 9px;
  padding: 12px;
  font-family: 'Lato', sans-serif;
  color: white;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  @media ${BreakpointsMediaQueries.sm} {
    max-width: 150px;
  }

  &: hover {
    background-color: ${BizlyPalette['accent-black']};
  }

  &:active ${DropdownMenu} {
    display: block;
  }

  &:hover ${DropdownMenu} {
    display: block;
  }

  &: hover ${DropdownButton} {
    background-color: ${BizlyPalette['accent-black']};
  }
`

const MenuItems = styled.div`
  font-weight: bold;
  font-size: 24px;
  height: 100%;
  padding: 10px;
  cursor: pointer;

  &: hover {
    background-color: ${BizlyPalette['accent-black']};
  }
`

const PlaybookTagDropdownMenu: FC<PlaybookTagDropdownMenuProps> = ({
  elements,
}) => {
  return (
    <DropdownContainer>
      <DropdownButton>
        Popular{' '}
        <KeyboardArrowDownIcon
          style={{
            fill: 'white',
            width: '28px',
            height: '28px',
            marginLeft: '12px',
          }}
        />{' '}
      </DropdownButton>
      <DropdownMenu>
        {elements.map((item) => (
          <MenuItems>{item.name}</MenuItems>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  )
}

export default PlaybookTagDropdownMenu
