import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import React, {FC, Fragment, useEffect} from 'react'
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import GetInitials from '../../assets/utils/GetInitials'
import {FetchUser} from '../../redux/actions/ApiActions'

import {onCognitoSignOut} from '../../redux/actions/CognitoAuth'

export interface TopNavBarProps {
  title: string
  subtitle?: string
}

const OuterContainer = styled.div`
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;
  min-height: 84px;
  width: calc(100vw - 84px);
  padding: 0 20px;
  box-sizing: border-box;
  background-color: ${BizlyPalette['base-black']};
  margin-bottom: 16px;
  border-bottom: 1px solid ${BizlyPalette['outline-gray']};
  position: sticky;
  top: 0;
  z-index: 2;

  @media ${BreakpointsMediaQueries.lg} {
    min-width: 100%;
  }

  @media ${BreakpointsMediaQueries.sm} {
    position: relative;
  }
`

const LeftContainer = styled.div`
  display: flex;
  flex: 3;
  width: 100%;
  max-width: 340px;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  @media ${BreakpointsMediaQueries.md} {
    flex: 1;
    margin-right: 20px;
  }
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 7;
  justify-content: space-between;

  @media ${BreakpointsMediaQueries.md} {
    flex: 4;
  }

  @media ${BreakpointsMediaQueries.md} {
    flex: 1;
    justify-content: flex-end;
  }
`

const RightContainerNoSub = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
  justify-content: flex-end;
`

const SectionEnd = styled.div`
  display: flex;
`

const SectionStart = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  color: white;
  display: flex;
  align-items: center;

  @media ${BreakpointsMediaQueries.md} {
    font-size: 18px;
  }

  @media ${BreakpointsMediaQueries.md} {
    display: none;
  }
`

const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-left: 16px;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media ${BreakpointsMediaQueries.md} {
    font-size: 18px;
  } ;
`

const Button = styled.button`
  display: flex;
  color: ${BizlyPalette['lighter-gray-icon']};
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 10px;
  outline: none;
  background-color: ${BizlyPalette['base-black']};
  border: 1px solid ${BizlyPalette['outline-gray']};
  cursor: pointer;

  @media ${BreakpointsMediaQueries.md} {
    min-height: 36px;
    min-width: 36px;
  }

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }

  &:active {
    background-color: ${BizlyPalette['accent-black']};
  }
`

const Subtitle = styled.p`
  margin: 0 0 0 12px;
`

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid ${BizlyPalette['background-gray']};
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }
`

const TopNavBar: FC<TopNavBarProps> = ({title, subtitle}) => {
  // tslint:disable-next-line: typedef
  const Dispatch = useDispatch()

  useEffect(() => {
    Dispatch(FetchUser())
  }, [])

  const state: any = useSelector((data: RootStateOrAny) => data.userData)

  const {user} = useSelector((state: RootStateOrAny) => state.cognitoAuth)

  const handleSignOut = () => {
    Dispatch(onCognitoSignOut())
  }

  const initials: string = GetInitials(state.name || '')

  const Content: JSX.Element = !!subtitle ? (
    <Fragment>
      <LeftContainer>
        <Checkbox type='checkbox' />
        <Title>{title}</Title>
      </LeftContainer>
      <RightContainer>
        <SectionStart>
          <Button>
            <CalendarTodayIcon
              style={{fill: '#8e8f93', width: '18px', height: '18px'}}
            />
          </Button>
          <Subtitle>{subtitle}</Subtitle>
        </SectionStart>
        <SectionEnd>
          <Button>
            <SearchIcon
              style={{fill: '#8e8f93', width: '18px', height: '18px'}}
            />
          </Button>
          <Button>{initials}</Button>
          {user && <Button onClick={handleSignOut}>Sign out</Button>}
        </SectionEnd>
      </RightContainer>
    </Fragment>
  ) : (
    <Fragment>
      <LeftContainer>
        <Button>
          <MenuIcon style={{fill: '#8e8f93', width: '18px', height: '18px'}} />
        </Button>
        <Title>{title}</Title>
      </LeftContainer>
      <RightContainerNoSub>
        <SectionEnd>
          <Button>
            <SearchIcon
              style={{fill: '#8e8f93', width: '18px', height: '18px'}}
            />
          </Button>
          <Button>{initials}</Button>
          {user && <Button onClick={handleSignOut}>Sign out</Button>}
        </SectionEnd>
      </RightContainerNoSub>
    </Fragment>
  )

  return <OuterContainer>{Content}</OuterContainer>
}

export default TopNavBar
