/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import CalendarItem, {CalendarItemProps} from '../CalendarItem/CalendarItem'

export interface DayEvents {
  date: string
  items: Array<CalendarItemProps>
}

export interface CalendarItemsContainerProps {
  events: Array<DayEvents>
}

const OuterContainer = styled.div`
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  background-color: ${BizlyPalette['base-black']};
  color: ${BizlyPalette.white};
  width: 70%;
  padding: 0 20px;

  @media ${BreakpointsMediaQueries.md} {
    width: 100%;
  }
`

const TitleContainer = styled.div`
  margin: 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${BizlyPalette.white};

  @media ${BreakpointsMediaQueries.sm} {
    width: 100%;
    margin: 20px 10px;
  }
`

const CalendarItemsContainer: FC<CalendarItemsContainerProps> = ({events}) => {
  const DayItems: JSX.Element[] = events.map((event, index) => (
    <div key={index}>
      <TitleContainer>{event.date}</TitleContainer>
      {event.items.map((dayItem, i) => (
        <CalendarItem
          startTime={dayItem.startTime}
          finishTIme={dayItem.finishTIme}
          name={dayItem.name}
          description={dayItem.description}
          location={dayItem.location}
          key={i}
        />
      ))}
    </div>
  ))

  return <OuterContainer>{DayItems}</OuterContainer>
}

export default CalendarItemsContainer
