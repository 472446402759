import Modal from '@material-ui/core/Modal'
import React, {FC, useEffect, useState} from 'react'
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {GetAppData} from '../../redux/actions/ApiActions'
import AgendaItemsContainer from '../AgendaItemsContainer/AgendaItemsContainer'
// import AgendaOverlay from '../AgendaOverlay/AgendaOverlay'
import AgendaOverlaySideNav from '../AgendaOverlaySideNav/AgendaOverlaySideNav'
// import AgendaItemsContainer from '../AgendaItemsContainer/AgendaItemsContainer'
import Tag from '../Tag/Tag'
import VideoChat from '../VideoChat/VideoChat'
export interface AgendaInfoBarProps {
  text: string
  tags: Array<{name: string}>
}

const ContentInfoBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  top: -16px;
`

const StyledButtonBar = styled.button`
  background-color: ${BizlyPalette['base-purple']};
  color: white;
  border: none;
  border-radius: 0 0 5px 5px;
  max-height: 50px;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  margin: 0 6px;
  min-width: 120px;
  cursor: pointer;

  &: active {
    opacity: 0.3;
  }
`

const StyledModal = styled(Modal)`
  overflow: scroll;

  & > div {
    background-color: ${BizlyPalette['base-black']} !important;
    outline: none;
  }
`

const ContainerDiv = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
`

const AgendaInfoBar: FC<AgendaInfoBarProps> = ({text, tags}) => {
  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  // tslint:disable-next-line: typedef
  const Dispatch = useDispatch()

  useEffect(() => {
    Dispatch(GetAppData())
  }, [])

  const state: any = useSelector((data: RootStateOrAny) => data.appData)

  return (
    <ContentInfoBar>
      <StyledButtonBar onClick={onOpenModal}>{text}</StyledButtonBar>
      <StyledModal open={open} onClose={onCloseModal}>
        <ContainerDiv>
          <div
            style={{
              width: '10%',
              display: 'flex',
              alignItems: 'flex-start',
              marginLeft: '40px',
              height: '90%',
            }}>
            <AgendaOverlaySideNav onClick={onCloseModal} />
          </div>
          <div style={{width: '90%', display: 'flex', height: '90%'}}>
            <div style={{width: '44%'}}>
              <AgendaItemsContainer
                agendaItems={state.events[state.events.length - 1].agendaItems}
                isInform={false}
              />
            </div>
            {/* <div style={{ width: '70%' }}> */}
            <VideoChat />
            {/* </div> */}
          </div>
        </ContainerDiv>
      </StyledModal>
      {tags &&
        tags?.map((item, index) => (
          <Tag text={item.name} size='medium' key={index} />
        ))}
    </ContentInfoBar>
  )
}

export default AgendaInfoBar
