import AttachmentIcon from '@material-ui/icons/Attachment'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AttachmentsButtonProps {}

const StyledAttachmentsButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  font-size: 16px;
  line-height: 27px;
  width: 140px;
  justify-content: space-around;
  align-items: center;
  color: #676768;
  outline: none;
  cursor: pointer;

  &:hover {
    color: ${BizlyPalette['base-purple']};
  }
`

const StyledSpan = styled.span`
  text-decoration: underline;
`

const AttachmentsButton: FC<AttachmentsButtonProps> = ({}) => {
  return (
    <StyledAttachmentsButton className='bsb-calendar-item'>
      <AttachmentIcon
        style={{fill: '#676768', width: '20px', height: '20px'}}
      />
      <StyledSpan>Attachments</StyledSpan>
    </StyledAttachmentsButton>
  )
}

export default AttachmentsButton
