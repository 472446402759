import React, {VFC} from 'react'
import styled from 'styled-components'

import logo from '../../assets/images/logo.png'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import SignAction from '../SignActions/SignActions'
import SignForm from '../SignForm/SignForm'
import SignTopNavBar from '../SignTopNavBar/SignTopNavBar'
import TellMoreForm from '../TellMoreForm/TellMoreForm'

export interface SignPagesProps {
  buttonText: string
  buttonLink: string
  title?: string
  subtitle?: string
  type: 'signIn' | 'signUp' | 'tellMore' | 'recover' | 'thanks'
}

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;

  @media ${BreakpointsMediaQueries.sm} {
    padding: 0 24px;
  } ;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Logo = styled.img`
  display: none;

  @media ${BreakpointsMediaQueries.sm} {
    display: block;
    margin: 60px 0 40px 0;
  } ;
`

const SignPages: VFC<SignPagesProps> = ({buttonText, buttonLink, type}) => {
  let content: JSX.Element | ''

  switch (type) {
    case 'signIn':
      content = (
        <Content>
          <Logo src={logo} />
          <SignForm type='signIn' title='Sign in to fight crime.' />
        </Content>
      )
      break

    case 'signUp':
      content = (
        <Content>
          <Logo src={logo} />
          <SignForm type='signUp' title='Sign up to fight crime.' />
        </Content>
      )
      break

    case 'tellMore':
      content = (
        <Content>
          <Logo src={logo} />
          <TellMoreForm />
        </Content>
      )
      break

    case 'thanks':
      content = (
        <Content>
          <SignAction
            title='Thank you'
            type='thanks'
            subtitle='We sent an email to mgarite@bizly.com Click confirmation link in the email to verify your account. Once verified this page will direct to dashboard.'
          />
        </Content>
      )
      break

    case 'recover':
      content = (
        <Content>
          <SignAction
            title='Lost your password? Enter email below to recover.'
            type='recover'
            buttonText='Recover'
          />
        </Content>
      )
      break

    default:
      content = ''
      break
  }

  return (
    <ContainerDiv>
      <SignTopNavBar buttonText={buttonText} buttonLink={buttonLink} />
      {content}
    </ContainerDiv>
  )
}

export default SignPages
