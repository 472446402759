// tslint:disable: typedef

const FormPageData = {
  title: 'Add New Event',
  name: 'Pablo Picasso',
  playBookList: [
    {name: 'None Selected'},
    {
      name: 'PlayBook 1',
      title: 'Title 1',
      locationType: 'In person',
      locationAddress: 'New York City, 123-3, 5th Av.',
      description:
        '1. This is a demo description of what it should look if I select something',
    },
    {
      name: 'PlayBook 2',
      title: 'Title 2',
      locationType: 'Video',
      locationAddress: 'https://zoom.something/123/123',
      description:
        '2. This is a demo description of what it should look if I select something',
    },
    {
      name: 'PlayBook 3',
      title: 'Title 3',
      locationType: 'Chat',
      locationAddress: 'meet.google.com/something',
      description:
        '3. This is a demo description of what it should look if I select something',
    },
    {
      name: 'PlayBook 4',
      title: 'Title 4',
      locationType: 'In person',
      locationAddress: 'New York City, 123-3, 5th Av.',
      description:
        '4. This is a demo description of what it should look if I select something',
    },
    {
      name: 'PlayBook 5',
      title: 'Title 5',
      locationType: 'In person',
      locationAddress: 'New York City, 123-3, 5th Av.',
      description:
        '5. This is a demo description of what it should look if I select something',
    },
  ],
  locationList: [
    {name: 'None selected'},
    {name: 'In person'},
    {name: 'Video'},
    {name: 'Chat'},
  ],
  tagList: [
    {name: 'Business'},
    {name: 'Personal'},
    {name: 'Casual'},
    {name: 'Urgent'},
    {name: 'Important'},
  ],
  memberList: [
    {name: 'Lucas Loke'},
    {name: 'Armand Arnault'},
    {name: 'Johan Sebastian'},
    {name: 'Manu Williams'},
    {name: 'Darien Sook'},
  ],
  durationList: [
    {name: 'None selected'},
    {name: '5 min'},
    {name: '10 min'},
    {name: '15 min'},
    {name: '30 min'},
    {name: '60 min'},
    {name: '1 hour'},
    {name: '2 hours'},
    {name: '3 hours'},
    {name: '4 hours'},
  ],
}

export default FormPageData
