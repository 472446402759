import React, {useEffect} from 'react'
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import {FetchCalendarEvents} from '../../redux/actions/ApiActions'
import CalendarItemsContainer, {
  DayEvents,
} from '../CalendarItemsContainer/CalendarItemsContainer'
import CalendarNav from '../CalendarNav/CalendarNav'
import {MenuItemProps} from '../MenuItem/MenuItem'
import TopNavBar from '../TopNavBar/TopNavBar'

export interface CalendarPageProps {
  subtitle: string
  eventGroups: Array<MenuItemProps>
  events: Array<DayEvents>
}

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  width: 100%;

  @media ${BreakpointsMediaQueries.md} {
    flex-direction: column;
    align-items: center;
  }
`

const CalendarPage = () => {
  // tslint:disable-next-line: typedef
  const Dispatch = useDispatch()

  useEffect(() => {
    Dispatch(FetchCalendarEvents())
  }, [])

  const state: any = useSelector((data: RootStateOrAny) => data.calendarEvents)

  return (
    <ContainerDiv>
      <TopNavBar title='Schedule' subtitle={state.subtitle} />
      <Content>
        <CalendarNav settingsList={state.eventGroups} />
        <CalendarItemsContainer events={state.events} />
      </Content>
    </ContainerDiv>
  )
}

export default CalendarPage
