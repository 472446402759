import SendIcon from '@material-ui/icons/Send'
import {ChatBubble} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: unset;
  width: 100%;
  padding: 24px;
  position: relative;
  background-color: ${BizlyPalette['base-black']};

  @media ${BreakpointsMediaQueries.md} {
    display: none;
  }
`

const LeftChatBubble = styled(ChatBubble)`
  background-color: ${BizlyPalette['base-purple']};
  color: white;
  align-self: flex-end;
`

const RightChatBubble = styled(ChatBubble)`
  background-color: ${BizlyPalette['light-gray']};
  color: white;
  align-self: flex-start;
`

const StyledSendField = styled.div`
  position: absolute;
  bottom: 120px;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
`

const StyledInput = styled.input`
  width: 80%;
  min-height: 44px;
  padding: 0 12px;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  font-size: 16px;
`

const StyledSendButton = styled.button`
  background-color: ${BizlyPalette['base-purple']};
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  min-width: 44px;
`

const StyledChatBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-bottom: 180px;
`

const DummyChat = () => {
  return (
    <Content>
      <StyledChatBox>
        <RightChatBubble
          variant='incoming'
          senderName='Jim Halpert'
          timestamp='9:47 AM'
          css='margin: 1rem;'>
          I've always been your biggest flan.
        </RightChatBubble>
        <LeftChatBubble
          variant='outgoing'
          senderName='Ryan Howard'
          timestamp='9:45 AM'
          css='margin: 1rem;'>
          You should have put him in custardy.
        </LeftChatBubble>
        \
        <RightChatBubble
          variant='incoming'
          senderName='Jim Halpert'
          timestamp='9:47 AM'
          css='margin: 1rem;'>
          I've always been your biggest flan.
        </RightChatBubble>
        <LeftChatBubble
          variant='outgoing'
          senderName='Ryan Howard'
          timestamp='9:45 AM'
          css='margin: 1rem;'>
          You should have put him in custardy.
        </LeftChatBubble>
        <RightChatBubble
          variant='incoming'
          senderName='Jim Halpert'
          timestamp='9:47 AM'
          css='margin: 1rem;'>
          I've always been your biggest flan.
        </RightChatBubble>
        <LeftChatBubble
          variant='outgoing'
          senderName='Ryan Howard'
          timestamp='9:45 AM'
          css='margin: 1rem;'>
          You should have put him in custardy.
        </LeftChatBubble>
        <RightChatBubble
          variant='incoming'
          senderName='Jim Halpert'
          timestamp='9:47 AM'
          css='margin: 1rem;'>
          I've always been your biggest flan.
        </RightChatBubble>
        <LeftChatBubble
          variant='outgoing'
          senderName='Ryan Howard'
          timestamp='9:45 AM'
          css='margin: 1rem;'>
          You should have put him in custardy.
        </LeftChatBubble>
      </StyledChatBox>
      <StyledSendField>
        <StyledInput type='text' placeholder='Type to add your text...' />
        <StyledSendButton>
          <SendIcon style={{fill: 'white', width: '20px', height: '20px'}} />
        </StyledSendButton>
      </StyledSendField>
    </Content>
  )
}

export default DummyChat
