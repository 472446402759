export enum BizlyPalette {
  'avatar-gray' = '#898a8d',
  'base-purple' = '#5E1A6F',
  'sucess-green' = '#1a2824',
  'sucess-border' = '#3b4845',
  'base-black' = '#1d1d1e',
  'black' = '#000',
  'white' = '#FFF',
  'lighter-yellow' = '#FEC000',
  // tooltip-yellow
  'light-yellow' = '#FDB72E',
  // tooltip-green
  'base-green' = '#1B6328',
  // tooltip-red
  'dark-red' = '#6C2029',
  // tooltip-gray
  'light-gray' = '#707078',
  // tooltip-list-gray
  'lighter-gray' = '#898B8E',
  // tooltip-background-black
  'light-black' = '#0F0F0F',
  'dimmed-gray' = '#7E7F82',
  'info-yellow' = '#FEC000',
  'icon-gray' = '#8d8d8f',
  'outline-gray' = '#878787',
  'text-gray' = '#909091',
  'accent-black' = '#222526',
  'lighter-gray-icon' = '#8e8f93',
  'base-gray' = '#212223',
  'outline-red' = '#6c1236',
  'outline-blue' = '#18a0fb',
  'outline-green' = '#1e5e13',
  'service-gray' = '#8c8c8d',
  'subtitle-gray' = '#8b8b8d',
  'background-gray' = '#3A3A3A',
  'outline-black' = '#46464b',
  'light-outline' = '#777881',
  'light-green' = '#49b341',
  'dark-gray' = '#292929',
}
