import React, {useEffect} from 'react'
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import {GetAppData} from '../../redux/actions/ApiActions'
import AgendaDescriptionContainer, {
  AgendaDescriptionContainerProps,
} from '../AgendaDescriptionContainer/AgendaDescriptionContainer'
import AgendaItemsContainer, {
  AgendaItemsContainerProps,
} from '../AgendaItemsContainer/AgendaItemsContainer'
import TopNavBar from '../TopNavBar/TopNavBar'

export interface AgendaInfoPageProps {
  agendaDescription: AgendaDescriptionContainerProps
  agendaItems: AgendaItemsContainerProps
}

const ContainerDiv = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
`

const Content = styled.div`
  display: flex;
  width: 100%;

  @media ${BreakpointsMediaQueries.sm} {
    flex-direction: column;
    align-items: center;
  }
`

const AgendaInfoPage = () => {
  // tslint:disable-next-line: typedef
  const Dispatch = useDispatch()

  useEffect(() => {
    Dispatch(GetAppData())
  }, [])

  const state: any = useSelector((data: RootStateOrAny) => data.appData)

  return (
    <ContainerDiv>
      <TopNavBar title='Agenda' />
      <Content>
        <AgendaDescriptionContainer
          title={state.events[0].agendaDescription.title}
          description={state.events[0].agendaDescription.description}
          whyTitle={state.events[0].agendaDescription.whyTitle}
          whyDescription={state.events[0].agendaDescription.whyDescription}
          whenTitle={state.events[0].agendaDescription.whenTitle}
          whenDescription={state.events[0].agendaDescription.whenDescription}
        />
        <AgendaItemsContainer
          title={state.events[state.events.length - 1].agendaItems.title}
          description={
            state.events[state.events.length - 1].agendaItems.description
          }
          agendaItems={state.events[state.events.length - 1].agendaItems}
          isInform={true}
        />
      </Content>
    </ContainerDiv>
  )
}

export default AgendaInfoPage
