import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import PlaybookNavDropdownMenu from '../PlaybookNavDropdownMenu/PlaybookNavDropdownMenu'
import PlaybookTag from '../PlaybookTag/PlaybookTag'
import {PlaybookTagProps} from '../PlaybookTag/PlaybookTag'
import PlaybookTagDropdownMenu from '../PlaybookTagDropdownMenu/PlaybookTagDropdownMenu'

export interface PlaybookTagContainerProps {
  playbookTags: Array<PlaybookTagProps>
}

const playbookCategories: Array<{name: string}> = [
  {name: 'My Playbooks'},
  {name: 'Recomended'},
  {name: 'All'},
]

const Container = styled.div`
  color: white;
  font-size: 22px;
  font-weight: bold;
  width: 65%;
  font-family: 'Lato', sans-serif;
  background-color: ${BizlyPalette['base-black']};
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;

  @media ${BreakpointsMediaQueries.md} {
    margin-left: 0;
    padding-right: 0;
    align-items: center;
  }

  @media ${BreakpointsMediaQueries.sm} {
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
  } ;
`

const NavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 540px;
  justify-content: space-between;
`
const PlaybookTagContainer: FC<PlaybookTagContainerProps> = ({
  playbookTags,
}) => {
  return (
    <Container>
      <NavContainer>
        <PlaybookTagDropdownMenu elements={playbookCategories} />
        <PlaybookNavDropdownMenu playbookTags={playbookTags} />
      </NavContainer>
      {playbookTags.map((item, i) => (
        <PlaybookTag
          text={item.text}
          color={item.color}
          number={`${i + 1}`}
          category={item.category}
        />
      ))}
    </Container>
  )
}

export default PlaybookTagContainer
