// import BackspaceIcon from '@material-ui/icons/Backspace'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import GetInitials from '../../assets/utils/GetInitials'

export interface MemberChipProps {
  name: string
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Lato', sans-serif;
  height: 54px;
  max-width: 300px;
  min-width: 120px;
  padding: 0 8px;
  margin: 8px 8px 8px 0;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  overflow: hidden;
`

// const Button = styled.button`
//     padding: 0;
//     border: 1px solid white;
//     background-color: transparent;
//     outline: none;
//     border: none;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     height: 36px;
//     width: 36px;
//     border-radius: 6px;
//     cursor: pointer;

//     &:active {
//         background-color: ${BizlyPalette['dimmed-gray']};
//     }
// `

const Initials = styled.span`
  height: 36px;
  width: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  background-color: ${BizlyPalette['dimmed-gray']};
`

const Name = styled.span`
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  margin-right: 20px;
`

const MemberChip: FC<MemberChipProps> = ({name}) => {
  const initials: string = GetInitials(name || '')

  return (
    <Container>
      <Initials>{initials}</Initials>
      <Name>{name}</Name>
      {/* <Button type='button' >
                <BackspaceIcon style={{ fill: 'white', height: '16px', width: '18px' }} />
            </Button> */}
    </Container>
  )
}

export default MemberChip
