import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import React, {FC} from 'react'
import styled from 'styled-components'

import {BizlyPalette} from '../../assets/utils/BizlyPalette.enum'
import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import MenuItem, {MenuItemProps} from '../MenuItem/MenuItem'

export interface SettingsNavProps {
  settingsList: Array<MenuItemProps>
  orgName: string
  orgLogo?: string
  numberOfEmployees: string
}

const LogoUpload = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 114px;
  width: 114px;
  border-radius: 26px;
  margin-bottom: 20px;
  border: none;
  outline: none;
  background-color: ${BizlyPalette['accent-black']};
  cursor: pointer;

  &:active {
    background-color: ${BizlyPalette['dimmed-gray']};
  }

  &:hover {
    border: 1px solid ${BizlyPalette['base-purple']};
  }
`

const Container = styled.div`
  font-family: 'Lato', sans-serif;
  color: white;
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: center;
  background-color: ${BizlyPalette['base-black']};
  padding: 40px 60px 0 10px;
  max-width: 340px;

  @media ${BreakpointsMediaQueries.md} {
    flex: 1;
  }

  @media ${BreakpointsMediaQueries.sm} {
    padding: 24px 0 0 0;
    width: 100%;
  } ;
`

const OrgName = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 12px;
`

const EmployeeCount = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${BizlyPalette['subtitle-gray']};
  margin-bottom: 30px;
  text-align: center;
`

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${BreakpointsMediaQueries.sm} {
    flex-direction: row;
    width: 100%;
  } ;
`

const LogoContainer = styled('div')<{orgLogo: string | undefined}>`
  background-image: url(${(props) => props.orgLogo});
  background-position: center;
  image-size: contain;
  background-repeat: no-repeat;
  height: 114px;
  width: 114px;
  border-radius: 26px;
  margin-bottom: 20px;
`

const SettingsNav: FC<SettingsNavProps> = ({
  settingsList,
  orgName,
  orgLogo,
  numberOfEmployees,
}) => {
  const LogoContent = !!orgLogo ? (
    <LogoContainer orgLogo={orgLogo}></LogoContainer>
  ) : (
    <LogoUpload>
      <CloudUploadIcon
        style={{width: '28px', height: '28px', fill: '#8d8d8f'}}
      />
    </LogoUpload>
  )

  const settingsContent = !!settingsList
    ? settingsList.map((item) => (
        <MenuItem
          title={item.title}
          description={item.description}
          type={item.type}
          borderBottom={item.borderBottom}
          section={item.section}
          key={item.id}
        />
      ))
    : ''

  return (
    <Container>
      {LogoContent}
      <OrgName>{orgName}</OrgName>
      <EmployeeCount>{numberOfEmployees} Employees</EmployeeCount>
      <SettingsContainer>{settingsContent}</SettingsContainer>
    </Container>
  )
}

export default SettingsNav
