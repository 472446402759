import React, {VFC} from 'react'
import styled from 'styled-components'

import {BreakpointsMediaQueries} from '../../assets/utils/Breakpoints.enum'
import ActionBox from '../ActionBox/ActionBox'
import CallToAction from '../CallToAction/CallToAction'
import TopNavBar from '../TopNavBar/TopNavBar'

export interface ImportPageProps {}

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;

  @media ${BreakpointsMediaQueries.md} {
    align-items: center;
  }
`

const ActionBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-conent: flex-start;

  @media ${BreakpointsMediaQueries.lg} {
    justify-content: center;
  }
`

const ImportPage: VFC<ImportPageProps> = ({}) => {
  return (
    <ContainerDiv>
      <TopNavBar title='Dashboard' />
      <Content>
        <CallToAction
          title='You are Back!'
          description='Seems you were able to connect some data so far. Connect more info to make bizly more powerful!'
        />
        <ActionBoxContainer>
          <ActionBox
            title='Import Your Contacts'
            type='ImportContacts'
            state='default'
            subtitle='Select account type below:'
          />
          <ActionBox
            title='Import Your Calendar'
            type='ImportCalendar'
            state='default'
            subtitle='Select account type below:'
          />
          <ActionBox
            title='Connect To Services'
            type='ConnectToServices'
            state='noBorder'
          />
        </ActionBoxContainer>
      </Content>
    </ContainerDiv>
  )
}

export default ImportPage
