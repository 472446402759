import React from 'react'

export const dashboardPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/dashboard/page-1',
        component: React.lazy(() => import('./Pages/PageOne')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/dashboard/page-2',
        component: React.lazy(() => import('./Pages/PageTwo')),
      },
    ],
  },
]
